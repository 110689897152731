/* Carbon scale */
/* Night scale */
._3YFEI .hero-header, ._3YFEI h1, ._3YFEI h2, ._3YFEI h3, ._3YFEI h4, ._3YFEI h5, ._3YFEI h6 {
  margin: 0;
}
._3YFEI .hero-header, ._3YFEI h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3YFEI .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3YFEI h3, ._3YFEI h4, ._3YFEI h5, ._3YFEI h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3YFEI h1, ._3YFEI h2, ._3YFEI h3, ._3YFEI h4, ._3YFEI h5, ._3YFEI h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3YFEI h1 {
  font-size: 42px;
}
._3YFEI h2 {
  font-size: 30px;
}
._3YFEI h3 {
  font-size: 24px;
}
._3YFEI h4 {
  font-size: 18px;
}
._3YFEI h5 {
  font-size: 16px;
}
._3YFEI h6 {
  font-size: 14px;
}

._2xNK8 b, ._2xNK8 strong {
  color: #1D1E26;
}
._2xNK8 ol, ._2xNK8 ul {
  padding: 0;
}
._2xNK8 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2xNK8 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2xNK8 img {
  max-width: 100%;
}
._2xNK8 figure {
  margin: 0;
}
._2xNK8 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2xNK8 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.JdNEo b, .JdNEo strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.JdNEo i, .JdNEo em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.JdNEo a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.JdNEo a:hover {
  text-decoration: none;
  color: #007799;
}
.JdNEo code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1mmNT {
  font-size: 16px;
}

._2jaOT {
  font-size: 14px;
}

._29F0O {
  font-size: 12px;
}

._3IVWl {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3h4nj {
  height: 60px;
  min-height: 60px;
  min-width: 360px;
  background-color: #303240;
  color: white;
  fill: white;
}
._3h4nj .uui-mainmenu-server-badge {
  position: absolute;
  left: 80px;
  bottom: 0;
}
._3h4nj .uui-mainmenu-server-badge-label {
  padding: 0 5px;
  border: 2px solid #FFFFFF;
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-size: 9px;
  text-transform: uppercase;
}
._3h4nj.uui-mainmenu-transparent {
  background-color: transparent;
}
._3h4nj.uui-mainmenu-transparent #global_menu_toggle, ._3h4nj.uui-mainmenu-transparent .uui-burger-menu {
  background-color: transparent;
}
._3h4nj.uui-mainmenu-transparent #global_menu_toggle:hover, ._3h4nj.uui-mainmenu-transparent #global_menu_toggle:active, ._3h4nj.uui-mainmenu-transparent .uui-burger-menu:hover, ._3h4nj.uui-mainmenu-transparent .uui-burger-menu:active {
  background-color: transparent;
  color: #009ECC;
  fill: #009ECC;
}