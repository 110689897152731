.FYPtS {
  pointer-events: none;
}
.FYPtS[data-placement^=top] ._3mjoY {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FYPtS[data-placement^=bottom] ._3mjoY {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.FYPtS[data-placement^=left] ._3mjoY {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FYPtS[data-placement^=right] ._3mjoY {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}