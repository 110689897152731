/* Carbon scale */
/* Night scale */
.uSZ48 .hero-header, .uSZ48 h1, .uSZ48 h2, .uSZ48 h3, .uSZ48 h4, .uSZ48 h5, .uSZ48 h6 {
  margin: 0;
}
.uSZ48 .hero-header, .uSZ48 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.uSZ48 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.uSZ48 h3, .uSZ48 h4, .uSZ48 h5, .uSZ48 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.uSZ48 h1, .uSZ48 h2, .uSZ48 h3, .uSZ48 h4, .uSZ48 h5, .uSZ48 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uSZ48 h1 {
  font-size: 42px;
}
.uSZ48 h2 {
  font-size: 30px;
}
.uSZ48 h3 {
  font-size: 24px;
}
.uSZ48 h4 {
  font-size: 18px;
}
.uSZ48 h5 {
  font-size: 16px;
}
.uSZ48 h6 {
  font-size: 14px;
}

._2jGWj b, ._2jGWj strong {
  color: #1D1E26;
}
._2jGWj ol, ._2jGWj ul {
  padding: 0;
}
._2jGWj li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2jGWj pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2jGWj img {
  max-width: 100%;
}
._2jGWj figure {
  margin: 0;
}
._2jGWj figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2jGWj p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3DgHw b, ._3DgHw strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3DgHw i, ._3DgHw em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3DgHw a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3DgHw a:hover {
  text-decoration: none;
  color: #007799;
}
._3DgHw code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2nt8F {
  font-size: 16px;
}

._2wb6l {
  font-size: 14px;
}

._3X9xY {
  font-size: 12px;
}

.aJ4fL {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._213j2 {
  min-width: 36px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  bottom: -1px;
  display: flex;
  align-items: center;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
  border-bottom: 2px solid transparent;
}
._213j2.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._213j2.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._213j2._6iKwQ {
  color: #474A59;
  fill: #474A59;
}
._213j2._6iKwQ:hover, ._213j2._6iKwQ:focus {
  fill: #009ECC;
  color: #009ECC;
}
._213j2._6iKwQ.uui-active {
  fill: #009ECC;
  color: #009ECC;
  border-color: #009ECC;
}
._213j2._6iKwQ.uui-disabled {
  color: #ACAFBF;
  fill: #ACAFBF;
}
._213j2._6iKwQ.uui-disabled:hover, ._213j2._6iKwQ.uui-disabled:focus {
  fill: #ACAFBF;
  color: #ACAFBF;
}
._213j2._6iKwQ.uui-disabled.uui-active {
  fill: #ACAFBF;
  color: #ACAFBF;
  border-color: #ACAFBF;
}
._213j2.Uyu8i {
  color: white;
  fill: white;
}
._213j2.Uyu8i:hover, ._213j2.Uyu8i:focus {
  fill: #009ECC;
  color: #009ECC;
}
._213j2.Uyu8i.uui-active {
  fill: #009ECC;
  color: #009ECC;
  border-color: #009ECC;
}
._213j2.Uyu8i.uui-disabled {
  color: #6C6F80;
  fill: #6C6F80;
}
._213j2.Uyu8i.uui-disabled:hover, ._213j2.Uyu8i.uui-disabled:focus {
  fill: #6C6F80;
  color: #6C6F80;
}
._213j2.Uyu8i.uui-disabled.uui-active {
  fill: #6C6F80;
  color: #6C6F80;
  border-color: #6C6F80;
}
._213j2.ZMRIJ .uui-caption {
  position: relative;
}
._213j2.ZMRIJ .uui-caption::after {
  position: absolute;
  content: "";
  margin-bottom: 18px;
  height: 6px;
  width: 6px;
  background: #FA4B4B;
  box-shadow: 0 1px 8px 0 #FA4B4B;
  border-radius: 50%;
}
._213j2 .uui-count {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  letter-spacing: 0;
  color: #6C6F80;
  background-color: #E1E3EB;
  padding: 0 6px;
  margin-left: 6px;
  margin-right: 3px;
  border-radius: 9px;
}
._213j2.uui-active {
  border-bottom: 2px solid;
}
._213j2 .uui-caption {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
}
._213j2._1xHuL {
  height: 36px;
}
._213j2._3Hx19 {
  height: 48px;
}
._213j2._1XajX {
  height: 60px;
}