/* Carbon scale */
/* Night scale */
.tEi2b .hero-header, .tEi2b h1, .tEi2b h2, .tEi2b h3, .tEi2b h4, .tEi2b h5, .tEi2b h6 {
  margin: 0;
}
.tEi2b .hero-header, .tEi2b h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.tEi2b .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.tEi2b h3, .tEi2b h4, .tEi2b h5, .tEi2b h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.tEi2b h1, .tEi2b h2, .tEi2b h3, .tEi2b h4, .tEi2b h5, .tEi2b h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.tEi2b h1 {
  font-size: 42px;
}
.tEi2b h2 {
  font-size: 30px;
}
.tEi2b h3 {
  font-size: 24px;
}
.tEi2b h4 {
  font-size: 18px;
}
.tEi2b h5 {
  font-size: 16px;
}
.tEi2b h6 {
  font-size: 14px;
}

._13xeO b, ._13xeO strong {
  color: #1D1E26;
}
._13xeO ol, ._13xeO ul {
  padding: 0;
}
._13xeO li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._13xeO pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._13xeO img {
  max-width: 100%;
}
._13xeO figure {
  margin: 0;
}
._13xeO figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._13xeO p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.eMY2N b, .eMY2N strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.eMY2N i, .eMY2N em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.eMY2N a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.eMY2N a:hover {
  text-decoration: none;
  color: #007799;
}
.eMY2N code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2mw2r {
  font-size: 16px;
}

._27Vu0 {
  font-size: 14px;
}

._3VTTv {
  font-size: 12px;
}

.CGHhz {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1FaN_ .uui-calendar-days > div:last-child .uui-calendar-day-cell:last-child > .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper) {
  position: relative;
}
._1FaN_ .uui-calendar-days > div:last-child .uui-calendar-day-cell:last-child > .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper) .uui-calendar-day {
  top: -3px;
}
._1FaN_ .uui-calendar-days > div:last-child .uui-calendar-day-cell:last-child > .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper):after {
  background: linear-gradient(320deg, #ffffff 3px, transparent 0), linear-gradient(-135deg, #ffffff 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: 6px 6px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 100%;
}
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:empty + .uui-calendar-day-cell .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper),
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:first-child .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper) {
  position: relative;
}
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:empty + .uui-calendar-day-cell .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper) .uui-calendar-day,
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:first-child .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper) .uui-calendar-day {
  top: -3px;
}
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:empty + .uui-calendar-day-cell .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper):after,
._1FaN_ .uui-calendar-days > div:first-child .uui-calendar-day-cell:first-child .uui-range-datepicker-in-range:not(.uui-range-datepicker-first-day-in-range-wrapper):not(.uui-range-datepicker-last-day-in-range-wrapper):after {
  background: linear-gradient(45deg, #ffffff 3px, transparent 0), linear-gradient(135deg, #ffffff 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: 6px 6px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 6px;
  height: 100%;
}
._1FaN_ .uui-range-datepicker-in-range.uui-calendar-day-wrapper {
  background-color: #E1F4FA;
  border-radius: 0;
  width: 40px;
}
._1FaN_ .uui-range-datepicker-first-day-in-range-wrapper.uui-calendar-selected-day {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
._1FaN_ .uui-range-datepicker-last-day-in-range-wrapper.uui-calendar-selected-day {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-right: 10px;
}
._1FaN_ .uui-range-datepicker-separator {
  display: flex;
  flex: 0 0 auto;
  border-left: 1px solid #CED0DB;
}