._3gO3_ {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  user-select: none;
}
._3gO3_ .WxAlC {
  display: flex;
  align-items: center;
  line-height: 0;
}
._3gO3_ .E1Odq > div {
  flex-grow: 1;
}