/* Carbon scale */
/* Night scale */
._2Qy1p .hero-header, ._2Qy1p h1, ._2Qy1p h2, ._2Qy1p h3, ._2Qy1p h4, ._2Qy1p h5, ._2Qy1p h6 {
  margin: 0;
}
._2Qy1p .hero-header, ._2Qy1p h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2Qy1p .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2Qy1p h3, ._2Qy1p h4, ._2Qy1p h5, ._2Qy1p h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2Qy1p h1, ._2Qy1p h2, ._2Qy1p h3, ._2Qy1p h4, ._2Qy1p h5, ._2Qy1p h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2Qy1p h1 {
  font-size: 42px;
}
._2Qy1p h2 {
  font-size: 30px;
}
._2Qy1p h3 {
  font-size: 24px;
}
._2Qy1p h4 {
  font-size: 18px;
}
._2Qy1p h5 {
  font-size: 16px;
}
._2Qy1p h6 {
  font-size: 14px;
}

._11bH_ b, ._11bH_ strong {
  color: #1D1E26;
}
._11bH_ ol, ._11bH_ ul {
  padding: 0;
}
._11bH_ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._11bH_ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._11bH_ img {
  max-width: 100%;
}
._11bH_ figure {
  margin: 0;
}
._11bH_ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._11bH_ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._28I1Y b, ._28I1Y strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._28I1Y i, ._28I1Y em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._28I1Y a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._28I1Y a:hover {
  text-decoration: none;
  color: #007799;
}
._28I1Y code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3lVx- {
  font-size: 16px;
}

._313X- {
  font-size: 14px;
}

._3RYxj {
  font-size: 12px;
}

.iYp2j {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.K12Ax {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}
.K12Ax:hover {
  background: #1D1E26;
}
.K12Ax:active {
  background-color: #070709;
}