/* Carbon scale */
/* Night scale */
._2XcNx .hero-header, ._2XcNx h1, ._2XcNx h2, ._2XcNx h3, ._2XcNx h4, ._2XcNx h5, ._2XcNx h6 {
  margin: 0;
}
._2XcNx .hero-header, ._2XcNx h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2XcNx .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2XcNx h3, ._2XcNx h4, ._2XcNx h5, ._2XcNx h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2XcNx h1, ._2XcNx h2, ._2XcNx h3, ._2XcNx h4, ._2XcNx h5, ._2XcNx h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2XcNx h1 {
  font-size: 42px;
}
._2XcNx h2 {
  font-size: 30px;
}
._2XcNx h3 {
  font-size: 24px;
}
._2XcNx h4 {
  font-size: 18px;
}
._2XcNx h5 {
  font-size: 16px;
}
._2XcNx h6 {
  font-size: 14px;
}

._3yntQ b, ._3yntQ strong {
  color: #1D1E26;
}
._3yntQ ol, ._3yntQ ul {
  padding: 0;
}
._3yntQ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3yntQ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3yntQ img {
  max-width: 100%;
}
._3yntQ figure {
  margin: 0;
}
._3yntQ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3yntQ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2-6lr b, ._2-6lr strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2-6lr i, ._2-6lr em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2-6lr a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2-6lr a:hover {
  text-decoration: none;
  color: #007799;
}
._2-6lr code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2Tcy7 {
  font-size: 16px;
}

.-c3WQ {
  font-size: 14px;
}

._1FQ5M {
  font-size: 12px;
}

._2Q-X- {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2mpOJ.uui-carousel-container {
  min-width: 300px;
  width: 100%;
}
._2mpOJ .uui-carousel-bar {
  display: flex;
  margin: 0 0 0 auto;
}
._2mpOJ .uui-carousel-bar-item {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #e5e5e5;
  cursor: pointer;
}
._2mpOJ .uui-carousel-left-arrow {
  cursor: pointer;
  width: 30px;
  fill: #CED0DB;
  transform: rotate(90deg);
  align-items: center;
}
._2mpOJ .uui-carousel-right-arrow {
  cursor: pointer;
  width: 30px;
  fill: #CED0DB;
  transform: rotate(-90deg);
  align-items: center;
}
._2mpOJ .uui-active {
  background-color: var(--uui-color-700);
}