._35XA0 {
  margin: 32px 0 12px;
}

._3ujAR {
  margin: 12px 0 24px;
}

._334sq {
  animation: _1ODo5 2s ease-out;
}

@keyframes _1ODo5 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}