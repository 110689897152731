._1wLJd {
  flex-direction: row-reverse;
}

._1BivC {
  flex-direction: row;
}

.dqEEU > * {
  margin-right: var(--overlap);
}