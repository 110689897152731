/* Carbon scale */
/* Night scale */
._326db .hero-header, ._326db h1, ._326db h2, ._326db h3, ._326db h4, ._326db h5, ._326db h6 {
  margin: 0;
}
._326db .hero-header, ._326db h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._326db .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._326db h3, ._326db h4, ._326db h5, ._326db h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._326db h1, ._326db h2, ._326db h3, ._326db h4, ._326db h5, ._326db h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._326db h1 {
  font-size: 42px;
}
._326db h2 {
  font-size: 30px;
}
._326db h3 {
  font-size: 24px;
}
._326db h4 {
  font-size: 18px;
}
._326db h5 {
  font-size: 16px;
}
._326db h6 {
  font-size: 14px;
}

._3mCII b, ._3mCII strong {
  color: #1D1E26;
}
._3mCII ol, ._3mCII ul {
  padding: 0;
}
._3mCII li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3mCII pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3mCII img {
  max-width: 100%;
}
._3mCII figure {
  margin: 0;
}
._3mCII figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3mCII p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._15bm8 b, ._15bm8 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._15bm8 i, ._15bm8 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._15bm8 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._15bm8 a:hover {
  text-decoration: none;
  color: #007799;
}
._15bm8 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2CHHV {
  font-size: 16px;
}

._2JvPF {
  font-size: 14px;
}

._3PVvc {
  font-size: 12px;
}

._2646_ {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._28bSS {
  display: block;
  height: 60px;
  width: 60px;
  fill: white;
  flex-shrink: 0;
  cursor: pointer;
}
._28bSS:hover {
  background-color: #1D1E26;
}
._28bSS:active {
  background-color: #070709;
}

.Ke9kN svg {
  width: 60px;
  height: 60px;
}