/* Carbon scale */
/* Night scale */
._2uP-S {
  position: relative;
  background-color: #E1E3EB;
}
._2uP-S ._3nrq7 {
  background-color: #009ECC;
  width: 33%;
  top: 0;
  bottom: 0;
  position: absolute;
  animation: reYAY 2s linear 0.15s infinite;
}

@keyframes reYAY {
  0% {
    left: -33%;
  }
  100% {
    left: 100%;
  }
}
._18E5R {
  height: 12px;
}

._21x7N {
  height: 18px;
}

._2wj_H {
  height: 24px;
}