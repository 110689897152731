._3b-XQ {
  display: flex;
  flex: 0 0 auto;
}

.DyvCE {
  align-items: center;
}

._3owR7 {
  align-items: flex-start;
}

.tD7lf {
  align-items: flex-end;
}

._6GrT8 {
  align-items: stretch;
}