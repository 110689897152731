/* Carbon scale */
/* Night scale */
.Q7AyX .hero-header, .Q7AyX h1, .Q7AyX h2, .Q7AyX h3, .Q7AyX h4, .Q7AyX h5, .Q7AyX h6 {
  margin: 0;
}
.Q7AyX .hero-header, .Q7AyX h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.Q7AyX .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.Q7AyX h3, .Q7AyX h4, .Q7AyX h5, .Q7AyX h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.Q7AyX h1, .Q7AyX h2, .Q7AyX h3, .Q7AyX h4, .Q7AyX h5, .Q7AyX h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.Q7AyX h1 {
  font-size: 42px;
}
.Q7AyX h2 {
  font-size: 30px;
}
.Q7AyX h3 {
  font-size: 24px;
}
.Q7AyX h4 {
  font-size: 18px;
}
.Q7AyX h5 {
  font-size: 16px;
}
.Q7AyX h6 {
  font-size: 14px;
}

._1MPgc b, ._1MPgc strong {
  color: #1D1E26;
}
._1MPgc ol, ._1MPgc ul {
  padding: 0;
}
._1MPgc li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1MPgc pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1MPgc img {
  max-width: 100%;
}
._1MPgc figure {
  margin: 0;
}
._1MPgc figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1MPgc p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1QlNl b, ._1QlNl strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1QlNl i, ._1QlNl em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1QlNl a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1QlNl a:hover {
  text-decoration: none;
  color: #007799;
}
._1QlNl code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2LA4M {
  font-size: 16px;
}

._3RVi2 {
  font-size: 14px;
}

._1r7Ct {
  font-size: 12px;
}

._3qJKk {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2Cj8E {
  display: none;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 720px) {
  ._2Cj8E {
    display: flex;
  }
}

._1Rk94 {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  right: 24px;
}