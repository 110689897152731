/* Carbon scale */
/* Night scale */
._2TFi1 .hero-header, ._2TFi1 h1, ._2TFi1 h2, ._2TFi1 h3, ._2TFi1 h4, ._2TFi1 h5, ._2TFi1 h6 {
  margin: 0;
}
._2TFi1 .hero-header, ._2TFi1 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2TFi1 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2TFi1 h3, ._2TFi1 h4, ._2TFi1 h5, ._2TFi1 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2TFi1 h1, ._2TFi1 h2, ._2TFi1 h3, ._2TFi1 h4, ._2TFi1 h5, ._2TFi1 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2TFi1 h1 {
  font-size: 42px;
}
._2TFi1 h2 {
  font-size: 30px;
}
._2TFi1 h3 {
  font-size: 24px;
}
._2TFi1 h4 {
  font-size: 18px;
}
._2TFi1 h5 {
  font-size: 16px;
}
._2TFi1 h6 {
  font-size: 14px;
}

._2ilMQ b, ._2ilMQ strong {
  color: #1D1E26;
}
._2ilMQ ol, ._2ilMQ ul {
  padding: 0;
}
._2ilMQ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2ilMQ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2ilMQ img {
  max-width: 100%;
}
._2ilMQ figure {
  margin: 0;
}
._2ilMQ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2ilMQ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._11ok6 b, ._11ok6 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._11ok6 i, ._11ok6 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._11ok6 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._11ok6 a:hover {
  text-decoration: none;
  color: #007799;
}
._11ok6 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1l0fy {
  font-size: 16px;
}

.Et7S2 {
  font-size: 14px;
}

._3aNXl {
  font-size: 12px;
}

._2029X {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3wq00 {
  --uui-dt-row-border-color: transparent;
  --uui-dt-row-border-width: 0;
}
._3wq00.uui-table-row.-clickable {
  cursor: pointer;
}
._3wq00.uui-table-row.-clickable:hover {
  --uui-dt-row-background: #F5F6FA;
}
._3wq00.uui-table-row.-draggable:hover {
  cursor: grab;
}
._3wq00.uui-table-row.-draggable:active {
  cursor: grabbing;
}
._3wq00.uui-table-row.uui-invalid {
  --uui-dt-row-background: #FEF7F6;
}
._3wq00.uui-table-row.uui-dragged-out {
  opacity: 0.5;
}
._3wq00.uui-table-row.uui-drag-ghost {
  box-shadow: 0 1px 3px 0 rgba(51, 51, 51, 0.2);
}
._3wq00.uui-table-row.uui-focus {
  --uui-dt-row-background: #F5F6FA;
}
._3wq00.uui-table-row.O5tKC {
  min-height: 24px;
}
._3wq00.uui-table-row._3Ukpz {
  min-height: 30px;
}
._3wq00.uui-table-row._1HZh4 {
  min-height: 36px;
}
._3wq00.uui-table-row._6Wi7d {
  min-height: 48px;
}
._3wq00.uui-table-row.LN5st {
  min-height: 60px;
}
._3wq00.uui-table-row._10-4U {
  padding-left: 12px;
}
._3wq00.uui-table-row._3TswY {
  padding-left: 18px;
}
._3wq00.uui-table-row._3PxjC {
  padding-left: 24px;
}
._3wq00._1DPlG {
  --uui-dt-row-background: #FAFAFC;
}
._3wq00._3J4KF {
  --uui-dt-row-background: #FFFFFF;
}
._3wq00._20Avh {
  --uui-dt-row-border-color: #E1E3EB;
  --uui-dt-row-border-width: 1px;
}