._167Hs > :nth-child(n) {
  border-radius: 0;
}
._167Hs > :first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
._167Hs > :last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}