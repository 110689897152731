._1I3OS ._1cM4M {
  padding-top: 3px;
  padding-bottom: 3px;
}
._1I3OS ._1Cwq7 {
  padding-top: 6px;
  padding-bottom: 6px;
}
._1I3OS ._2xtVt {
  padding-top: 6px;
  padding-bottom: 6px;
}
._1I3OS ._1rFt3 {
  padding-top: 9px;
  padding-bottom: 9px;
}
._1I3OS .rr5a7 {
  padding-top: 9px;
  padding-bottom: 9px;
}