/* Carbon scale */
/* Night scale */
._2MaI2 .hero-header, ._2MaI2 h1, ._2MaI2 h2, ._2MaI2 h3, ._2MaI2 h4, ._2MaI2 h5, ._2MaI2 h6 {
  margin: 0;
}
._2MaI2 .hero-header, ._2MaI2 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2MaI2 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2MaI2 h3, ._2MaI2 h4, ._2MaI2 h5, ._2MaI2 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2MaI2 h1, ._2MaI2 h2, ._2MaI2 h3, ._2MaI2 h4, ._2MaI2 h5, ._2MaI2 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2MaI2 h1 {
  font-size: 42px;
}
._2MaI2 h2 {
  font-size: 30px;
}
._2MaI2 h3 {
  font-size: 24px;
}
._2MaI2 h4 {
  font-size: 18px;
}
._2MaI2 h5 {
  font-size: 16px;
}
._2MaI2 h6 {
  font-size: 14px;
}

.t408k b, .t408k strong {
  color: #1D1E26;
}
.t408k ol, .t408k ul {
  padding: 0;
}
.t408k li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.t408k pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.t408k img {
  max-width: 100%;
}
.t408k figure {
  margin: 0;
}
.t408k figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.t408k p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1ZJZs b, ._1ZJZs strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1ZJZs i, ._1ZJZs em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1ZJZs a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1ZJZs a:hover {
  text-decoration: none;
  color: #007799;
}
._1ZJZs code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3-Xfv {
  font-size: 16px;
}

.p_aNv {
  font-size: 14px;
}

._34nJv {
  font-size: 12px;
}

._2zXO7 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.YjxKa {
  border-bottom: 1px solid #CED0DB;
  padding: 6px 24px;
}

._2EjvC {
  border-top: 1px solid #CED0DB;
}

.QlYca {
  margin-bottom: 6px;
}