/* Carbon scale */
/* Night scale */
._1THYP .hero-header, ._1THYP h1, ._1THYP h2, ._1THYP h3, ._1THYP h4, ._1THYP h5, ._1THYP h6 {
  margin: 0;
}
._1THYP .hero-header, ._1THYP h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1THYP .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1THYP h3, ._1THYP h4, ._1THYP h5, ._1THYP h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1THYP h1, ._1THYP h2, ._1THYP h3, ._1THYP h4, ._1THYP h5, ._1THYP h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1THYP h1 {
  font-size: 42px;
}
._1THYP h2 {
  font-size: 30px;
}
._1THYP h3 {
  font-size: 24px;
}
._1THYP h4 {
  font-size: 18px;
}
._1THYP h5 {
  font-size: 16px;
}
._1THYP h6 {
  font-size: 14px;
}

._2L4uM b, ._2L4uM strong {
  color: #1D1E26;
}
._2L4uM ol, ._2L4uM ul {
  padding: 0;
}
._2L4uM li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2L4uM pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2L4uM img {
  max-width: 100%;
}
._2L4uM figure {
  margin: 0;
}
._2L4uM figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2L4uM p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._22p93 b, ._22p93 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._22p93 i, ._22p93 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._22p93 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._22p93 a:hover {
  text-decoration: none;
  color: #007799;
}
._22p93 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2etpy {
  font-size: 16px;
}

.U7k9S {
  font-size: 14px;
}

._3NtpC {
  font-size: 12px;
}

.XH-jC {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3KCR6 ._1IrYe {
  width: 100%;
  height: 1px;
  margin: 7px 0;
  border: 0;
}

._1joDH {
  display: flex;
  margin-bottom: 7px;
}
._1joDH ._3py_n {
  flex-grow: 1;
  padding: 7px 36px 7px 0;
  text-align: left;
  border-bottom: 1px solid;
}

._1X7pz {
  box-shadow: 0 1px 3px 0 rgba(51, 51, 51, 0.2);
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
  border-radius: 6px;
}
._1X7pz .uui-icon svg {
  height: 16px;
  max-width: 16px;
}
._1X7pz._38ay_ {
  background-color: #FFFFFF;
  fill: #ACAFBF;
  color: #6C6F80;
}
._1X7pz._38ay_ ._3py_n {
  color: #ACAFBF;
  border-bottom-color: #E1E3EB;
}
._1X7pz._38ay_ ._1IrYe {
  background-color: #E1E3EB;
}
._1X7pz._38ay_ .uui-input {
  color: inherit;
}
._1X7pz._38ay_ .uui-input::placeholder {
  color: #E1E3EB;
}
._1X7pz._38ay_ ._1fBXo:hover, ._1X7pz._38ay_ ._1fBXo:focus {
  color: #009ECC;
}
._1X7pz._38ay_ ._1fBXo:hover .uui-checkbox, ._1X7pz._38ay_ ._1fBXo:focus .uui-checkbox {
  border-color: #009ECC;
}
._1X7pz._38ay_ ._1fBXo .uui-checkbox {
  background-color: white;
  fill: #009ECC;
}
._1X7pz._38ay_ .uui-button-box.-clickable:hover, ._1X7pz._38ay_ .uui-button-box.-clickable:focus {
  color: #009ECC;
  fill: #009ECC;
}
._1X7pz._38ay_ .uui-button-box.-clickable.uui-disabled {
  color: #ACAFBF;
  fill: #ACAFBF;
}
._1X7pz._38ay_ .uui-button-box.-clickable.uui-disabled:hover, ._1X7pz._38ay_ .uui-button-box.-clickable.uui-disabled:focus {
  cursor: default;
}
._1X7pz._2usUe {
  background-color: #303240;
  fill: #E1E3EB;
  color: #E1E3EB;
}
._1X7pz._2usUe ._3py_n {
  border-bottom-color: #E1E3EB;
}
._1X7pz._2usUe ._1IrYe {
  background-color: #E1E3EB;
}
._1X7pz._2usUe .uui-input {
  color: inherit;
}
._1X7pz._2usUe .uui-input::placeholder {
  color: #ACAFBF;
}
._1X7pz._2usUe ._1CHj2 {
  color: #E1E3EB;
  fill: #E1E3EB;
}
._1X7pz._2usUe ._1fBXo:hover, ._1X7pz._2usUe ._1fBXo:focus {
  color: #FFFFFF;
}
._1X7pz._2usUe ._1fBXo:hover .uui-checkbox, ._1X7pz._2usUe ._1fBXo:focus .uui-checkbox {
  border-color: #FFFFFF;
}
._1X7pz._2usUe ._1fBXo .uui-checkbox {
  background-color: #303240;
}
._1X7pz._2usUe .uui-button-box.-clickable:hover, ._1X7pz._2usUe .uui-button-box.-clickable:focus {
  color: #FFFFFF;
  fill: #FFFFFF;
}
._1X7pz._2usUe .uui-button-box.-clickable.uui-disabled {
  color: #ACAFBF;
  fill: #ACAFBF;
}
._1X7pz._2usUe .uui-button-box.-clickable.uui-disabled:hover, ._1X7pz._2usUe .uui-button-box.-clickable.uui-disabled:focus {
  cursor: default;
}
._1X7pz .uui-icon {
  padding: 0 10px;
}

._1CHj2 {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  color: #6C6F80;
  fill: #ACAFBF;
}
._1CHj2.uui-button-box .uui-caption {
  padding: 7px 36px 7px 0;
  text-align: left;
}
._1CHj2.uui-button-box .uui-icon {
  width: 36px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
._1CHj2.uui-button-box.uui-active {
  color: #009ECC;
}

._19gfU {
  margin-left: 36px;
}

._1fBXo .uui-checkbox {
  width: 12px;
  height: 12px;
  margin: 1px 0;
}
._1fBXo .uui-input-label {
  font-size: 14px;
  line-height: 14px;
}
._1fBXo .uui-icon svg {
  width: 12px;
}
._1fBXo .uui-checkbox {
  border: 1px solid #E1E3EB;
  align-self: baseline;
  margin: 9px 12px;
}
._1fBXo .uui-checkbox:focus {
  outline: none;
}
._1fBXo.uui-disabled {
  cursor: default;
}
._1fBXo.uui-disabled .uui-checkbox, ._1fBXo.uui-disabled .uui-checkbox.uui-checked {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  fill: white;
}
._1fBXo.uui-disabled .uui-checkbox .uui-input, ._1fBXo.uui-disabled .uui-checkbox.uui-checked .uui-input {
  color: #ACAFBF;
}
._1fBXo.uui-disabled .uui-checkbox .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox.uui-checked .uui-input::placeholder {
  color: #ACAFBF;
}
._1fBXo.uui-disabled .uui-checkbox:hover, ._1fBXo.uui-disabled .uui-checkbox:active, ._1fBXo.uui-disabled .uui-checkbox:focus, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:hover, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:active, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._1fBXo.uui-disabled .uui-checkbox:hover .uui-input, ._1fBXo.uui-disabled .uui-checkbox:active .uui-input, ._1fBXo.uui-disabled .uui-checkbox:focus .uui-input, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:hover .uui-input, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:active .uui-input, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:focus .uui-input {
  color: #ACAFBF;
}
._1fBXo.uui-disabled .uui-checkbox:hover .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox:active .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox:focus .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:hover .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:active .uui-input::placeholder, ._1fBXo.uui-disabled .uui-checkbox.uui-checked:focus .uui-input::placeholder {
  color: #ACAFBF;
}
._1fBXo.uui-disabled .uui-checkbox::placeholder, ._1fBXo.uui-disabled .uui-checkbox.uui-checked::placeholder {
  color: #E1E3EB;
}
._1fBXo.uui-disabled .uui-input-label {
  color: #E1E3EB;
}
._1fBXo.uui-invalid .uui-checkbox {
  border-color: #FA4B4B;
  fill: #FA4B4B;
}
._1fBXo.uui-invalid .uui-checkbox:hover, ._1fBXo.uui-invalid .uui-checkbox:active {
  border-color: #f91919;
  fill: #f91919;
}
._1fBXo.uui-invalid .uui-checkbox ._3r5pH.uui-checked {
  fill: white;
  background-color: #FA4B4B;
  border-color: #FA4B4B;
}
._1fBXo.uui-invalid .uui-checkbox ._3r5pH.uui-checked:hover, ._1fBXo.uui-invalid .uui-checkbox ._3r5pH.uui-checked:active {
  background-color: #f91919;
  border-color: #f91919;
}
._1fBXo.uui-invalid .uui-input-label {
  color: #FA4B4B;
}

._1mnE5 {
  width: 100%;
  display: flex;
  padding-bottom: 5px;
}
._1mnE5 .uui-icon svg {
  height: 16px;
  max-width: 16px;
}
._1mnE5:hover {
  border-color: #ACAFBF;
}
._1mnE5 .uui-input-box .uui-input {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  border-bottom: 1px solid #E1E3EB;
  padding-left: 12px;
}
._1mnE5 .uui-input-box .uui-icon {
  padding: 7px;
  border-bottom: 1px solid #E1E3EB;
}
._1mnE5.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  fill: #ACAFBF;
}
._1mnE5.uui-disabled .uui-input {
  color: #ACAFBF;
}
._1mnE5.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
._1mnE5.uui-disabled:hover, ._1mnE5.uui-disabled:active, ._1mnE5.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._1mnE5.uui-disabled:hover .uui-input, ._1mnE5.uui-disabled:active .uui-input, ._1mnE5.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
._1mnE5.uui-disabled:hover .uui-input::placeholder, ._1mnE5.uui-disabled:active .uui-input::placeholder, ._1mnE5.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
._1mnE5.uui-disabled::placeholder {
  color: #E1E3EB;
}
._1mnE5.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
._1mnE5.uui-invalid {
  border-color: #FA4B4B;
}
._1mnE5.uui-active {
  border-color: #E1E3EB;
}
._1mnE5.uui-focus {
  border-color: #ACAFBF;
}
._1mnE5 .uui-icon-accept {
  cursor: pointer;
}
._1mnE5 .uui-icon-accept.uui-enabled {
  fill: #67A300;
}
._1mnE5 .uui-icon-accept.uui-enabled:hover {
  fill: #477000;
}
._1mnE5 .uui-icon-accept.uui-disabled {
  fill: #E1E3EB;
  cursor: default;
}
._1mnE5 .uui-icon-cancel {
  cursor: pointer;
}
._1mnE5 .uui-icon-cancel.uui-enabled {
  fill: #6C6F80;
}
._1mnE5 .uui-icon-cancel.uui-enabled:hover {
  fill: #FA4B4B;
}
._1mnE5 .uui-icon-cancel.uui-disabled {
  fill: #E1E3EB;
  cursor: default;
}
._1mnE5.-clickable {
  cursor: pointer;
}
._1mnE5.-clickable .uui-input {
  cursor: pointer;
}