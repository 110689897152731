/* Carbon scale */
/* Night scale */
._2leOa .hero-header, ._2leOa h1, ._2leOa h2, ._2leOa h3, ._2leOa h4, ._2leOa h5, ._2leOa h6 {
  margin: 0;
}
._2leOa .hero-header, ._2leOa h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2leOa .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2leOa h3, ._2leOa h4, ._2leOa h5, ._2leOa h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2leOa h1, ._2leOa h2, ._2leOa h3, ._2leOa h4, ._2leOa h5, ._2leOa h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2leOa h1 {
  font-size: 42px;
}
._2leOa h2 {
  font-size: 30px;
}
._2leOa h3 {
  font-size: 24px;
}
._2leOa h4 {
  font-size: 18px;
}
._2leOa h5 {
  font-size: 16px;
}
._2leOa h6 {
  font-size: 14px;
}

._2rNEO b, ._2rNEO strong {
  color: #1D1E26;
}
._2rNEO ol, ._2rNEO ul {
  padding: 0;
}
._2rNEO li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2rNEO pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2rNEO img {
  max-width: 100%;
}
._2rNEO figure {
  margin: 0;
}
._2rNEO figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2rNEO p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2-0B1 b, ._2-0B1 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2-0B1 i, ._2-0B1 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2-0B1 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2-0B1 a:hover {
  text-decoration: none;
  color: #007799;
}
._2-0B1 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3MsQF {
  font-size: 16px;
}

.aQ6uT {
  font-size: 14px;
}

._1FEoF {
  font-size: 12px;
}

.cKcSR {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3nNwH {
  overflow: visible;
  fill: var(--uui-color-700);
}
._3nNwH.-clickable:not(.uui-disabled):hover, ._3nNwH.-clickable:not(.uui-disabled):focus {
  fill: var(--uui-color-800);
}
._3nNwH.-clickable:not(.uui-disabled):active {
  fill: var(--uui-color-900);
}
._3nNwH.uui-disabled {
  fill: #ACAFBF;
}
._3nNwH.uui-disabled:hover {
  fill: #ACAFBF;
}
._3nNwH.uui-invalid {
  fill: #FA4B4B;
}