._239tE.uui-monthselection-container .uui-monthselection-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
._239tE.uui-monthselection-container .uui-monthselection-month-container {
  display: flex;
  flex-direction: column;
}
._239tE.uui-monthselection-container .uui-monthselection-month-container > * {
  display: flex;
}
._239tE.uui-monthselection-container .uui-monthselection-month {
  display: flex;
  justify-content: center;
  align-items: center;
}