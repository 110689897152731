/* Carbon scale */
/* Night scale */
._1pYGE .hero-header, ._1pYGE h1, ._1pYGE h2, ._1pYGE h3, ._1pYGE h4, ._1pYGE h5, ._1pYGE h6 {
  margin: 0;
}
._1pYGE .hero-header, ._1pYGE h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1pYGE .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1pYGE h3, ._1pYGE h4, ._1pYGE h5, ._1pYGE h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1pYGE h1, ._1pYGE h2, ._1pYGE h3, ._1pYGE h4, ._1pYGE h5, ._1pYGE h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1pYGE h1 {
  font-size: 42px;
}
._1pYGE h2 {
  font-size: 30px;
}
._1pYGE h3 {
  font-size: 24px;
}
._1pYGE h4 {
  font-size: 18px;
}
._1pYGE h5 {
  font-size: 16px;
}
._1pYGE h6 {
  font-size: 14px;
}

._1ZW4L b, ._1ZW4L strong {
  color: #1D1E26;
}
._1ZW4L ol, ._1ZW4L ul {
  padding: 0;
}
._1ZW4L li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1ZW4L pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1ZW4L img {
  max-width: 100%;
}
._1ZW4L figure {
  margin: 0;
}
._1ZW4L figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1ZW4L p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3DKBE b, ._3DKBE strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3DKBE i, ._3DKBE em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3DKBE a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3DKBE a:hover {
  text-decoration: none;
  color: #007799;
}
._3DKBE code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.QQWbw {
  font-size: 16px;
}

._1VFA1 {
  font-size: 14px;
}

._31nOD {
  font-size: 12px;
}

._386Gs {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

@keyframes _1ZDzp {
  0% {
    transform: translateY(0);
  }
  66% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
._2oqT3.uui-spinner-container {
  transition: all 0.3s;
}
._2oqT3.uui-spinner-container .uui-spinner-animation {
  width: 90px;
  height: 30px;
}
._2oqT3.uui-spinner-container .uui-spinner-dot {
  width: 10px;
  height: 10px;
  opacity: 0.5;
  margin: 0 3px;
  border-radius: 100%;
  animation: _1ZDzp 0.8s infinite ease-in-out both;
}
._2oqT3.uui-spinner-container .uui-spinner-dot-1 {
  animation-delay: -0.2s;
}
._2oqT3.uui-spinner-container .uui-spinner-dot-2 {
  animation-delay: -0.1s;
}
._2oqT3 .uui-spinner-dot {
  background-color: var(--uui-color-700);
}