/* Carbon scale */
/* Night scale */
._2Pg4w .hero-header, ._2Pg4w h1, ._2Pg4w h2, ._2Pg4w h3, ._2Pg4w h4, ._2Pg4w h5, ._2Pg4w h6 {
  margin: 0;
}
._2Pg4w .hero-header, ._2Pg4w h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2Pg4w .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2Pg4w h3, ._2Pg4w h4, ._2Pg4w h5, ._2Pg4w h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2Pg4w h1, ._2Pg4w h2, ._2Pg4w h3, ._2Pg4w h4, ._2Pg4w h5, ._2Pg4w h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2Pg4w h1 {
  font-size: 42px;
}
._2Pg4w h2 {
  font-size: 30px;
}
._2Pg4w h3 {
  font-size: 24px;
}
._2Pg4w h4 {
  font-size: 18px;
}
._2Pg4w h5 {
  font-size: 16px;
}
._2Pg4w h6 {
  font-size: 14px;
}

._2a8DM b, ._2a8DM strong {
  color: #1D1E26;
}
._2a8DM ol, ._2a8DM ul {
  padding: 0;
}
._2a8DM li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2a8DM pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2a8DM img {
  max-width: 100%;
}
._2a8DM figure {
  margin: 0;
}
._2a8DM figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2a8DM p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1KH4t b, ._1KH4t strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1KH4t i, ._1KH4t em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1KH4t a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1KH4t a:hover {
  text-decoration: none;
  color: #007799;
}
._1KH4t code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2hsPx {
  font-size: 16px;
}

.hPoZ3 {
  font-size: 14px;
}

._2iflt {
  font-size: 12px;
}

._1mima {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3knMY {
  display: flex;
  flex: auto;
}

._2SmZL {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ntadp.uui-focus {
  background-color: #F5F6FA;
}
.ntadp.-clickable {
  cursor: pointer;
}