/* Carbon scale */
/* Night scale */
._1LZS8 .hero-header, ._1LZS8 h1, ._1LZS8 h2, ._1LZS8 h3, ._1LZS8 h4, ._1LZS8 h5, ._1LZS8 h6 {
  margin: 0;
}
._1LZS8 .hero-header, ._1LZS8 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1LZS8 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1LZS8 h3, ._1LZS8 h4, ._1LZS8 h5, ._1LZS8 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1LZS8 h1, ._1LZS8 h2, ._1LZS8 h3, ._1LZS8 h4, ._1LZS8 h5, ._1LZS8 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1LZS8 h1 {
  font-size: 42px;
}
._1LZS8 h2 {
  font-size: 30px;
}
._1LZS8 h3 {
  font-size: 24px;
}
._1LZS8 h4 {
  font-size: 18px;
}
._1LZS8 h5 {
  font-size: 16px;
}
._1LZS8 h6 {
  font-size: 14px;
}

._3-a-w b, ._3-a-w strong {
  color: #1D1E26;
}
._3-a-w ol, ._3-a-w ul {
  padding: 0;
}
._3-a-w li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3-a-w pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3-a-w img {
  max-width: 100%;
}
._3-a-w figure {
  margin: 0;
}
._3-a-w figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3-a-w p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2imYA b, ._2imYA strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2imYA i, ._2imYA em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2imYA a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2imYA a:hover {
  text-decoration: none;
  color: #007799;
}
._2imYA code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3c5cS {
  font-size: 16px;
}

._29lPM {
  font-size: 14px;
}

.yPdRa {
  font-size: 12px;
}

._2MEmD {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.L8Jva {
  flex-shrink: 0;
  color: #FFFFFF;
  fill: #FFFFFF;
}
.L8Jva._1Freb {
  font-family: "Oswald Regular", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}
.L8Jva._3abii {
  font-family: "Sans Regular", Arial, sans-serif;
  font-weight: normal;
}
.L8Jva._3KvfU {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
.L8Jva._3fsQF {
  font-family: "Sans Light", Arial, sans-serif;
  font-weight: normal;
}
.L8Jva._9DfVv {
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-weight: normal;
}
.L8Jva .uui-caption, .L8Jva .uui-input {
  padding: 22px 6px;
  line-height: 16px;
  margin-bottom: -3px;
  font-family: "Sans Semibold", Arial, sans-serif;
}
.L8Jva.uui-button-box, .L8Jva.uui-input-box {
  min-height: 60px;
  padding-right: 6px;
  padding-left: 6px;
}
.L8Jva .uui-caption {
  white-space: nowrap;
  font-size: 16px;
}
.L8Jva .uui-icon svg {
  width: 18px;
}
.L8Jva._21bDc .uui-icon svg {
  height: 24px;
  max-width: 24px;
}
.L8Jva._1xm10 .uui-icon svg {
  height: 24px;
  max-width: 24px;
}
.L8Jva.-clickable:hover, .L8Jva.-clickable:focus {
  background-color: #1D1E26;
  color: #FFFFFF;
  fill: #FFFFFF;
}
.L8Jva.uui-active {
  background-color: #1D1E26;
  border-bottom: 3px solid #009ECC;
  color: #FFFFFF;
  fill: #FFFFFF;
}