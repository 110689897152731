/* Carbon scale */
/* Night scale */
.MYUje .hero-header, .MYUje h1, .MYUje h2, .MYUje h3, .MYUje h4, .MYUje h5, .MYUje h6 {
  margin: 0;
}
.MYUje .hero-header, .MYUje h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.MYUje .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.MYUje h3, .MYUje h4, .MYUje h5, .MYUje h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.MYUje h1, .MYUje h2, .MYUje h3, .MYUje h4, .MYUje h5, .MYUje h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.MYUje h1 {
  font-size: 42px;
}
.MYUje h2 {
  font-size: 30px;
}
.MYUje h3 {
  font-size: 24px;
}
.MYUje h4 {
  font-size: 18px;
}
.MYUje h5 {
  font-size: 16px;
}
.MYUje h6 {
  font-size: 14px;
}

._3pxCl b, ._3pxCl strong {
  color: #1D1E26;
}
._3pxCl ol, ._3pxCl ul {
  padding: 0;
}
._3pxCl li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3pxCl pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3pxCl img {
  max-width: 100%;
}
._3pxCl figure {
  margin: 0;
}
._3pxCl figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3pxCl p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1ieLm b, ._1ieLm strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1ieLm i, ._1ieLm em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1ieLm a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1ieLm a:hover {
  text-decoration: none;
  color: #007799;
}
._1ieLm code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2var2 {
  font-size: 16px;
}

.FJLRi {
  font-size: 14px;
}

._3y4ut {
  font-size: 12px;
}

._2_4Pw {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3A38f {
  box-sizing: content-box;
}
._3A38f.-clickable:hover {
  background-color: #FAFAFC;
  cursor: pointer;
}

.No9yD {
  box-shadow: inset 0 10px 5px -10px rgba(51, 51, 51, 0.2);
}

.JGq4v {
  min-height: 24px;
}

._2C9kx {
  min-height: 30px;
}

._2rjCO {
  min-height: 36px;
}

._3r_BD {
  min-height: 42px;
}

._2K6eb {
  min-height: 48px;
}

._204Yu {
  padding-left: 6px;
  padding-right: 6px;
}

._2RqUl {
  padding-left: 12px;
  padding-right: 12px;
}

._25Yop {
  padding-left: 18px;
  padding-right: 18px;
}

._1RvKg {
  padding-left: 24px;
  padding-right: 24px;
}

._2QLmq {
  margin: 24px;
}

._1jy4l {
  margin: 12px;
}

._2OpOM {
  padding-top: 6px;
  padding-bottom: 6px;
}

._2YdL5 {
  padding-top: 9px;
  padding-bottom: 9px;
}

._3uA9P {
  padding-top: 12px;
  padding-bottom: 12px;
}

._16kIW {
  padding-top: 18px;
  padding-bottom: 18px;
}

._16RXQ {
  padding-top: 24px;
  padding-bottom: 24px;
}

.uVCzy > *:not(:last-child) {
  margin-right: 3px;
}
.uVCzy > *:not(:first-child) {
  margin-left: 3px;
}

._1MS1u > *:not(:last-child) {
  margin-right: 6px;
}
._1MS1u > *:not(:first-child) {
  margin-left: 6px;
}

._28bJK > *:not(:last-child) {
  margin-right: 9px;
}
._28bJK > *:not(:first-child) {
  margin-left: 9px;
}

.RjQs6 {
  background-color: white;
}

._2zlny {
  background-color: #FAFAFC;
}

._1Jv3N {
  background-color: #F5F6FA;
}

._2l2Ul {
  background-color: transparent;
}

._16fmH {
  border-bottom: 1px solid #FAFAFC;
}

._3WeTa {
  border-bottom: 1px solid #E1E3EB;
}

._3xPEZ {
  border-bottom: 1px solid #CED0DB;
}

.W6Vdf {
  border-bottom: 1px solid #474A59;
}