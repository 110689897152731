/* Carbon scale */
/* Night scale */
._2VIKX .hero-header, ._2VIKX h1, ._2VIKX h2, ._2VIKX h3, ._2VIKX h4, ._2VIKX h5, ._2VIKX h6 {
  margin: 0;
}
._2VIKX .hero-header, ._2VIKX h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2VIKX .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2VIKX h3, ._2VIKX h4, ._2VIKX h5, ._2VIKX h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2VIKX h1, ._2VIKX h2, ._2VIKX h3, ._2VIKX h4, ._2VIKX h5, ._2VIKX h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2VIKX h1 {
  font-size: 42px;
}
._2VIKX h2 {
  font-size: 30px;
}
._2VIKX h3 {
  font-size: 24px;
}
._2VIKX h4 {
  font-size: 18px;
}
._2VIKX h5 {
  font-size: 16px;
}
._2VIKX h6 {
  font-size: 14px;
}

._3pFeo b, ._3pFeo strong {
  color: #1D1E26;
}
._3pFeo ol, ._3pFeo ul {
  padding: 0;
}
._3pFeo li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3pFeo pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3pFeo img {
  max-width: 100%;
}
._3pFeo figure {
  margin: 0;
}
._3pFeo figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3pFeo p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2V5JQ b, ._2V5JQ strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2V5JQ i, ._2V5JQ em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2V5JQ a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2V5JQ a:hover {
  text-decoration: none;
  color: #007799;
}
._2V5JQ code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3sEfc {
  font-size: 16px;
}

._1TLGi {
  font-size: 14px;
}

._25IZC {
  font-size: 12px;
}

._1WShp {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

@media screen and (max-width: 720px) {
  ._2sJhh {
    height: var(--app-mobile-height);
    border-radius: 0;
  }
}