/* Carbon scale */
/* Night scale */
.pFBnr .hero-header, .pFBnr h1, .pFBnr h2, .pFBnr h3, .pFBnr h4, .pFBnr h5, .pFBnr h6 {
  margin: 0;
}
.pFBnr .hero-header, .pFBnr h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.pFBnr .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.pFBnr h3, .pFBnr h4, .pFBnr h5, .pFBnr h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.pFBnr h1, .pFBnr h2, .pFBnr h3, .pFBnr h4, .pFBnr h5, .pFBnr h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.pFBnr h1 {
  font-size: 42px;
}
.pFBnr h2 {
  font-size: 30px;
}
.pFBnr h3 {
  font-size: 24px;
}
.pFBnr h4 {
  font-size: 18px;
}
.pFBnr h5 {
  font-size: 16px;
}
.pFBnr h6 {
  font-size: 14px;
}

._12Rom b, ._12Rom strong {
  color: #1D1E26;
}
._12Rom ol, ._12Rom ul {
  padding: 0;
}
._12Rom li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._12Rom pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._12Rom img {
  max-width: 100%;
}
._12Rom figure {
  margin: 0;
}
._12Rom figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._12Rom p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.aHfU9 b, .aHfU9 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.aHfU9 i, .aHfU9 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.aHfU9 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.aHfU9 a:hover {
  text-decoration: none;
  color: #007799;
}
.aHfU9 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._35q4Z {
  font-size: 16px;
}

._39cO5 {
  font-size: 14px;
}

._34j8M {
  font-size: 12px;
}

._2xCW9 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.RkKl5 {
  width: 300px;
  border-radius: 6px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

._2Fcpk {
  width: 20%;
  display: flex;
}
._2Fcpk._2caFu {
  background: linear-gradient(180deg, #fb7d7d 0%, #FA4B4B 100%);
}
._2Fcpk.fu-9C {
  background: linear-gradient(180deg, #ffbc30 0%, #FCAA00 100%);
}
._2Fcpk._3IFc9 {
  background: linear-gradient(180deg, #87d600 0%, #67A300 100%);
}
._2Fcpk._2kENa {
  background: linear-gradient(180deg, #00c6ff 0%, #009ECC 100%);
}

._3C5nl {
  width: 24px;
  margin: auto;
  fill: white;
}

._2yDgv {
  padding: 9px 9px 9px 3px;
}

._29vI2 {
  width: 80%;
  display: flex;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
}

._2LL9J {
  width: 85%;
}