._1Mp8f {
  position: relative;
}

._36PQu {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  border: 2px solid white;
}

._3i0uf {
  bottom: -12px;
}

._1YJIA {
  bottom: -18px;
}

.JPDVi {
  bottom: -24px;
}

._3vmXZ {
  bottom: -72px;
}