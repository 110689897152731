/* Carbon scale */
/* Night scale */
._229zO .hero-header, ._229zO h1, ._229zO h2, ._229zO h3, ._229zO h4, ._229zO h5, ._229zO h6 {
  margin: 0;
}
._229zO .hero-header, ._229zO h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._229zO .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._229zO h3, ._229zO h4, ._229zO h5, ._229zO h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._229zO h1, ._229zO h2, ._229zO h3, ._229zO h4, ._229zO h5, ._229zO h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._229zO h1 {
  font-size: 42px;
}
._229zO h2 {
  font-size: 30px;
}
._229zO h3 {
  font-size: 24px;
}
._229zO h4 {
  font-size: 18px;
}
._229zO h5 {
  font-size: 16px;
}
._229zO h6 {
  font-size: 14px;
}

._3clv_ b, ._3clv_ strong {
  color: #1D1E26;
}
._3clv_ ol, ._3clv_ ul {
  padding: 0;
}
._3clv_ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3clv_ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3clv_ img {
  max-width: 100%;
}
._3clv_ figure {
  margin: 0;
}
._3clv_ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3clv_ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._20Wvc b, ._20Wvc strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._20Wvc i, ._20Wvc em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._20Wvc a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._20Wvc a:hover {
  text-decoration: none;
  color: #007799;
}
._20Wvc code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.dJ0iS {
  font-size: 16px;
}

.QexBt {
  font-size: 14px;
}

._3URSF {
  font-size: 12px;
}

._1X9-2 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3qSFU {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #CED0DB;
}
._3qSFU.uui-button-box {
  background-color: #fff;
  border-width: 1px !important;
}