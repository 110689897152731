/* Carbon scale */
/* Night scale */
._2QUV6 .hero-header, ._2QUV6 h1, ._2QUV6 h2, ._2QUV6 h3, ._2QUV6 h4, ._2QUV6 h5, ._2QUV6 h6 {
  margin: 0;
}
._2QUV6 .hero-header, ._2QUV6 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2QUV6 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2QUV6 h3, ._2QUV6 h4, ._2QUV6 h5, ._2QUV6 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2QUV6 h1, ._2QUV6 h2, ._2QUV6 h3, ._2QUV6 h4, ._2QUV6 h5, ._2QUV6 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2QUV6 h1 {
  font-size: 42px;
}
._2QUV6 h2 {
  font-size: 30px;
}
._2QUV6 h3 {
  font-size: 24px;
}
._2QUV6 h4 {
  font-size: 18px;
}
._2QUV6 h5 {
  font-size: 16px;
}
._2QUV6 h6 {
  font-size: 14px;
}

._3Ic93 b, ._3Ic93 strong {
  color: #1D1E26;
}
._3Ic93 ol, ._3Ic93 ul {
  padding: 0;
}
._3Ic93 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3Ic93 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3Ic93 img {
  max-width: 100%;
}
._3Ic93 figure {
  margin: 0;
}
._3Ic93 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3Ic93 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2P5Ny b, ._2P5Ny strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2P5Ny i, ._2P5Ny em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2P5Ny a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2P5Ny a:hover {
  text-decoration: none;
  color: #007799;
}
._2P5Ny code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3xT_3 {
  font-size: 16px;
}

.L0NcV {
  font-size: 14px;
}

._1BD-T {
  font-size: 12px;
}

._30nvQ {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3y1-R {
  animation-duration: 0.3s;
  animation-name: _35N1B;
  opacity: 1;
  transition: all 0.3s ease-out;
}
@keyframes _35N1B {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._3y1-R._2Pbnt .uui-modal-blocker {
  background-color: rgba(245, 245, 245, 0.9);
}
._3y1-R._2noC2 .uui-modal-blocker {
  background-color: #1D1E26;
  opacity: 0.7;
}
._3y1-R._30jLH .uui-modal-blocker {
  background-color: transparent;
}

._1A97b.QQl6_.uui-modal-window {
  width: 300px;
}
@media (max-width: 640px) {
  ._1A97b.QQl6_.uui-modal-window {
    width: unset;
  }
}
._1A97b._1O2oQ.uui-modal-window {
  width: 420px;
}
@media (max-width: 640px) {
  ._1A97b._1O2oQ.uui-modal-window {
    width: unset;
  }
}
._1A97b.OuVgt.uui-modal-window {
  width: 480px;
}
@media (max-width: 640px) {
  ._1A97b.OuVgt.uui-modal-window {
    width: unset;
  }
}
._1A97b._2MoQn.uui-modal-window {
  width: 600px;
}
@media (max-width: 640px) {
  ._1A97b._2MoQn.uui-modal-window {
    width: unset;
  }
}
._1A97b._3nGhb.uui-modal-window {
  width: 900px;
}
@media (max-width: 640px) {
  ._1A97b._3nGhb.uui-modal-window {
    width: unset;
  }
}
._1A97b._3Roo6.uui-modal-window {
  height: 300px;
}
._1A97b._2-Fxl.uui-modal-window {
  height: 700px;
}
._1A97b.uui-modal-window {
  background-color: #FFFFFF;
  overflow: auto;
  border-radius: 6px;
  max-height: 80vh;
}
@media (max-width: 640px) {
  ._1A97b.uui-modal-window {
    min-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}
._1A97b.uui-modal-window .uui-shadow-top::before {
  background: #CED0DB;
  height: 1px;
}
._1A97b.uui-modal-window .uui-shadow-bottom::after {
  background: #CED0DB;
  height: 1px;
}

._3UK0f {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
._3UK0f._2XZWl {
  border-top: 1px solid #CED0DB;
}

.bJnTM {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}