._2a1q- {
  display: flex;
  cursor: pointer;
  align-items: center;
}
._2a1q-:focus-within {
  outline: none;
}
._2a1q- .uui-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
._2a1q- .uui-checkbox input[type=checkbox] {
  margin: 0;
  border: none;
  width: 0;
  height: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
._2a1q- .uui-checkbox input[type=checkbox]:focus {
  outline: none;
}