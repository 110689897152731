/* Carbon scale */
/* Night scale */
._3WtZ1 .hero-header, ._3WtZ1 h1, ._3WtZ1 h2, ._3WtZ1 h3, ._3WtZ1 h4, ._3WtZ1 h5, ._3WtZ1 h6 {
  margin: 0;
}
._3WtZ1 .hero-header, ._3WtZ1 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3WtZ1 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3WtZ1 h3, ._3WtZ1 h4, ._3WtZ1 h5, ._3WtZ1 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3WtZ1 h1, ._3WtZ1 h2, ._3WtZ1 h3, ._3WtZ1 h4, ._3WtZ1 h5, ._3WtZ1 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3WtZ1 h1 {
  font-size: 42px;
}
._3WtZ1 h2 {
  font-size: 30px;
}
._3WtZ1 h3 {
  font-size: 24px;
}
._3WtZ1 h4 {
  font-size: 18px;
}
._3WtZ1 h5 {
  font-size: 16px;
}
._3WtZ1 h6 {
  font-size: 14px;
}

._2IxaO b, ._2IxaO strong {
  color: #1D1E26;
}
._2IxaO ol, ._2IxaO ul {
  padding: 0;
}
._2IxaO li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2IxaO pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2IxaO img {
  max-width: 100%;
}
._2IxaO figure {
  margin: 0;
}
._2IxaO figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2IxaO p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2llXR b, ._2llXR strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2llXR i, ._2llXR em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2llXR a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2llXR a:hover {
  text-decoration: none;
  color: #007799;
}
._2llXR code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.KMJFC {
  font-size: 16px;
}

._1j6JB {
  font-size: 14px;
}

._11fXm {
  font-size: 12px;
}

._3PmqJ {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._24XCl {
  padding: 12px 18px 12px 12px;
  border-radius: 6px;
  display: flex;
  overflow: hidden;
  min-width: 170px;
}

._1rEwK {
  height: 100%;
  margin-right: 12px;
}

._2GC4x {
  display: flex;
}

.KUi8Z:not(:last-child) {
  margin-right: 12px;
}

.g4cN7 {
  width: 100%;
  display: flex;
}

._1RHaC {
  height: 100%;
  margin-left: 17px;
}

._1C0ZI {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.bOdM0 {
  background-color: rgba(252, 170, 0, 0.1);
  border-left: 6px solid #FCAA00;
}
.bOdM0 ._1rEwK .uui-icon {
  fill: #FCAA00;
}

._3qClK {
  background-color: rgba(0, 158, 204, 0.1);
  border-left: 6px solid #009ECC;
}
._3qClK ._1rEwK .uui-icon {
  fill: #009ECC;
}

._2ItLl {
  background-color: rgba(250, 75, 75, 0.1);
  border-left: 6px solid #FA4B4B;
}
._2ItLl ._1rEwK .uui-icon {
  fill: #FA4B4B;
}

._2RYDH {
  background-color: rgba(103, 163, 0, 0.1);
  border-left: 6px solid #67A300;
}
._2RYDH ._1rEwK .uui-icon {
  fill: #67A300;
}