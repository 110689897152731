/* Carbon scale */
/* Night scale */
._3R1fy .hero-header, ._3R1fy h1, ._3R1fy h2, ._3R1fy h3, ._3R1fy h4, ._3R1fy h5, ._3R1fy h6 {
  margin: 0;
}
._3R1fy .hero-header, ._3R1fy h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3R1fy .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3R1fy h3, ._3R1fy h4, ._3R1fy h5, ._3R1fy h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3R1fy h1, ._3R1fy h2, ._3R1fy h3, ._3R1fy h4, ._3R1fy h5, ._3R1fy h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3R1fy h1 {
  font-size: 42px;
}
._3R1fy h2 {
  font-size: 30px;
}
._3R1fy h3 {
  font-size: 24px;
}
._3R1fy h4 {
  font-size: 18px;
}
._3R1fy h5 {
  font-size: 16px;
}
._3R1fy h6 {
  font-size: 14px;
}

._2XG9q b, ._2XG9q strong {
  color: #1D1E26;
}
._2XG9q ol, ._2XG9q ul {
  padding: 0;
}
._2XG9q li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2XG9q pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2XG9q img {
  max-width: 100%;
}
._2XG9q figure {
  margin: 0;
}
._2XG9q figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2XG9q p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.aIcJT b, .aIcJT strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.aIcJT i, .aIcJT em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.aIcJT a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.aIcJT a:hover {
  text-decoration: none;
  color: #007799;
}
.aIcJT code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1_e_X {
  font-size: 16px;
}

._3liEe {
  font-size: 14px;
}

._3YvTo {
  font-size: 12px;
}

._3ns6x {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.NHnjL {
  --uui-dt-row-background: #FAFAFC;
  --uui-dt-row-border-color: #E1E3EB;
  --uui-dt-row-border-width: 1px;
}

._2Y9Q_ {
  background: none;
}

._1K_9G {
  position: absolute;
  height: 100%;
  right: 24px;
  z-index: 2;
}