/* Carbon scale */
/* Night scale */
._1jhym .hero-header, ._1jhym h1, ._1jhym h2, ._1jhym h3, ._1jhym h4, ._1jhym h5, ._1jhym h6 {
  margin: 0;
}
._1jhym .hero-header, ._1jhym h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1jhym .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1jhym h3, ._1jhym h4, ._1jhym h5, ._1jhym h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1jhym h1, ._1jhym h2, ._1jhym h3, ._1jhym h4, ._1jhym h5, ._1jhym h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1jhym h1 {
  font-size: 42px;
}
._1jhym h2 {
  font-size: 30px;
}
._1jhym h3 {
  font-size: 24px;
}
._1jhym h4 {
  font-size: 18px;
}
._1jhym h5 {
  font-size: 16px;
}
._1jhym h6 {
  font-size: 14px;
}

.unSf3 b, .unSf3 strong {
  color: #1D1E26;
}
.unSf3 ol, .unSf3 ul {
  padding: 0;
}
.unSf3 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.unSf3 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.unSf3 img {
  max-width: 100%;
}
.unSf3 figure {
  margin: 0;
}
.unSf3 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.unSf3 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3DMAG b, ._3DMAG strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3DMAG i, ._3DMAG em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3DMAG a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3DMAG a:hover {
  text-decoration: none;
  color: #007799;
}
._3DMAG code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3-Klm {
  font-size: 16px;
}

.RT1ia {
  font-size: 14px;
}

._2MuCp {
  font-size: 12px;
}

._36VEG {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1QLYK .uui-accordion-toggler {
  font-family: "Sans Semibold", Arial, sans-serif;
  cursor: pointer;
}
._1QLYK .uui-accordion-body {
  font-family: "Sans Regular", Arial, sans-serif;
}
._1QLYK._2giVP {
  border-radius: 6px;
}
._1QLYK._2giVP .uui-accordion-toggler {
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(29, 30, 38, 0.1), 0 2px 10px 0 rgba(29, 30, 38, 0.15);
  border-radius: 6px;
  fill: #6C6F80;
  line-height: 24px;
  font-size: 18px;
  padding: 0 24px;
}
._1QLYK._2giVP .uui-accordion-toggler .uui-accordion-toggle-container {
  min-height: 48px;
  padding-top: 5px;
  padding-bottom: 5px;
}
._1QLYK._2giVP .uui-accordion-toggler .uui-accordion-toggle-container .uui-accordion-title {
  color: #474A59;
}
._1QLYK._2giVP .uui-accordion-toggler:hover {
  box-shadow: 0 4px 22px 0 rgba(29, 30, 38, 0.15);
}
._1QLYK._2giVP .uui-accordion-body {
  background: #FFFFFF;
  padding: 18px 24px;
  line-height: 24px;
  letter-spacing: 0;
  border-top: unset;
  border-radius: 0 0 6px 6px;
  overflow-wrap: anywhere;
}
._1QLYK._2giVP.uui-opened {
  box-shadow: 0 1px 2px 0 rgba(29, 30, 38, 0.1), 0 2px 10px 0 rgba(29, 30, 38, 0.15);
}
._1QLYK._2giVP.uui-opened .uui-accordion-toggler {
  border-bottom: 1px solid #CED0DB;
  border-radius: 6px 6px 0 0;
  box-shadow: unset;
}
._1QLYK._2giVP.uui-opened .uui-accordion-toggler:hover {
  box-shadow: unset;
}
._1QLYK._2giVP.uui-disabled {
  box-shadow: 0 1px 2px 0 rgba(29, 30, 38, 0.1), 0 2px 10px 0 rgba(29, 30, 38, 0.15);
}
._1QLYK._2giVP.uui-disabled .uui-accordion-toggler {
  cursor: default;
  fill: #ACAFBF;
  box-shadow: unset;
}
._1QLYK._2giVP.uui-disabled .uui-accordion-toggler .uui-accordion-title {
  color: #9FA1AE;
}
._1QLYK._2giVP.uui-disabled .uui-opened {
  border-radius: 6px 6px 0 0;
}
._1QLYK.oKb1_ .uui-accordion-toggler {
  border-bottom: 1px solid #CED0DB;
  fill: #6C6F80;
  line-height: 18px;
  font-size: 14px;
  padding: 8px 12px 8px;
}
._1QLYK.oKb1_ .uui-accordion-toggler .uui-accordion-toggle-container {
  min-height: 24px;
}
._1QLYK.oKb1_ .uui-accordion-toggler .uui-accordion-toggle-container .uui-accordion-title {
  color: #474A59;
}
._1QLYK.oKb1_ .uui-accordion-body {
  padding: 1px 12px 12px;
  border-bottom: 1px solid #CED0DB;
}
._1QLYK.oKb1_.uui-opened .uui-accordion-toggler {
  border-bottom: unset;
}
._1QLYK.oKb1_.uui-disabled .uui-accordion-toggler {
  cursor: default;
  fill: #ACAFBF;
}
._1QLYK.oKb1_.uui-disabled .uui-accordion-toggler .uui-accordion-title {
  color: #9FA1AE;
}
._1QLYK._22Vm1 .uui-accordion-toggler, ._1QLYK._22Vm1 .uui-accordion-body {
  padding-left: 0px;
  padding-right: 0px;
}
._1QLYK.MTn2p .uui-accordion-toggler, ._1QLYK.MTn2p .uui-accordion-body {
  padding-left: 6px;
  padding-right: 6px;
}
._1QLYK.gzbBG .uui-accordion-toggler, ._1QLYK.gzbBG .uui-accordion-body {
  padding-left: 12px;
  padding-right: 12px;
}
._1QLYK._3mxx7 .uui-accordion-toggler, ._1QLYK._3mxx7 .uui-accordion-body {
  padding-left: 18px;
  padding-right: 18px;
}