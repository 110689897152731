/* Carbon scale */
/* Night scale */
._1_Mbl .hero-header, ._1_Mbl h1, ._1_Mbl h2, ._1_Mbl h3, ._1_Mbl h4, ._1_Mbl h5, ._1_Mbl h6 {
  margin: 0;
}
._1_Mbl .hero-header, ._1_Mbl h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1_Mbl .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1_Mbl h3, ._1_Mbl h4, ._1_Mbl h5, ._1_Mbl h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1_Mbl h1, ._1_Mbl h2, ._1_Mbl h3, ._1_Mbl h4, ._1_Mbl h5, ._1_Mbl h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1_Mbl h1 {
  font-size: 42px;
}
._1_Mbl h2 {
  font-size: 30px;
}
._1_Mbl h3 {
  font-size: 24px;
}
._1_Mbl h4 {
  font-size: 18px;
}
._1_Mbl h5 {
  font-size: 16px;
}
._1_Mbl h6 {
  font-size: 14px;
}

._2uU98 b, ._2uU98 strong {
  color: #1D1E26;
}
._2uU98 ol, ._2uU98 ul {
  padding: 0;
}
._2uU98 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2uU98 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2uU98 img {
  max-width: 100%;
}
._2uU98 figure {
  margin: 0;
}
._2uU98 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2uU98 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.F2Otp b, .F2Otp strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.F2Otp i, .F2Otp em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.F2Otp a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.F2Otp a:hover {
  text-decoration: none;
  color: #007799;
}
.F2Otp code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.sRhpG {
  font-size: 16px;
}

._2Qzho {
  font-size: 14px;
}

._2GAwX {
  font-size: 12px;
}

._1RwDJ {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2Cf7S .uui-calendar-content {
  font-family: "Sans Regular", Arial, sans-serif;
}
._2Cf7S .uui-calendar-weekday {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #ACAFBF;
  width: 34px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
._2Cf7S .uui-calendar-day-cell {
  color: #474A59;
  text-align: center;
  padding: 0;
  height: 36px;
  width: 36px;
  display: flex;
  position: relative;
}
._2Cf7S .uui-calendar-day-cell:focus {
  outline: none;
}
._2Cf7S .uui-calendar-day-wrapper {
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  margin: auto;
  cursor: default;
}
._2Cf7S .uui-calendar-day {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 34px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
._2Cf7S .uui-calendar-clickable-day:hover:not(.uui-calendar-filtered-day) {
  cursor: pointer;
}
._2Cf7S .uui-calendar-clickable-day:hover:not(.uui-calendar-filtered-day) .uui-calendar-day {
  border: 1px solid #009ECC;
  background-color: #FFFFFF;
  z-index: 1;
}
._2Cf7S .uui-calendar-clickable-day:hover:not(.uui-calendar-filtered-day).uui-calendar-selected-day .uui-calendar-day {
  border: 1px solid #009ECC;
  background-color: #009ECC;
}
._2Cf7S .uui-calendar-current-day.uui-calendar-day-wrapper {
  color: #009ECC;
}
._2Cf7S .uui-calendar-selected-day .uui-calendar-day {
  background-color: #009ECC;
  color: #FFFFFF;
}
._2Cf7S .uui-calendar-selected-day.uui-calendar-clickable-day:hover {
  cursor: pointer;
}
._2Cf7S .uui-calendar-day-holiday {
  color: #6C6F80;
}
._2Cf7S .uui-calendar-filtered-day.uui-calendar-day-wrapper {
  color: #ACAFBF;
}
._2Cf7S .uui-calendar-filtered-day.uui-calendar-day-wrapper:hover {
  cursor: default;
  background-color: transparent;
}
._2Cf7S .uui-calendar-filtered-day.uui-calendar-day-wrapper.uui-calendar-selected-day .uui-calendar-day {
  background-color: #B3B6C3;
}