/* Carbon scale */
/* Night scale */
._3QoF6 .hero-header, ._3QoF6 h1, ._3QoF6 h2, ._3QoF6 h3, ._3QoF6 h4, ._3QoF6 h5, ._3QoF6 h6 {
  margin: 0;
}
._3QoF6 .hero-header, ._3QoF6 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3QoF6 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3QoF6 h3, ._3QoF6 h4, ._3QoF6 h5, ._3QoF6 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3QoF6 h1, ._3QoF6 h2, ._3QoF6 h3, ._3QoF6 h4, ._3QoF6 h5, ._3QoF6 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3QoF6 h1 {
  font-size: 42px;
}
._3QoF6 h2 {
  font-size: 30px;
}
._3QoF6 h3 {
  font-size: 24px;
}
._3QoF6 h4 {
  font-size: 18px;
}
._3QoF6 h5 {
  font-size: 16px;
}
._3QoF6 h6 {
  font-size: 14px;
}

._1H0_j b, ._1H0_j strong {
  color: #1D1E26;
}
._1H0_j ol, ._1H0_j ul {
  padding: 0;
}
._1H0_j li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1H0_j pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1H0_j img {
  max-width: 100%;
}
._1H0_j figure {
  margin: 0;
}
._1H0_j figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1H0_j p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2AXn7 b, ._2AXn7 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2AXn7 i, ._2AXn7 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2AXn7 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2AXn7 a:hover {
  text-decoration: none;
  color: #007799;
}
._2AXn7 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._4gQYV {
  font-size: 16px;
}

._3YmNx {
  font-size: 14px;
}

._1xkNz {
  font-size: 12px;
}

._2PHVG {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1ECE0 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 600px;
  width: 100%;
}
._1ECE0._3DBsQ {
  background-color: #FAFAFC;
}
._1ECE0._3tXPT {
  background-color: #222430;
}

._2csQP {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
}

._2lWDf {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin-top: 24px;
}
._2lWDf._3DBsQ {
  color: #1D1E26;
}
._2lWDf._3tXPT {
  color: #FFFFFF;
}

._1gbo9 {
  font-family: "Sans Regular", Arial, sans-serif;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
}
._1gbo9._3DBsQ {
  color: #1D1E26;
}
._1gbo9._3tXPT {
  color: #FFFFFF;
}

._1pcf8 {
  flex: 1 1 420px;
  width: 560px;
  max-width: 100%;
}