._1y2tF {
  cursor: grab;
}
._1y2tF.uui-drag-handle {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMjHxIGmVAAAAI0lEQVQoU2M4e/bsfxAGAgYQRucTVgBj4MJYBZHxwLvhPwMAPCnF0V9FrXoAAAAASUVORK5CYII=");
  width: 8px;
  flex: 0 0 8px;
}
._1y2tF.uui-disabled {
  cursor: auto;
}