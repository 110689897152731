._1xinJ .uui-label {
  display: flex;
  align-items: center;
}
._1xinJ .uui-label .uui-labeled-input-info-icon {
  display: flex;
}
._1xinJ ._1Pr48 {
  flex-grow: 1;
  text-align: right;
  text-overflow: ellipsis;
}
._1xinJ .uui-label-left {
  display: flex;
  align-items: center;
}
._1xinJ .g8yDb {
  flex-basis: 0;
  flex-grow: 1;
}