/* Carbon scale */
/* Night scale */
._3I9ec .hero-header, ._3I9ec h1, ._3I9ec h2, ._3I9ec h3, ._3I9ec h4, ._3I9ec h5, ._3I9ec h6 {
  margin: 0;
}
._3I9ec .hero-header, ._3I9ec h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3I9ec .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3I9ec h3, ._3I9ec h4, ._3I9ec h5, ._3I9ec h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3I9ec h1, ._3I9ec h2, ._3I9ec h3, ._3I9ec h4, ._3I9ec h5, ._3I9ec h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3I9ec h1 {
  font-size: 42px;
}
._3I9ec h2 {
  font-size: 30px;
}
._3I9ec h3 {
  font-size: 24px;
}
._3I9ec h4 {
  font-size: 18px;
}
._3I9ec h5 {
  font-size: 16px;
}
._3I9ec h6 {
  font-size: 14px;
}

._3ydqy b, ._3ydqy strong {
  color: #1D1E26;
}
._3ydqy ol, ._3ydqy ul {
  padding: 0;
}
._3ydqy li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3ydqy pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3ydqy img {
  max-width: 100%;
}
._3ydqy figure {
  margin: 0;
}
._3ydqy figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3ydqy p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2ujPp b, ._2ujPp strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2ujPp i, ._2ujPp em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2ujPp a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2ujPp a:hover {
  text-decoration: none;
  color: #007799;
}
._2ujPp code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._13mv2 {
  font-size: 16px;
}

._2byHf {
  font-size: 14px;
}

._1Vnza {
  font-size: 12px;
}

._2tepS {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2CEUe {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
}
._2CEUe:focus {
  box-shadow: none;
}
._2CEUe:hover {
  cursor: default;
}
._2CEUe .uui-caption {
  font-family: "Sans Regular", Arial, sans-serif;
  align-self: center;
  white-space: nowrap;
}
._2CEUe .uui-count {
  font-size: 12px;
  letter-spacing: 0;
  color: #6C6F80;
  background-color: #FFFFFF;
  padding: 0 6px;
  border-radius: 9px;
  min-height: 18px;
  line-height: 18px;
}
._2CEUe .uui-count:not(:first-child) {
  margin-left: 6px;
}
._2CEUe.uui-button-box {
  border-radius: 30px;
}
._2CEUe._2MDW0 {
  min-width: 18px;
  height: 18px;
  padding-left: 2px;
  padding-right: 2px;
}
._2CEUe._2MDW0 .uui-caption, ._2CEUe._2MDW0 .uui-input {
  line-height: 16px;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
}
._2CEUe._2MDW0 .uui-count {
  min-height: 12px;
  min-width: 6px;
  line-height: 12px;
  font-size: 8px;
  padding: 0 3px;
}
._2CEUe._2MDW0 .uui-count:not(:first-child) {
  margin-left: 3px;
}
._2CEUe._2rxFO {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
  height: 24px;
}
._2CEUe._2rxFO .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2CEUe._2rxFO .uui-caption, ._2CEUe._2rxFO .uui-input {
  line-height: 22px;
  font-size: 12px;
}
._2CEUe._2XekP {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
}
._2CEUe._2XekP .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2CEUe._2XekP .uui-caption, ._2CEUe._2XekP .uui-input {
  line-height: 22px;
  font-size: 14px;
}
._2CEUe.JNTnP {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
  height: 36px;
  padding-top: 6px;
  padding-bottom: 6px;
}
._2CEUe.JNTnP .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2CEUe.JNTnP .uui-caption, ._2CEUe.JNTnP .uui-input {
  line-height: 22px;
  font-size: 14px;
}
._2CEUe.jp9SM {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
  height: 42px;
  padding-top: 8px;
  padding-bottom: 8px;
}
._2CEUe.jp9SM .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2CEUe.jp9SM .uui-caption, ._2CEUe.jp9SM .uui-input {
  line-height: 24px;
  font-size: 16px;
}
._2CEUe.jp9SM .uui-count {
  min-height: 24px;
  min-width: 12px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 12px;
}
._2CEUe._1ji-b {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
  height: 48px;
  padding-top: 11px;
  padding-bottom: 11px;
}
._2CEUe._1ji-b .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2CEUe._1ji-b .uui-caption, ._2CEUe._1ji-b .uui-input {
  line-height: 24px;
  font-size: 16px;
}
._2CEUe._1ji-b .uui-count {
  min-height: 24px;
  min-width: 12px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 12px;
}
._2CEUe._2LgCj {
  background-color: var(--uui-color-700);
  color: var(--uui-contrast-color-700);
  fill: var(--uui-contrast-color-700);
  border-color: var(--uui-color-700);
}
._2CEUe._3aYgZ {
  background-color: #FFFFFF;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._2CEUe._3ipdA {
  background-color: transparent;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}