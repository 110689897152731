/* Carbon scale */
/* Night scale */
._1WQrp .hero-header, ._1WQrp h1, ._1WQrp h2, ._1WQrp h3, ._1WQrp h4, ._1WQrp h5, ._1WQrp h6 {
  margin: 0;
}
._1WQrp .hero-header, ._1WQrp h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1WQrp .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1WQrp h3, ._1WQrp h4, ._1WQrp h5, ._1WQrp h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1WQrp h1, ._1WQrp h2, ._1WQrp h3, ._1WQrp h4, ._1WQrp h5, ._1WQrp h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1WQrp h1 {
  font-size: 42px;
}
._1WQrp h2 {
  font-size: 30px;
}
._1WQrp h3 {
  font-size: 24px;
}
._1WQrp h4 {
  font-size: 18px;
}
._1WQrp h5 {
  font-size: 16px;
}
._1WQrp h6 {
  font-size: 14px;
}

.wO9Tg b, .wO9Tg strong {
  color: #1D1E26;
}
.wO9Tg ol, .wO9Tg ul {
  padding: 0;
}
.wO9Tg li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.wO9Tg pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.wO9Tg img {
  max-width: 100%;
}
.wO9Tg figure {
  margin: 0;
}
.wO9Tg figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.wO9Tg p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3rIEp b, ._3rIEp strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3rIEp i, ._3rIEp em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3rIEp a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3rIEp a:hover {
  text-decoration: none;
  color: #007799;
}
._3rIEp code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2U_yb {
  font-size: 16px;
}

._2eqBN {
  font-size: 14px;
}

._2bXHC {
  font-size: 12px;
}

._353a- {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3kMev .uui-tooltip-body {
  padding: 3px 12px;
  line-height: 18px;
  font-size: 12px;
  font-family: "Sans Regular", Arial, sans-serif;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  border-radius: 3px;
  word-wrap: break-word;
}
._3kMev .uui-popover-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
._3kMev .uui-popover-arrow::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
._3kMev .uui-tooltip-body {
  background-color: var(--uui-color-700);
  color: var(--uui-contrast-color-700);
}
._3kMev.uui-tooltip-container[data-placement^="right"] .uui-popover-arrow::after {
  background: var(--uui-color-700);
}
._3kMev.uui-tooltip-container[data-placement^="left"] .uui-popover-arrow::after {
  background: var(--uui-color-700);
}
._3kMev.uui-tooltip-container[data-placement^="top"] .uui-popover-arrow::after {
  background: var(--uui-color-700);
}
._3kMev.uui-tooltip-container[data-placement^="bottom"] .uui-popover-arrow::after {
  background: var(--uui-color-700);
}