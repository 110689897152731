/* Carbon scale */
/* Night scale */
._108LK .hero-header, ._108LK h1, ._108LK h2, ._108LK h3, ._108LK h4, ._108LK h5, ._108LK h6 {
  margin: 0;
}
._108LK .hero-header, ._108LK h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._108LK .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._108LK h3, ._108LK h4, ._108LK h5, ._108LK h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._108LK h1, ._108LK h2, ._108LK h3, ._108LK h4, ._108LK h5, ._108LK h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._108LK h1 {
  font-size: 42px;
}
._108LK h2 {
  font-size: 30px;
}
._108LK h3 {
  font-size: 24px;
}
._108LK h4 {
  font-size: 18px;
}
._108LK h5 {
  font-size: 16px;
}
._108LK h6 {
  font-size: 14px;
}

._3Z-Na b, ._3Z-Na strong {
  color: #1D1E26;
}
._3Z-Na ol, ._3Z-Na ul {
  padding: 0;
}
._3Z-Na li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3Z-Na pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3Z-Na img {
  max-width: 100%;
}
._3Z-Na figure {
  margin: 0;
}
._3Z-Na figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3Z-Na p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._34pPV b, ._34pPV strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._34pPV i, ._34pPV em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._34pPV a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._34pPV a:hover {
  text-decoration: none;
  color: #007799;
}
._34pPV code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3oyWX {
  font-size: 16px;
}

._1rk8e {
  font-size: 14px;
}

._2c8e8 {
  font-size: 12px;
}

._3Z5nD {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3BilA {
  height: 312px;
}
._3BilA .uui-datepicker-container {
  background-color: #FFFFFF;
}
._3BilA .uui-datepickerheader-header {
  padding-top: 18px;
  padding-bottom: 6px;
}
._3BilA .uui-datepickerBody-wrapper {
  padding: 0 18px 12px;
}
._3BilA .uui-datepickerheader-nav-title {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #009ECC;
  font-size: 14px;
  padding: 3px 10px;
}
._3BilA .uui-datepickerheader-nav-title:hover {
  background-color: #EBEDF5;
  cursor: pointer;
}
._3BilA .uui-datepickerheader-nav-title:focus {
  outline: none;
}
._3BilA .uui-datepickerheader-nav-icon-right, ._3BilA .uui-datepickerheader-nav-icon-left {
  fill: #6C6F80;
  width: 36px;
  height: 36px;
  align-items: center;
}
._3BilA .uui-datepickerheader-nav-icon-right:hover, ._3BilA .uui-datepickerheader-nav-icon-left:hover {
  cursor: pointer;
  background-color: #EBEDF5;
}
._3BilA .uui-datepickerheader-nav-icon-right:focus, ._3BilA .uui-datepickerheader-nav-icon-left:focus {
  outline: none;
}
._3BilA .uui-datepickerheader-nav-icon-right {
  transform: rotate(-90deg);
}
._3BilA .uui-datepickerheader-nav-icon-left {
  transform: rotate(90deg);
}
._3BilA .uui-monthselection-content, ._3BilA .uui-yearselection-content {
  font-family: "Sans Regular", Arial, sans-serif;
}
._3BilA .uui-monthselection-month-container {
  margin: 0 0 12px;
  display: flex;
  flex-direction: column;
}
._3BilA .uui-monthselection-month-container > * {
  display: flex;
}
._3BilA .uui-yearselection-year-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
}
._3BilA .uui-yearselection-year-container > * {
  display: flex;
}
._3BilA .uui-yearselection-year-row {
  display: flex;
  justify-content: space-between;
  width: 252px;
}
._3BilA .uui-monthselection-month {
  width: 72px;
  height: 36px;
  border-radius: 20px;
  margin: 6px;
  color: #474A59;
}
._3BilA .uui-monthselection-month:hover {
  width: 70px;
  height: 34px;
  border: 1px solid #009ECC;
  cursor: pointer;
}
._3BilA .uui-yearselection-year {
  width: 63px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 6px 0;
  color: #474A59;
}
._3BilA .uui-yearselection-year:hover {
  width: 61px;
  height: 34px;
  border: 1px solid #009ECC;
  cursor: pointer;
}
._3BilA .uui-monthselection-current-month.uui-monthselection-month {
  background-color: #009ECC;
  color: #FFFFFF;
}
._3BilA .uui-yearselection-current-year.uui-yearselection-year {
  background-color: #009ECC;
  color: #FFFFFF;
}

._37Z0u {
  width: 100%;
}