/* Carbon scale */
/* Night scale */
._2hoR3 .hero-header, ._2hoR3 h1, ._2hoR3 h2, ._2hoR3 h3, ._2hoR3 h4, ._2hoR3 h5, ._2hoR3 h6 {
  margin: 0;
}
._2hoR3 .hero-header, ._2hoR3 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2hoR3 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2hoR3 h3, ._2hoR3 h4, ._2hoR3 h5, ._2hoR3 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2hoR3 h1, ._2hoR3 h2, ._2hoR3 h3, ._2hoR3 h4, ._2hoR3 h5, ._2hoR3 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2hoR3 h1 {
  font-size: 42px;
}
._2hoR3 h2 {
  font-size: 30px;
}
._2hoR3 h3 {
  font-size: 24px;
}
._2hoR3 h4 {
  font-size: 18px;
}
._2hoR3 h5 {
  font-size: 16px;
}
._2hoR3 h6 {
  font-size: 14px;
}

._3t9Kr b, ._3t9Kr strong {
  color: #1D1E26;
}
._3t9Kr ol, ._3t9Kr ul {
  padding: 0;
}
._3t9Kr li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3t9Kr pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3t9Kr img {
  max-width: 100%;
}
._3t9Kr figure {
  margin: 0;
}
._3t9Kr figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3t9Kr p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3y3vJ b, ._3y3vJ strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3y3vJ i, ._3y3vJ em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3y3vJ a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3y3vJ a:hover {
  text-decoration: none;
  color: #007799;
}
._3y3vJ code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1kFU8 {
  font-size: 16px;
}

._37Olp {
  font-size: 14px;
}

.bQn-4 {
  font-size: 12px;
}

._3gdZw {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1mgFa {
  border-bottom: none;
  border-left: 2px solid transparent;
}
._1mgFa.uui-active {
  border-left: 2px solid;
  border-bottom: none;
}