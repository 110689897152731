/* Carbon scale */
/* Night scale */
._37Fwy .hero-header, ._37Fwy h1, ._37Fwy h2, ._37Fwy h3, ._37Fwy h4, ._37Fwy h5, ._37Fwy h6 {
  margin: 0;
}
._37Fwy .hero-header, ._37Fwy h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._37Fwy .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._37Fwy h3, ._37Fwy h4, ._37Fwy h5, ._37Fwy h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._37Fwy h1, ._37Fwy h2, ._37Fwy h3, ._37Fwy h4, ._37Fwy h5, ._37Fwy h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._37Fwy h1 {
  font-size: 42px;
}
._37Fwy h2 {
  font-size: 30px;
}
._37Fwy h3 {
  font-size: 24px;
}
._37Fwy h4 {
  font-size: 18px;
}
._37Fwy h5 {
  font-size: 16px;
}
._37Fwy h6 {
  font-size: 14px;
}

._2SU0s b, ._2SU0s strong {
  color: #1D1E26;
}
._2SU0s ol, ._2SU0s ul {
  padding: 0;
}
._2SU0s li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2SU0s pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2SU0s img {
  max-width: 100%;
}
._2SU0s figure {
  margin: 0;
}
._2SU0s figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2SU0s p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1Fxou b, ._1Fxou strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1Fxou i, ._1Fxou em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1Fxou a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1Fxou a:hover {
  text-decoration: none;
  color: #007799;
}
._1Fxou code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1HggL {
  font-size: 16px;
}

._1pK3z {
  font-size: 14px;
}

._2MmuA {
  font-size: 12px;
}

._2fZT_ {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2vKgu {
  background-color: #1D1E26;
}
._2vKgu .uui-caption,
._2vKgu .uui-icon {
  color: #FFFFFF;
  fill: #FFFFFF;
}

._16W7h {
  min-width: 200px;
  padding-bottom: 0;
}
._16W7h .uui-button-box {
  background-color: #1D1E26;
}
._16W7h .uui-button-box.uui-button-box, ._16W7h .uui-button-box.uui-input-box {
  min-height: 48px;
  border-width: 0;
}
._16W7h .uui-button-box .uui-caption, ._16W7h .uui-button-box .uui-input {
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-size: 16px;
  font-family: "Sans Semibold", Arial, sans-serif;
}
._16W7h .uui-button-box:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
._16W7h .uui-button-box.-clickable:hover, ._16W7h .uui-button-box.-clickable:focus {
  background-color: #303240;
}