/* Carbon scale */
/* Night scale */
._3xkAi .hero-header, ._3xkAi h1, ._3xkAi h2, ._3xkAi h3, ._3xkAi h4, ._3xkAi h5, ._3xkAi h6 {
  margin: 0;
}
._3xkAi .hero-header, ._3xkAi h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3xkAi .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3xkAi h3, ._3xkAi h4, ._3xkAi h5, ._3xkAi h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3xkAi h1, ._3xkAi h2, ._3xkAi h3, ._3xkAi h4, ._3xkAi h5, ._3xkAi h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3xkAi h1 {
  font-size: 42px;
}
._3xkAi h2 {
  font-size: 30px;
}
._3xkAi h3 {
  font-size: 24px;
}
._3xkAi h4 {
  font-size: 18px;
}
._3xkAi h5 {
  font-size: 16px;
}
._3xkAi h6 {
  font-size: 14px;
}

.tY8OY b, .tY8OY strong {
  color: #1D1E26;
}
.tY8OY ol, .tY8OY ul {
  padding: 0;
}
.tY8OY li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.tY8OY pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.tY8OY img {
  max-width: 100%;
}
.tY8OY figure {
  margin: 0;
}
.tY8OY figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.tY8OY p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.GgZdW b, .GgZdW strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.GgZdW i, .GgZdW em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.GgZdW a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.GgZdW a:hover {
  text-decoration: none;
  color: #007799;
}
.GgZdW code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3xzx8 {
  font-size: 16px;
}

._398Vo {
  font-size: 14px;
}

._3ACJN {
  font-size: 12px;
}

._1r03v {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2Vgwj {
  flex-basis: 200px;
  max-width: 500px;
  height: 36px;
  min-width: 36px;
  border-radius: 18px;
  background-color: #474A59;
  fill: #CED0DB;
  padding: 0 16px;
  margin: 0 16px;
  transition: background-color 0.3s ease-out, flex-basis 0.3s ease-in-out;
}
._2Vgwj .uui-input {
  color: #E1E3EB;
  font-family: "Sans Regular", Arial, sans-serif;
}
._2Vgwj .uui-input::placeholder {
  color: #ACAFBF;
}
._2Vgwj .uui-caption,
._2Vgwj .uui-input {
  padding: 8px 6px;
  line-height: 18px;
  font-size: 14px;
}
._2Vgwj .uui-icon svg {
  height: 19px;
  max-width: 19px;
}
._2Vgwj .uui-icon-cancel svg {
  height: 24px;
  max-width: 24px;
}
._2Vgwj .uui-icon-cancel:hover {
  cursor: pointer;
}
._2Vgwj.uui-input-box {
  min-height: 36px;
}