/* Carbon scale */
/* Night scale */
._3Vdg5 .hero-header, ._3Vdg5 h1, ._3Vdg5 h2, ._3Vdg5 h3, ._3Vdg5 h4, ._3Vdg5 h5, ._3Vdg5 h6 {
  margin: 0;
}
._3Vdg5 .hero-header, ._3Vdg5 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3Vdg5 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3Vdg5 h3, ._3Vdg5 h4, ._3Vdg5 h5, ._3Vdg5 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3Vdg5 h1, ._3Vdg5 h2, ._3Vdg5 h3, ._3Vdg5 h4, ._3Vdg5 h5, ._3Vdg5 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3Vdg5 h1 {
  font-size: 42px;
}
._3Vdg5 h2 {
  font-size: 30px;
}
._3Vdg5 h3 {
  font-size: 24px;
}
._3Vdg5 h4 {
  font-size: 18px;
}
._3Vdg5 h5 {
  font-size: 16px;
}
._3Vdg5 h6 {
  font-size: 14px;
}

._-OSz b, ._-OSz strong {
  color: #1D1E26;
}
._-OSz ol, ._-OSz ul {
  padding: 0;
}
._-OSz li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._-OSz pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._-OSz img {
  max-width: 100%;
}
._-OSz figure {
  margin: 0;
}
._-OSz figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._-OSz p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2oFTU b, ._2oFTU strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2oFTU i, ._2oFTU em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2oFTU a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2oFTU a:hover {
  text-decoration: none;
  color: #007799;
}
._2oFTU code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.x4kiD {
  font-size: 16px;
}

.OrcTZ {
  font-size: 14px;
}

._3pUxd {
  font-size: 12px;
}

.C074C {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2qMeY._1O7Yf {
  color: #FAFAFC;
}
._2qMeY._1O7Yf._33xuo b, ._2qMeY._1O7Yf._33xuo strong {
  color: #FAFAFC;
}
._2qMeY._1O7Yf pre, ._2qMeY._1O7Yf code {
  color: #1D1E26;
  background-color: #FAFAFC;
}