/* Carbon scale */
/* Night scale */
.F7JnO .hero-header, .F7JnO h1, .F7JnO h2, .F7JnO h3, .F7JnO h4, .F7JnO h5, .F7JnO h6 {
  margin: 0;
}
.F7JnO .hero-header, .F7JnO h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.F7JnO .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.F7JnO h3, .F7JnO h4, .F7JnO h5, .F7JnO h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.F7JnO h1, .F7JnO h2, .F7JnO h3, .F7JnO h4, .F7JnO h5, .F7JnO h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.F7JnO h1 {
  font-size: 42px;
}
.F7JnO h2 {
  font-size: 30px;
}
.F7JnO h3 {
  font-size: 24px;
}
.F7JnO h4 {
  font-size: 18px;
}
.F7JnO h5 {
  font-size: 16px;
}
.F7JnO h6 {
  font-size: 14px;
}

._12x3s b, ._12x3s strong {
  color: #1D1E26;
}
._12x3s ol, ._12x3s ul {
  padding: 0;
}
._12x3s li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._12x3s pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._12x3s img {
  max-width: 100%;
}
._12x3s figure {
  margin: 0;
}
._12x3s figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._12x3s p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3lgmR b, ._3lgmR strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3lgmR i, ._3lgmR em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3lgmR a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3lgmR a:hover {
  text-decoration: none;
  color: #007799;
}
._3lgmR code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3AQhE {
  font-size: 16px;
}

._2Cshs {
  font-size: 14px;
}

._37Aio {
  font-size: 12px;
}

._1J6xq {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1XJK8:hover .uui-checkbox, ._1XJK8:active .uui-checkbox, ._1XJK8:focus-within .uui-checkbox {
  outline: none;
  border-color: var(--uui-color-800);
}
._1XJK8 .uui-input-label {
  font-family: "Sans Regular", Arial, sans-serif;
  margin-left: 12px;
  flex-grow: 1;
}
._1XJK8 .uui-input-label b, ._1XJK8 .uui-input-label strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1XJK8 .uui-input-label i, ._1XJK8 .uui-input-label em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1XJK8 .uui-input-label a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1XJK8 .uui-input-label a:hover {
  text-decoration: none;
  color: #007799;
}
._1XJK8 .uui-input-label code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1XJK8 .uui-icon {
  fill: #FFFFFF;
}
._1XJK8 .uui-checkbox {
  border: 1px solid #CED0DB;
  border-radius: 3px;
}
._1XJK8 .uui-checked {
  background-color: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._1XJK8 .uui-checked:hover, ._1XJK8 .uui-checked:active, ._1XJK8 .uui-checked:focus-within {
  background-color: var(--uui-color-800);
  border-color: var(--uui-color-800);
}
._1XJK8._3yzvZ .uui-checkbox {
  width: 18px;
  height: 18px;
  margin: 0px 0;
}
._1XJK8._3yzvZ .uui-icon svg {
  width: 18px;
  height: 18px;
}
._1XJK8._3yzvZ .uui-input-label {
  font-size: 14px;
  line-height: 18px;
}
._1XJK8._2Rl_x .uui-checkbox {
  width: 12px;
  height: 12px;
  margin: 0px 0;
}
._1XJK8._2Rl_x .uui-icon svg {
  width: 12px;
  height: 12px;
}
._1XJK8._2Rl_x .uui-input-label {
  font-size: 12px;
  line-height: 12px;
}
._1XJK8._2Rl_x .uui-input-label {
  margin-left: 6px;
}
._1XJK8._38otz .uui-input-label {
  color: #474A59;
}
._1XJK8._38otz.uui-disabled {
  cursor: default;
}
._1XJK8._38otz.uui-disabled .uui-checkbox {
  cursor: default;
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #FAFAFC;
  border-color: #E1E3EB;
}
._1XJK8._38otz.uui-disabled .uui-checkbox .uui-input {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-checkbox .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-checkbox:hover, ._1XJK8._38otz.uui-disabled .uui-checkbox:active {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #FAFAFC;
  border-color: #E1E3EB;
}
._1XJK8._38otz.uui-disabled .uui-checkbox:hover .uui-input, ._1XJK8._38otz.uui-disabled .uui-checkbox:active .uui-input {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-checkbox:hover .uui-input::placeholder, ._1XJK8._38otz.uui-disabled .uui-checkbox:active .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked {
  background-color: var(--uui-color-600);
  color: var(--uui-color-600);
  fill: #FAFAFC;
  border-color: var(--uui-color-600);
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked .uui-input {
  color: var(--uui-color-600);
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:hover, ._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:active {
  background-color: var(--uui-color-600);
  color: var(--uui-color-600);
  fill: #FAFAFC;
  border-color: var(--uui-color-600);
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:hover .uui-input, ._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:active .uui-input {
  color: var(--uui-color-600);
}
._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:hover .uui-input::placeholder, ._1XJK8._38otz.uui-disabled .uui-checkbox.uui-checked:active .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-disabled .uui-input-label {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly {
  cursor: default;
}
._1XJK8._38otz.uui-readonly .uui-checkbox {
  cursor: default;
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #FFFFFF;
  border-color: #E1E3EB;
}
._1XJK8._38otz.uui-readonly .uui-checkbox .uui-input {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly .uui-checkbox .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly .uui-checkbox:hover, ._1XJK8._38otz.uui-readonly .uui-checkbox:active, ._1XJK8._38otz.uui-readonly .uui-checkbox:focus-within {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #FFFFFF;
  border-color: #E1E3EB;
}
._1XJK8._38otz.uui-readonly .uui-checkbox:hover .uui-input, ._1XJK8._38otz.uui-readonly .uui-checkbox:active .uui-input, ._1XJK8._38otz.uui-readonly .uui-checkbox:focus-within .uui-input {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly .uui-checkbox:hover .uui-input::placeholder, ._1XJK8._38otz.uui-readonly .uui-checkbox:active .uui-input::placeholder, ._1XJK8._38otz.uui-readonly .uui-checkbox:focus-within .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked {
  background-color: var(--uui-color-600);
  color: var(--uui-color-600);
  fill: #FFFFFF;
  border-color: var(--uui-color-600);
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked .uui-input {
  color: var(--uui-color-600);
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:hover, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:active, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:focus-within {
  background-color: var(--uui-color-600);
  color: var(--uui-color-600);
  fill: #FFFFFF;
  border-color: var(--uui-color-600);
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:hover .uui-input, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:active .uui-input, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:focus-within .uui-input {
  color: var(--uui-color-600);
}
._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:hover .uui-input::placeholder, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:active .uui-input::placeholder, ._1XJK8._38otz.uui-readonly .uui-checkbox.uui-checked:focus-within .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._1OvgE .uui-input-label {
  color: #FAFAFC;
}
._1XJK8._1OvgE.uui-disabled {
  cursor: default;
  background: none;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked {
  background-color: #6C6F80;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #6C6F80;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked .uui-input {
  color: #ACAFBF;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox:hover, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:active, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:focus-within, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:hover, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:active, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:focus-within {
  background-color: #6C6F80;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #6C6F80;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox:hover .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:active .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:focus-within .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:hover .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:active .uui-input, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:focus-within .uui-input {
  color: #ACAFBF;
}
._1XJK8._1OvgE.uui-disabled .uui-checkbox:hover .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:active .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox:focus-within .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:hover .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:active .uui-input::placeholder, ._1XJK8._1OvgE.uui-disabled .uui-checkbox.uui-checked:focus-within .uui-input::placeholder {
  color: #ACAFBF;
}
._1XJK8._1OvgE.uui-disabled .uui-input-label {
  color: #ACAFBF;
}
._1XJK8.uui-invalid .uui-checkbox {
  border-color: #FA4B4B;
  fill: #FA4B4B;
}
._1XJK8.uui-invalid .uui-checkbox:hover, ._1XJK8.uui-invalid .uui-checkbox:active {
  border-color: #FA4B4B;
  fill: #FA4B4B;
}
._1XJK8.uui-invalid .uui-checkbox.uui-checked {
  fill: #FFFFFF;
  background-color: #FA4B4B;
  border-color: #FA4B4B;
}
._1XJK8.uui-invalid .uui-checkbox.uui-checked:hover, ._1XJK8.uui-invalid .uui-checkbox.uui-checked:active {
  background-color: #FA4B4B;
  border-color: #FA4B4B;
}
._1XJK8.uui-invalid .uui-input-label {
  color: #FA4B4B;
}
._1XJK8.zzbFy {
  justify-content: center;
}