.RzPqv {
  line-height: 12px;
}

._38pQH {
  line-height: 18px;
}

._1Es7q {
  line-height: 24px;
}

._1XTG1 {
  line-height: 30px;
}

._3VnX2 {
  font-size: 10px;
}

._1d8FB {
  font-size: 12px;
}

.Nyn-t {
  font-size: 14px;
}

._3cRz8 {
  font-size: 16px;
}

._2LfZx {
  font-size: 18px;
}

._3nOzP {
  padding-top: 2px;
  padding-bottom: 2px;
}

.rCBIs {
  padding-top: 3px;
  padding-bottom: 3px;
}

._2mnTS {
  padding-top: 5px;
  padding-bottom: 5px;
}

._1iP98 {
  padding-top: 6px;
  padding-bottom: 6px;
}

._3QSaC {
  padding-top: 8px;
  padding-bottom: 8px;
}

._1CFCU {
  padding-top: 9px;
  padding-bottom: 9px;
}

._3zBFU {
  padding-top: 11px;
  padding-bottom: 11px;
}

._2w_D8 {
  padding-top: 12px;
  padding-bottom: 12px;
}

._2cpQ2 {
  padding-top: 14px;
  padding-bottom: 14px;
}

._3596_ {
  padding-top: 15px;
  padding-bottom: 15px;
}

.xD3lj {
  padding-top: 17px;
  padding-bottom: 17px;
}

._1GtyE {
  padding-top: 18px;
  padding-bottom: 18px;
}

._3tgYm {
  padding-top: 23px;
  padding-bottom: 23px;
}

._6bD3g {
  padding-top: 24px;
  padding-bottom: 24px;
}