._2IJJk.uui-blocker-container .uui-blocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
._2IJJk.uui-blocker-container .uui-blocker-enter {
  opacity: 0;
}
._2IJJk.uui-blocker-container .uui-blocker-enter-active {
  opacity: 1;
}
._2IJJk.uui-blocker-container .uui-blocker-exit {
  opacity: 1;
  pointer-events: none;
}
._2IJJk.uui-blocker-container .uui-blocker-exit-active {
  opacity: 0;
}