/* Carbon scale */
/* Night scale */
.vQ86E .avatars {
  padding-right: 12px;
}
.vQ86E .avatars > * {
  border-radius: 50%;
  border: 1px solid white;
}
.vQ86E .avatarsCount {
  margin-left: 3px;
  line-height: 1;
  font-size: 14px;
  color: #474A59;
  font-family: "Sans Semibold", Arial, sans-serif;
}