._3eWPS {
  display: flex;
  align-items: center;
}

._2P0N_ {
  margin: 0 8px 0 8px;
}

._3CeHP {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}
._3CeHP._3AaPN {
  width: 152px;
  height: 16px;
}
._3CeHP._3AaPN ._3c5Ih {
  width: 152px;
}
._3CeHP._1PdAs {
  width: 232px;
  height: 22px;
}
._3CeHP._1PdAs ._3c5Ih {
  width: 232px;
}

._1MhDZ._3AaPN {
  width: 115px;
}
._1MhDZ._1PdAs {
  width: 176px;
}

._3c5Ih.uui-icon {
  flex-direction: row;
}
._3c5Ih svg {
  flex-grow: 1;
  height: auto;
}

._3kcOl {
  position: absolute;
  top: 0;
  left: 0;
}
._3kcOl._3AaPN {
  width: 16px;
  height: 16px;
}
._3kcOl._1PdAs {
  width: 22px;
  height: 22px;
}
._3kcOl._1PdAs ._2dtzz svg {
  width: 22px;
  height: 22px;
}
._3kcOl._3-QBS {
  visibility: hidden;
}

.fI5G- {
  margin-left: 18px;
}

._2u1Xw {
  cursor: pointer;
  fill: #D9DBE3;
}
._2u1Xw:hover {
  fill: #C0C3CE;
}
._2u1Xw._1DsWc {
  fill: #6C7283;
}
._2u1Xw._2Plbk {
  fill: #D9DBE3;
  cursor: default;
}
._2u1Xw._3AaPN {
  width: 18px;
  height: 18px;
}
._2u1Xw._1PdAs {
  width: 24px;
  height: 24px;
}
._2u1Xw._1PdAs svg {
  width: 24px;
  height: 24px;
}

._2hisA {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

._2q5NU {
  position: fixed;
  width: 1px;
}

._2DRsF {
  pointer-events: none;
}