._2xntt {
  position: relative;
}
._2xntt.Q3UKo ._1FjF1 .uui-datepickerheader-nav-icon-right {
  visibility: hidden;
}
._2xntt.Q3UKo ._27B-p .uui-datepickerheader-nav-icon-left {
  visibility: hidden;
}
._2xntt ._1XYVP {
  position: relative;
}
._2xntt ._2J3c0 {
  transition: height 0.3s ease-in-out;
}
._2xntt ._2jnda {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.uui-datepicker-container {
  transition: height 0.3s ease-in-out;
}