/* Carbon scale */
/* Night scale */
.QziAm .hero-header, .QziAm h1, .QziAm h2, .QziAm h3, .QziAm h4, .QziAm h5, .QziAm h6 {
  margin: 0;
}
.QziAm .hero-header, .QziAm h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.QziAm .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.QziAm h3, .QziAm h4, .QziAm h5, .QziAm h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.QziAm h1, .QziAm h2, .QziAm h3, .QziAm h4, .QziAm h5, .QziAm h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.QziAm h1 {
  font-size: 42px;
}
.QziAm h2 {
  font-size: 30px;
}
.QziAm h3 {
  font-size: 24px;
}
.QziAm h4 {
  font-size: 18px;
}
.QziAm h5 {
  font-size: 16px;
}
.QziAm h6 {
  font-size: 14px;
}

.mLJ4k b, .mLJ4k strong {
  color: #1D1E26;
}
.mLJ4k ol, .mLJ4k ul {
  padding: 0;
}
.mLJ4k li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.mLJ4k pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.mLJ4k img {
  max-width: 100%;
}
.mLJ4k figure {
  margin: 0;
}
.mLJ4k figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.mLJ4k p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._22fcg b, ._22fcg strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._22fcg i, ._22fcg em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._22fcg a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._22fcg a:hover {
  text-decoration: none;
  color: #007799;
}
._22fcg code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1DapY {
  font-size: 16px;
}

._2Nw5b {
  font-size: 14px;
}

._2wJjQ {
  font-size: 12px;
}

.iB2zO {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

@keyframes _2S-2A {
  0% {
    background-position: 300px 0;
  }
  100% {
    background-position: 0 0;
  }
}
._3Y1MH {
  display: flex;
  flex-wrap: wrap;
}

._2-j0y {
  user-select: none;
  font-family: "Redacted", Arial, sans-serif;
  letter-spacing: -1px;
  margin: 0 3px 3px 0;
  color: var(--uui-color-700);
  vertical-align: 1px;
}
._2-j0y:last-child {
  margin-right: 0;
}
@supports not (-moz-animation: none) {
  ._2-j0y._2OOWm {
    color: transparent;
    background: linear-gradient(to right, var(--uui-color-700) 0%, var(--uui-color-800) 50%, var(--uui-color-700) 100%) repeat;
    background-size: 300px 10px;
    background-clip: text;
    background-attachment: fixed;
    -webkit-background-clip: text;
    animation: _2S-2A 2s cubic-bezier(0.49, 0.07, 0.49, 0.87) infinite;
  }
}