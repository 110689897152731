/* Carbon scale */
/* Night scale */
.BI6m2 .hero-header, .BI6m2 h1, .BI6m2 h2, .BI6m2 h3, .BI6m2 h4, .BI6m2 h5, .BI6m2 h6 {
  margin: 0;
}
.BI6m2 .hero-header, .BI6m2 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.BI6m2 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.BI6m2 h3, .BI6m2 h4, .BI6m2 h5, .BI6m2 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.BI6m2 h1, .BI6m2 h2, .BI6m2 h3, .BI6m2 h4, .BI6m2 h5, .BI6m2 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.BI6m2 h1 {
  font-size: 42px;
}
.BI6m2 h2 {
  font-size: 30px;
}
.BI6m2 h3 {
  font-size: 24px;
}
.BI6m2 h4 {
  font-size: 18px;
}
.BI6m2 h5 {
  font-size: 16px;
}
.BI6m2 h6 {
  font-size: 14px;
}

._2nVfN b, ._2nVfN strong {
  color: #1D1E26;
}
._2nVfN ol, ._2nVfN ul {
  padding: 0;
}
._2nVfN li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2nVfN pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2nVfN img {
  max-width: 100%;
}
._2nVfN figure {
  margin: 0;
}
._2nVfN figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2nVfN p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2-sqG b, ._2-sqG strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2-sqG i, ._2-sqG em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2-sqG a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2-sqG a:hover {
  text-decoration: none;
  color: #007799;
}
._2-sqG code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2da1m {
  font-size: 16px;
}

.f3F-o {
  font-size: 14px;
}

._3f_9P {
  font-size: 12px;
}

._19mmX {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.ZqT79 {
  min-width: 48px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 60px;
}
.ZqT79 .uui-caption, .ZqT79 .uui-input {
  padding-left: 8px;
  padding-right: 8px;
}
.ZqT79 .uui-caption, .ZqT79 .uui-input {
  padding-top: 21px;
  padding-bottom: 21px;
  min-width: 60px;
  line-height: 18px;
  font-size: 18px;
}

.BDyQK {
  margin: 0 8px;
}

._2XneA {
  cursor: pointer;
}
._2XneA:hover {
  background: #1D1E26;
}
._2XneA:active {
  background-color: #070709;
}

._2OZQ6 {
  background-color: #1D1E26;
}

._2H-Ji .uui-icon svg {
  height: 24px;
  max-width: 24px;
}