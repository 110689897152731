@font-face {
  font-family: "Oswald Regular";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald Bold";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald Light";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Regular";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Light";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Light.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Light.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Light.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Bold";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Bold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Semibold";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Semibold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Semibold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Italic";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-It.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-It.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-It.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2") format("woff2");
}
@font-face {
  font-family: "Redacted";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.eot");
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.svg#filename") format("svg");
  font-display: block;
}