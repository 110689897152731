._2HSWI {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
}
._2HSWI:focus-within {
  outline: none;
}
._2HSWI .uui-radioinput {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
._2HSWI .uui-radioinput input[type=radio] {
  margin: 0;
  border: none;
  width: 0;
  height: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
._2HSWI .uui-radioinput input[type=radio]:focus {
  outline: none;
}
._2HSWI .di9_X {
  position: absolute;
  left: 0;
  top: 0;
}