/* Carbon scale */
/* Night scale */
._3WQih .hero-header, ._3WQih h1, ._3WQih h2, ._3WQih h3, ._3WQih h4, ._3WQih h5, ._3WQih h6 {
  margin: 0;
}
._3WQih .hero-header, ._3WQih h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3WQih .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3WQih h3, ._3WQih h4, ._3WQih h5, ._3WQih h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3WQih h1, ._3WQih h2, ._3WQih h3, ._3WQih h4, ._3WQih h5, ._3WQih h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3WQih h1 {
  font-size: 42px;
}
._3WQih h2 {
  font-size: 30px;
}
._3WQih h3 {
  font-size: 24px;
}
._3WQih h4 {
  font-size: 18px;
}
._3WQih h5 {
  font-size: 16px;
}
._3WQih h6 {
  font-size: 14px;
}

._2fxBs b, ._2fxBs strong {
  color: #1D1E26;
}
._2fxBs ol, ._2fxBs ul {
  padding: 0;
}
._2fxBs li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2fxBs pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2fxBs img {
  max-width: 100%;
}
._2fxBs figure {
  margin: 0;
}
._2fxBs figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2fxBs p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3kJHD b, ._3kJHD strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3kJHD i, ._3kJHD em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3kJHD a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3kJHD a:hover {
  text-decoration: none;
  color: #007799;
}
._3kJHD code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1m0Mi {
  font-size: 16px;
}

._19MdR {
  font-size: 14px;
}

._2qv3- {
  font-size: 12px;
}

._1MOYP {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3YLcV {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-self: stretch;
  width: 100%;
}

._3T52Y.uui-input-box {
  border: 0;
  box-shadow: none;
}
._3T52Y.uui-input-box.uui-focus {
  border-color: transparent;
  box-shadow: none;
}

._3RziO {
  padding: 6px 0;
}

._2lza9 {
  border-bottom: 1px solid #CED0DB;
  background: white;
  padding: 0 4px;
}

.TjCrs {
  padding: 0 7px;
}

._3vEED {
  padding: 0 6px;
}

._3ws4h {
  padding-top: 20px;
  padding-bottom: 20px;
}

._1Gt8R, ._2VHpq, ._3UVZc {
  padding-top: 23px;
  padding-bottom: 23px;
}