/* Carbon scale */
/* Night scale */
._3u4zw .hero-header, ._3u4zw h1, ._3u4zw h2, ._3u4zw h3, ._3u4zw h4, ._3u4zw h5, ._3u4zw h6 {
  margin: 0;
}
._3u4zw .hero-header, ._3u4zw h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3u4zw .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3u4zw h3, ._3u4zw h4, ._3u4zw h5, ._3u4zw h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3u4zw h1, ._3u4zw h2, ._3u4zw h3, ._3u4zw h4, ._3u4zw h5, ._3u4zw h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3u4zw h1 {
  font-size: 42px;
}
._3u4zw h2 {
  font-size: 30px;
}
._3u4zw h3 {
  font-size: 24px;
}
._3u4zw h4 {
  font-size: 18px;
}
._3u4zw h5 {
  font-size: 16px;
}
._3u4zw h6 {
  font-size: 14px;
}

._3s1oE b, ._3s1oE strong {
  color: #1D1E26;
}
._3s1oE ol, ._3s1oE ul {
  padding: 0;
}
._3s1oE li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3s1oE pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3s1oE img {
  max-width: 100%;
}
._3s1oE figure {
  margin: 0;
}
._3s1oE figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3s1oE p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1pqth b, ._1pqth strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1pqth i, ._1pqth em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1pqth a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1pqth a:hover {
  text-decoration: none;
  color: #007799;
}
._1pqth code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3DqyO {
  font-size: 16px;
}

._1Zk4D {
  font-size: 14px;
}

._2pXRq {
  font-size: 12px;
}

._20Tue {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2plA_ {
  align-items: normal;
}

._1Btyk {
  display: flex;
  padding: 10px 30px;
  justify-content: space-between;
  border-top: 1px solid #CED0DB;
  align-items: center;
  background-color: #FFFFFF;
  position: relative;
}

._3UCF4 {
  display: flex;
  justify-content: flex-end;
}

._2ZN2H {
  color: #CED0DB;
  font-size: 15px;
  font-family: "Sans Regular", Arial, sans-serif;
}

._28ntC {
  min-width: 0;
  border-radius: 3px;
  padding: 0 5px;
}
._28ntC.uui-focus {
  border: 1px solid #009ECC;
}
._28ntC.oOR5M {
  min-width: 22px;
  padding-left: 5px;
  padding-right: 5px;
}
._28ntC.oOR5M .uui-caption, ._28ntC.oOR5M .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._28ntC.oOR5M.uui-button-box, ._28ntC.oOR5M.uui-input-box {
  min-height: 22px;
  border-width: 0;
}
._28ntC._2VB1h {
  min-width: 28px;
  padding-left: 5px;
  padding-right: 5px;
}
._28ntC._2VB1h .uui-caption, ._28ntC._2VB1h .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._28ntC._2VB1h.uui-button-box, ._28ntC._2VB1h.uui-input-box {
  min-height: 28px;
  border-width: 0;
}
._28ntC._2depx {
  min-width: 34px;
  padding-left: 5px;
  padding-right: 5px;
}
._28ntC._2depx .uui-caption, ._28ntC._2depx .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._28ntC._2depx.uui-button-box, ._28ntC._2depx.uui-input-box {
  min-height: 34px;
  border-width: 0;
}
._28ntC._12VY5 {
  min-width: 40px;
  padding-left: 5px;
  padding-right: 5px;
}
._28ntC._12VY5 .uui-caption, ._28ntC._12VY5 .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._28ntC._12VY5.uui-button-box, ._28ntC._12VY5.uui-input-box {
  min-height: 40px;
  border-width: 0;
}
._28ntC._39zHo {
  min-width: 46px;
  padding-left: 5px;
  padding-right: 5px;
}
._28ntC._39zHo .uui-caption, ._28ntC._39zHo .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._28ntC._39zHo.uui-button-box, ._28ntC._39zHo.uui-input-box {
  min-height: 46px;
  border-width: 0;
}

._1TEgB {
  display: flex;
  flex-shrink: 0;
  margin: -1px 1px;
  background: #CED0DB;
  width: 1px;
}

.Nz4kR {
  display: flex;
  min-width: 0;
  background-color: #FFFFFF;
  border: 1px solid #CED0DB;
  border-radius: 3px;
}
.Nz4kR.uui-readonly.dBdd6 {
  background: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
.Nz4kR.uui-readonly._3kMQw {
  background: transparent;
  pointer-events: none;
}
.Nz4kR.uui-readonly.dBdd6:hover {
  border-color: #E1E3EB;
}
.Nz4kR.uui-readonly._5Bm4S:hover {
  border-color: transparent;
}
.Nz4kR.uui-readonly._3kMQw:hover {
  border-color: transparent;
}
.Nz4kR.uui-readonly ._28ntC.uui-focus {
  box-shadow: none;
}
.Nz4kR.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
}
.Nz4kR.uui-disabled .uui-input {
  color: #ACAFBF;
}
.Nz4kR.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
.Nz4kR.uui-disabled:hover, .Nz4kR.uui-disabled:active, .Nz4kR.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
.Nz4kR.uui-disabled:hover .uui-input, .Nz4kR.uui-disabled:active .uui-input, .Nz4kR.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
.Nz4kR.uui-disabled:hover .uui-input::placeholder, .Nz4kR.uui-disabled:active .uui-input::placeholder, .Nz4kR.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
.Nz4kR.uui-disabled::placeholder {
  color: #E1E3EB;
}
.Nz4kR.uui-invalid {
  border-color: #FA4B4B;
}

._2EC29 {
  background: none;
  border: 0;
}
._2EC29 ._28ntC.uui-input-box {
  border-width: 1px;
}
._2EC29 ._28ntC.uui-readonly {
  background: none;
}
._2EC29 ._28ntC.uui-readonly.uui-focus, ._2EC29 ._28ntC.uui-readonly:hover {
  border-color: transparent;
  box-shadow: none;
}
._2EC29.uui-disabled {
  background: none;
}

._3Owic {
  background: none;
  border: 0;
  border-radius: 0;
}
._3Owic ._28ntC.uui-input-box {
  border-width: 1px;
}
._3Owic ._28ntC.uui-readonly {
  background: none;
}
._3Owic ._28ntC.uui-readonly.uui-focus, ._3Owic ._28ntC.uui-readonly:hover {
  border-color: transparent;
  box-shadow: none;
}
._3Owic.uui-disabled {
  background: none;
}