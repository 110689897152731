/* Carbon scale */
/* Night scale */
._1vy5d .hero-header, ._1vy5d h1, ._1vy5d h2, ._1vy5d h3, ._1vy5d h4, ._1vy5d h5, ._1vy5d h6 {
  margin: 0;
}
._1vy5d .hero-header, ._1vy5d h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1vy5d .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1vy5d h3, ._1vy5d h4, ._1vy5d h5, ._1vy5d h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1vy5d h1, ._1vy5d h2, ._1vy5d h3, ._1vy5d h4, ._1vy5d h5, ._1vy5d h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1vy5d h1 {
  font-size: 42px;
}
._1vy5d h2 {
  font-size: 30px;
}
._1vy5d h3 {
  font-size: 24px;
}
._1vy5d h4 {
  font-size: 18px;
}
._1vy5d h5 {
  font-size: 16px;
}
._1vy5d h6 {
  font-size: 14px;
}

.F0XLz b, .F0XLz strong {
  color: #1D1E26;
}
.F0XLz ol, .F0XLz ul {
  padding: 0;
}
.F0XLz li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.F0XLz pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.F0XLz img {
  max-width: 100%;
}
.F0XLz figure {
  margin: 0;
}
.F0XLz figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.F0XLz p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.DzJ3b b, .DzJ3b strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.DzJ3b i, .DzJ3b em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.DzJ3b a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.DzJ3b a:hover {
  text-decoration: none;
  color: #007799;
}
.DzJ3b code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.DSB71 {
  font-size: 16px;
}

._2TNda {
  font-size: 14px;
}

.xFL3b {
  font-size: 12px;
}

._1SSRd {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2bbhf.uui-dropdown-body {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-sizing: border-box;
  box-shadow: 0 8px 36px rgba(29, 30, 38, 0.15);
  border: 0;
  overflow: hidden;
  border-radius: 6px;
}
._2bbhf.cnlzW {
  background-color: white;
}
._2bbhf._3s1c2 {
  background-color: #474A59;
}
._2bbhf .uui-popover-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
._2bbhf .uui-popover-arrow::after {
  content: "";
  position: absolute;
  background: #FFFFFF;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
._2bbhf._1kUnT {
  padding-left: 6px;
  padding-right: 6px;
}
._2bbhf.-AKUe {
  padding-left: 12px;
  padding-right: 12px;
}
._2bbhf._23O5F {
  padding-left: 18px;
  padding-right: 18px;
}
._2bbhf._3UU47 {
  padding-left: 24px;
  padding-right: 24px;
}
._2bbhf._3DAhA {
  padding-left: 30px;
  padding-right: 30px;
}
._2bbhf._1brdc {
  padding-top: 3px;
  padding-bottom: 3px;
}
._2bbhf.gqO-D {
  padding-top: 6px;
  padding-bottom: 6px;
}
._2bbhf.JbvKH {
  padding-top: 9px;
  padding-bottom: 9px;
}
._2bbhf._1aZ9W {
  padding-top: 12px;
  padding-bottom: 12px;
}
._2bbhf._1KzAb {
  padding-top: 15px;
  padding-bottom: 15px;
}
._2bbhf._131HA {
  padding-top: 24px;
  padding-bottom: 24px;
}