/* Carbon scale */
/* Night scale */
._1hAV9 .hero-header, ._1hAV9 h1, ._1hAV9 h2, ._1hAV9 h3, ._1hAV9 h4, ._1hAV9 h5, ._1hAV9 h6 {
  margin: 0;
}
._1hAV9 .hero-header, ._1hAV9 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1hAV9 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1hAV9 h3, ._1hAV9 h4, ._1hAV9 h5, ._1hAV9 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1hAV9 h1, ._1hAV9 h2, ._1hAV9 h3, ._1hAV9 h4, ._1hAV9 h5, ._1hAV9 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1hAV9 h1 {
  font-size: 42px;
}
._1hAV9 h2 {
  font-size: 30px;
}
._1hAV9 h3 {
  font-size: 24px;
}
._1hAV9 h4 {
  font-size: 18px;
}
._1hAV9 h5 {
  font-size: 16px;
}
._1hAV9 h6 {
  font-size: 14px;
}

._1GFsA b, ._1GFsA strong {
  color: #1D1E26;
}
._1GFsA ol, ._1GFsA ul {
  padding: 0;
}
._1GFsA li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1GFsA pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1GFsA img {
  max-width: 100%;
}
._1GFsA figure {
  margin: 0;
}
._1GFsA figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1GFsA p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3hlXr b, ._3hlXr strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3hlXr i, ._3hlXr em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3hlXr a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3hlXr a:hover {
  text-decoration: none;
  color: #007799;
}
._3hlXr code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1VWOi {
  font-size: 16px;
}

._3w4Ut {
  font-size: 14px;
}

._3HYln {
  font-size: 12px;
}

._3dBqC {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1baEn.LCOh3 {
  font-family: "Oswald Regular", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}
._1baEn._1IyAp {
  font-family: "Sans Regular", Arial, sans-serif;
  font-weight: normal;
}
._1baEn._1T5fl {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
._1baEn._1iOVW {
  font-family: "Sans Light", Arial, sans-serif;
  font-weight: normal;
}
._1baEn._3rkUj {
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-weight: normal;
}
._1baEn._2WJni {
  min-width: auto;
}
._1baEn._2WJni.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._2WJni.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._2WJni.uui-button-box {
  min-height: 18px;
  border-width: 0;
}
._1baEn._2Vozg {
  min-width: auto;
}
._1baEn._2Vozg.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._2Vozg.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._2Vozg.uui-button-box {
  min-height: 24px;
  border-width: 0;
}
._1baEn._2O_yS {
  min-width: auto;
}
._1baEn._2O_yS.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._2O_yS.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._2O_yS.uui-button-box {
  min-height: 30px;
  border-width: 0;
}
._1baEn._2V-NX {
  min-width: auto;
}
._1baEn._2V-NX.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._2V-NX.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._2V-NX.uui-button-box {
  min-height: 36px;
  border-width: 0;
}
._1baEn._1-o_j {
  min-width: auto;
}
._1baEn._1-o_j.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._1-o_j.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._1-o_j.uui-button-box {
  min-height: 42px;
  border-width: 0;
}
._1baEn._3xOpL {
  min-width: auto;
}
._1baEn._3xOpL.uui-has-left-icon .uui-caption {
  padding-left: 6px;
}
._1baEn._3xOpL.uui-has-right-icon .uui-caption {
  padding-right: 6px;
}
._1baEn._3xOpL.uui-button-box {
  min-height: 48px;
  border-width: 0;
}
._1baEn.uui-button-box {
  background-color: transparent;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: transparent;
}
._1baEn.uui-button-box.-clickable:hover, ._1baEn.uui-button-box.-clickable:focus {
  background-color: transparent;
  color: var(--uui-color-800);
  fill: var(--uui-color-800);
  border-color: transparent;
}
._1baEn.uui-button-box.-clickable:active {
  background-color: transparent;
  color: var(--uui-color-900);
  fill: var(--uui-color-900);
  border-color: transparent;
}
._1baEn.uui-button-box.-clickable:focus {
  box-shadow: none;
}
._1baEn.uui-button-box.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  background-color: transparent;
}
._1baEn.uui-button-box.uui-disabled .uui-input {
  color: #ACAFBF;
}
._1baEn.uui-button-box.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
._1baEn.uui-button-box.uui-disabled:hover, ._1baEn.uui-button-box.uui-disabled:active, ._1baEn.uui-button-box.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._1baEn.uui-button-box.uui-disabled:hover .uui-input, ._1baEn.uui-button-box.uui-disabled:active .uui-input, ._1baEn.uui-button-box.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
._1baEn.uui-button-box.uui-disabled:hover .uui-input::placeholder, ._1baEn.uui-button-box.uui-disabled:active .uui-input::placeholder, ._1baEn.uui-button-box.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
._1baEn.uui-button-box.uui-disabled::placeholder {
  color: #E1E3EB;
}
._1baEn.uui-button-box.uui-disabled:hover {
  background-color: transparent;
}