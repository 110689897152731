._3Ku8A.uui-blocker-container .uui-blocker {
  background-color: rgba(255, 255, 255, 0.9);
}
._3Ku8A.uui-blocker-container .uui-blocker-enter {
  background-color: rgba(255, 255, 255, 0);
}
._3Ku8A.uui-blocker-container .uui-blocker-enter-active {
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 1000ms ease-out, background-color 2000ms ease-out;
}
._3Ku8A.uui-blocker-container .uui-blocker-exit {
  background-color: rgba(255, 255, 255, 0.9);
  pointer-events: none;
  transition: opacity 1000ms ease-out, background-color 200ms ease-out;
}
._3Ku8A.uui-blocker-container .uui-blocker-exit-active {
  background-color: rgba(255, 255, 255, 0);
}