._1xAtA {
  position: relative;
  overflow: hidden;
}

._2e1v4 {
  height: 100%;
  text-align: center;
  transition: width 0.3s cubic-bezier(0.1, 0.4, 0.3, 1) 0s;
}

._2mlFt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.1, 0.4, 0.3, 1) 0s;
}