._1Yn5X {
  width: 100%;
}
._1Yn5X.uui-input-box {
  display: flex;
  outline: none;
}
._1Yn5X .uui-input {
  flex: 1 1 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
}
._1Yn5X .uui-input::-ms-clear {
  display: none;
}
._1Yn5X .uui-input[type=search]::-webkit-search-decoration, ._1Yn5X .uui-input[type=search]::-webkit-search-cancel-button, ._1Yn5X .uui-input[type=search]::-webkit-search-results-button, ._1Yn5X .uui-input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

._3mB8Q {
  visibility: hidden;
}

._2xW7r {
  cursor: pointer;
}