._2f5T7:focus {
  outline: none;
}
._2f5T7 .uui-rating-empty-star-container {
  display: flex;
}
._2f5T7 .uui-rating-filled-star-container {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
._2f5T7 .uui-rating-star {
  flex-shrink: 0;
}