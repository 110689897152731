/* Carbon scale */
/* Night scale */
._2Zmlf .hero-header, ._2Zmlf h1, ._2Zmlf h2, ._2Zmlf h3, ._2Zmlf h4, ._2Zmlf h5, ._2Zmlf h6 {
  margin: 0;
}
._2Zmlf .hero-header, ._2Zmlf h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2Zmlf .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2Zmlf h3, ._2Zmlf h4, ._2Zmlf h5, ._2Zmlf h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2Zmlf h1, ._2Zmlf h2, ._2Zmlf h3, ._2Zmlf h4, ._2Zmlf h5, ._2Zmlf h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2Zmlf h1 {
  font-size: 42px;
}
._2Zmlf h2 {
  font-size: 30px;
}
._2Zmlf h3 {
  font-size: 24px;
}
._2Zmlf h4 {
  font-size: 18px;
}
._2Zmlf h5 {
  font-size: 16px;
}
._2Zmlf h6 {
  font-size: 14px;
}

.q471K b, .q471K strong {
  color: #1D1E26;
}
.q471K ol, .q471K ul {
  padding: 0;
}
.q471K li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.q471K pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.q471K img {
  max-width: 100%;
}
.q471K figure {
  margin: 0;
}
.q471K figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.q471K p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3B0nb b, ._3B0nb strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3B0nb i, ._3B0nb em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3B0nb a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3B0nb a:hover {
  text-decoration: none;
  color: #007799;
}
._3B0nb code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.Pj_7l {
  font-size: 16px;
}

._1oenW {
  font-size: 14px;
}

._1MmHp {
  font-size: 12px;
}

._3Chtq {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3t0n_ {
  border-top: 6px solid var(--uui-color-700);
  padding: 6px 18px;
  width: 360px;
  background: #FFFFFF;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 720px) {
  ._3t0n_ {
    width: 100%;
  }
}

._39saB {
  padding: 6px 0;
}
._39saB .uui-icon {
  fill: var(--uui-color-700);
}

._1CBn5 {
  display: flex;
}

._1yOlo:not(:last-child) {
  margin-right: 12px;
}

._2PfPk {
  padding: 6px 0;
}

.l6RwC {
  width: 100%;
  display: flex;
}

._3FGBm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
}

._3haMj {
  display: flex;
  align-items: flex-start;
}

._1wyXN {
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 18px;
}

._3t8po ._39saB .uui-icon {
  fill: #FCAA00;
}

._8m6EN ._39saB .uui-icon {
  fill: #009ECC;
}

._1ed72 ._39saB .uui-icon {
  fill: #FA4B4B;
}

._2B1eI ._39saB .uui-icon {
  fill: #67A300;
}