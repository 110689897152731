/* Carbon scale */
/* Night scale */
._3i8Q1 .hero-header, ._3i8Q1 h1, ._3i8Q1 h2, ._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  margin: 0;
}
._3i8Q1 .hero-header, ._3i8Q1 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3i8Q1 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3i8Q1 h1, ._3i8Q1 h2, ._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3i8Q1 h1 {
  font-size: 42px;
}
._3i8Q1 h2 {
  font-size: 30px;
}
._3i8Q1 h3 {
  font-size: 24px;
}
._3i8Q1 h4 {
  font-size: 18px;
}
._3i8Q1 h5 {
  font-size: 16px;
}
._3i8Q1 h6 {
  font-size: 14px;
}

._1clPn b, ._1clPn strong {
  color: #1D1E26;
}
._1clPn ol, ._1clPn ul {
  padding: 0;
}
._1clPn li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1clPn pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1clPn img {
  max-width: 100%;
}
._1clPn figure {
  margin: 0;
}
._1clPn figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1clPn p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2w7qs b, ._2w7qs strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2w7qs i, ._2w7qs em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2w7qs a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2w7qs a:hover {
  text-decoration: none;
  color: #007799;
}
._2w7qs code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3wG_1 {
  font-size: 16px;
}

.Yk5iO {
  font-size: 14px;
}

._3y1Zh {
  font-size: 12px;
}

._3JMcO {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

/* Carbon scale */
/* Night scale */
._3i8Q1 .hero-header, ._3i8Q1 h1, ._3i8Q1 h2, ._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  margin: 0;
}

._3i8Q1 .hero-header, ._3i8Q1 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}

._3i8Q1 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}

._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}

._3i8Q1 h1, ._3i8Q1 h2, ._3i8Q1 h3, ._3i8Q1 h4, ._3i8Q1 h5, ._3i8Q1 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}

._3i8Q1 h1 {
  font-size: 42px;
}

._3i8Q1 h2 {
  font-size: 30px;
}

._3i8Q1 h3 {
  font-size: 24px;
}

._3i8Q1 h4 {
  font-size: 18px;
}

._3i8Q1 h5 {
  font-size: 16px;
}

._3i8Q1 h6 {
  font-size: 14px;
}

._1clPn b, ._1clPn strong {
  color: #1D1E26;
}

._1clPn ol, ._1clPn ul {
  padding: 0;
}

._1clPn li {
  margin-left: 1.25em;
  line-height: 1.5;
}

._1clPn pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1clPn img {
  max-width: 100%;
}

._1clPn figure {
  margin: 0;
}

._1clPn figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}

._1clPn p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2w7qs b, ._2w7qs strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}

._2w7qs i, ._2w7qs em {
  font-family: "Sans Italic", Arial, sans-serif;
}

._2w7qs a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}

._2w7qs a:hover {
  text-decoration: none;
  color: #007799;
}

._2w7qs code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3wG_1 {
  font-size: 16px;
}

.Yk5iO {
  font-size: 14px;
}

._3y1Zh {
  font-size: 12px;
}

._3JMcO {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3l8PV {
  color: #303240;
  fill: #6C6F80;
  box-sizing: border-box;
}
._3l8PV .uui-input-prefix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-left: -5px;
  padding: 6px;
  border-right: 1px solid #CED0DB;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
._3l8PV .uui-input-suffix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-right: -5px;
  padding: 6px;
  border-left: 1px solid #CED0DB;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
._3l8PV .uui-input {
  color: #303240;
  font-family: "Sans Regular", Arial, sans-serif;
}
._3l8PV .uui-input::placeholder {
  color: #ACAFBF;
}
._3l8PV .uui-input:placeholder-shown {
  text-overflow: ellipsis;
}
._3l8PV .uui-input::-moz-placeholder {
  opacity: 1;
}
._3l8PV._1BIBJ {
  background-color: #FFFFFF;
  border-color: #CED0DB;
  border-style: solid;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
._3l8PV._1BIBJ:hover {
  border-color: #6C6F80;
}
._3l8PV._1BIBJ.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #007799;
}
._3l8PV._1BIBJ .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._3l8PV._1BIBJ._1NsLw {
  min-width: 24px;
}
._3l8PV._1BIBJ.h_fOn {
  min-width: 30px;
}
._3l8PV._1BIBJ._3khNZ {
  min-width: 36px;
}
._3l8PV._1BIBJ._3bcjY {
  min-width: 42px;
}
._3l8PV._1BIBJ._26N77 {
  min-width: 48px;
}
._3l8PV._1BIBJ._2bBY2 {
  min-width: 60px;
}
._3l8PV._3GCFQ {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 3px;
}
._3l8PV._3GCFQ:hover {
  border-color: #CED0DB;
}
._3l8PV._3GCFQ.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #009ECC;
}
._3l8PV._3GCFQ .uui-input-prefix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none;
  margin-left: 0;
}
._3l8PV._3GCFQ .uui-input-suffix {
  border-left: none;
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
._3l8PV._3GCFQ .uui-input {
  padding-left: 5px;
  padding-right: 5px;
}
._3l8PV._3Tm-c {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 0;
  padding: 0 6px;
}
._3l8PV._3Tm-c:hover .uui-icon.-clickable.uui-enabled, ._3l8PV._3Tm-c:hover .uui-icon-dropdown.uui-enabled {
  fill: #6C6F80;
}
._3l8PV._3Tm-c:hover .uui-icon.-clickable.uui-enabled:hover, ._3l8PV._3Tm-c:hover .uui-icon-dropdown.uui-enabled:hover {
  fill: #474A59;
}
._3l8PV._3Tm-c:hover .uui-icon.-clickable.uui-disabled, ._3l8PV._3Tm-c:hover .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
._3l8PV._3Tm-c .uui-icon.-clickable, ._3l8PV._3Tm-c .uui-icon-dropdown {
  cursor: pointer;
}
._3l8PV._3Tm-c .uui-icon.-clickable.uui-enabled, ._3l8PV._3Tm-c .uui-icon-dropdown.uui-enabled {
  fill: transparent;
}
._3l8PV._3Tm-c .uui-icon.-clickable.uui-disabled, ._3l8PV._3Tm-c .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
._3l8PV._3Tm-c .uui-input-prefix {
  border-right: none;
}
._3l8PV._3Tm-c .uui-input-suffix {
  border-left: none;
}
._3l8PV._3Tm-c.uui-focus .uui-input-prefix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 2px 0 0);
}
._3l8PV._3Tm-c.uui-focus .uui-input-suffix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 0 0 2px);
}
._3l8PV._3Tm-c .uui-input {
  padding-left: 5px;
  padding-right: 5px;
}
._3l8PV._3Tm-c .uui-icon:first-child {
  padding-left: 5px;
}
._3l8PV.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  fill: #ACAFBF;
}
._3l8PV.uui-disabled .uui-input {
  color: #ACAFBF;
}
._3l8PV.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
._3l8PV.uui-disabled:hover, ._3l8PV.uui-disabled:active, ._3l8PV.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._3l8PV.uui-disabled:hover .uui-input, ._3l8PV.uui-disabled:active .uui-input, ._3l8PV.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
._3l8PV.uui-disabled:hover .uui-input::placeholder, ._3l8PV.uui-disabled:active .uui-input::placeholder, ._3l8PV.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
._3l8PV.uui-disabled::placeholder {
  color: #E1E3EB;
}
._3l8PV.uui-disabled .uui-input::placeholder {
  color: #E1E3EB;
}
._3l8PV.uui-disabled._3GCFQ, ._3l8PV.uui-disabled._3Tm-c {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: transparent;
}
._3l8PV.uui-disabled._3GCFQ .uui-input, ._3l8PV.uui-disabled._3Tm-c .uui-input {
  color: #ACAFBF;
}
._3l8PV.uui-disabled._3GCFQ .uui-input::placeholder, ._3l8PV.uui-disabled._3Tm-c .uui-input::placeholder {
  color: #ACAFBF;
}
._3l8PV.uui-readonly._1BIBJ {
  background: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
._3l8PV.uui-readonly._3Tm-c {
  background: transparent;
  pointer-events: none;
}
._3l8PV.uui-readonly._1BIBJ:hover {
  border-color: #E1E3EB;
}
._3l8PV.uui-readonly._3GCFQ:hover {
  border-color: transparent;
}
._3l8PV.uui-readonly._3Tm-c:hover {
  border-color: transparent;
}
._3l8PV.uui-invalid._1BIBJ {
  border-color: #FA4B4B;
}
._3l8PV._1NsLw.uui-button-box, ._3l8PV._1NsLw.uui-input-box {
  min-height: 24px;
  border-width: 1px;
}
._3l8PV.h_fOn.uui-button-box, ._3l8PV.h_fOn.uui-input-box {
  min-height: 30px;
  border-width: 1px;
}
._3l8PV._3khNZ.uui-button-box, ._3l8PV._3khNZ.uui-input-box {
  min-height: 36px;
  border-width: 1px;
}
._3l8PV._3bcjY.uui-button-box, ._3l8PV._3bcjY.uui-input-box {
  min-height: 42px;
  border-width: 1px;
}
._3l8PV._26N77.uui-button-box, ._3l8PV._26N77.uui-input-box {
  min-height: 48px;
  border-width: 1px;
}
._3l8PV._2bBY2.uui-button-box, ._3l8PV._2bBY2.uui-input-box {
  min-height: 60px;
  border-width: 1px;
}
._3l8PV .uui-icon.-clickable {
  cursor: pointer;
}
._3l8PV .uui-icon.-clickable.uui-enabled {
  fill: #6C6F80;
}
._3l8PV .uui-icon.-clickable.uui-enabled:hover {
  fill: #474A59;
}
._3l8PV .uui-icon.-clickable.uui-disabled {
  fill: #ACAFBF;
  cursor: default;
}
._3l8PV .uui-icon-accept.-clickable.uui-enabled {
  fill: var(--uui-color-700);
}
._3l8PV .uui-icon-accept.-clickable.uui-enabled:hover {
  fill: var(--uui-color-800);
}

._3l8PV.uui-focus:not(._3Tm-c) {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #009ECC;
}
._3l8PV.uui-input-box {
  padding-right: 0;
  min-width: 40px;
}
._3l8PV.uui-input-box.uui-numeric-input-without-arrows {
  padding-right: 5px;
}
._3l8PV.uui-input-box .uui-numeric-input-up-button {
  transform: rotate(180deg);
}
._3l8PV.uui-input-box .uui-numeric-input-button-group > *:hover, ._3l8PV.uui-input-box .uui-numeric-input-button-group > *:focus {
  outline: none;
  background-color: #FAFAFC;
}
._3l8PV.uui-input-box .uui-input.uui-value-in-placeholder::placeholder {
  color: inherit;
}
._3l8PV.uui-input-box .uui-icon svg {
  margin-top: -2px;
}
._3l8PV.uui-input-box._1NsLw .uui-numeric-input-button-group > * {
  height: 11px;
}
._3l8PV.uui-input-box.h_fOn .uui-numeric-input-button-group > * {
  height: 14px;
}
._3l8PV.uui-input-box._3khNZ .uui-numeric-input-button-group > * {
  height: 17px;
}
._3l8PV.uui-input-box._26N77 .uui-numeric-input-button-group > * {
  height: 23px;
}
._3l8PV.uui-disabled .uui-numeric-input-button-group > *:hover, ._3l8PV.uui-disabled .uui-numeric-input-button-group > *:focus {
  cursor: default;
  background-color: transparent;
}
._3l8PV.uui-readonly._1BIBJ {
  background: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
._3l8PV.uui-readonly._3Tm-c {
  background: transparent;
  pointer-events: none;
}
._3l8PV.uui-readonly._1BIBJ:hover {
  border-color: #E1E3EB;
}
._3l8PV.uui-readonly._3GCFQ:hover {
  border-color: transparent;
}
._3l8PV.uui-readonly._3Tm-c:hover {
  border-color: transparent;
}