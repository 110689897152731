/* Carbon scale */
/* Night scale */
._1-4KD .hero-header, ._1-4KD h1, ._1-4KD h2, ._1-4KD h3, ._1-4KD h4, ._1-4KD h5, ._1-4KD h6 {
  margin: 0;
}
._1-4KD .hero-header, ._1-4KD h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1-4KD .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1-4KD h3, ._1-4KD h4, ._1-4KD h5, ._1-4KD h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1-4KD h1, ._1-4KD h2, ._1-4KD h3, ._1-4KD h4, ._1-4KD h5, ._1-4KD h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1-4KD h1 {
  font-size: 42px;
}
._1-4KD h2 {
  font-size: 30px;
}
._1-4KD h3 {
  font-size: 24px;
}
._1-4KD h4 {
  font-size: 18px;
}
._1-4KD h5 {
  font-size: 16px;
}
._1-4KD h6 {
  font-size: 14px;
}

._37X2s b, ._37X2s strong {
  color: #1D1E26;
}
._37X2s ol, ._37X2s ul {
  padding: 0;
}
._37X2s li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._37X2s pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._37X2s img {
  max-width: 100%;
}
._37X2s figure {
  margin: 0;
}
._37X2s figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._37X2s p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.IZ70p b, .IZ70p strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.IZ70p i, .IZ70p em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.IZ70p a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.IZ70p a:hover {
  text-decoration: none;
  color: #007799;
}
.IZ70p code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.v2alD {
  font-size: 16px;
}

._2sF9- {
  font-size: 14px;
}

._3QHH- {
  font-size: 12px;
}

._1gQUz {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3zJ_L {
  color: #474A59;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 12px;
  position: relative;
  width: 0;
  border-right: 1px solid transparent;
  margin-left: -1px;
  background-clip: padding-box;
}
._3zJ_L.-clickable {
  cursor: pointer;
}
._3zJ_L.-clickable:hover {
  background: #EBEDF5;
  box-shadow: -1px 0 0 0 #E1E3EB, 1px 0 0 0 #E1E3EB;
}
._3zJ_L.-clickable:hover ._1gOtI {
  fill: #6C6F80;
}
._3zJ_L._2CjKY {
  height: 24px;
}
._3zJ_L._3xCT5 {
  height: 30px;
}
._3zJ_L._3eTgI {
  height: 36px;
}
._3zJ_L._1YT1q {
  height: 42px;
}
._3zJ_L._1kmWu {
  height: 48px;
}
._3zJ_L._1iywW {
  height: 60px;
}
._3zJ_L.Km3ic {
  padding-left: 18px;
}
._3zJ_L._3kn4j {
  padding-left: 24px;
}
._3zJ_L._3OdVB {
  padding-left: 30px;
}
._3zJ_L._1AfpY {
  padding-left: 36px;
}
._3zJ_L._3U-V1 {
  padding-right: 12px;
}
._3zJ_L._32LCJ {
  padding-right: 18px;
}
._3zJ_L._2QR1A {
  padding-right: 24px;
}
._3zJ_L._360HN {
  padding-right: 30px;
}
._3zJ_L._2nhV- {
  padding-right: 36px;
}
._3zJ_L ._19jKG {
  font-family: "Sans Semibold", Arial, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._3zJ_L .TA7ie {
  margin-right: 12px;
}
._3zJ_L ._3S8VX {
  flex-shrink: 0;
}
._3zJ_L ._1gOtI {
  margin-left: 1px;
}
._3zJ_L ._1gOtI svg {
  width: 16px;
  height: 16px;
}
._3zJ_L ._2-sSj {
  fill: #6C6F80;
}
._3zJ_L ._2ALzP svg {
  width: 20px;
  height: 20px;
}
._3zJ_L ._15z3C {
  display: flex;
  overflow: hidden;
}
._3zJ_L ._15z3C._2-gr8 {
  justify-content: flex-end;
}
._3zJ_L ._15z3C._23-bS {
  justify-content: center;
}
._3zJ_L ._12gqF {
  width: 100%;
  min-width: 0;
}
._3zJ_L ._1Vufx {
  flex-shrink: 0;
  margin-left: 5px;
}
._3zJ_L ._1DScv {
  top: 0;
}
._3zJ_L ._3Lgio {
  text-transform: uppercase;
  font-size: 12px;
}
._3zJ_L ._11oGD {
  font-size: 13px;
}
._3zJ_L ._2IZLP {
  font-size: 14px;
}
._3zJ_L._2dt3q:hover {
  box-shadow: -1px 0 0 0 #E1E3EB, 1px 0 0 0 #E1E3EB;
}
._3zJ_L ._13Qga {
  position: absolute;
  width: 12px;
  right: 0;
  height: 100%;
  cursor: col-resize;
}
._3zJ_L._2TJ-O {
  cursor: pointer;
}
._3zJ_L._2WGvx {
  background-color: #EBEDF5;
}
._3zJ_L._3lfpl {
  background-color: #F5F6FA;
}
._3zJ_L._3lfpl ._19jKG {
  color: #ACAFBF;
}
._3zJ_L._3lfpl ._3S8VX {
  fill: #ACAFBF;
}
._3zJ_L.OqWz0 {
  box-shadow: inset 2px 0 0 0 #009ECC;
}
._3zJ_L.OqWz0.-clickable, ._3zJ_L.OqWz0._2dt3q {
  box-shadow: inset 2px 0 0 0 #009ECC;
}
._3zJ_L._1lZt_ {
  box-shadow: inset -2px 0 0 0 #009ECC;
}
._3zJ_L._1lZt_.-clickable, ._3zJ_L._1lZt_._2dt3q {
  box-shadow: inset -2px 0 0 0 #009ECC;
}