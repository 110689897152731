/* Carbon scale */
/* Night scale */
.mF3ND .hero-header, .mF3ND h1, .mF3ND h2, .mF3ND h3, .mF3ND h4, .mF3ND h5, .mF3ND h6 {
  margin: 0;
}
.mF3ND .hero-header, .mF3ND h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.mF3ND .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.mF3ND h3, .mF3ND h4, .mF3ND h5, .mF3ND h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.mF3ND h1, .mF3ND h2, .mF3ND h3, .mF3ND h4, .mF3ND h5, .mF3ND h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.mF3ND h1 {
  font-size: 42px;
}
.mF3ND h2 {
  font-size: 30px;
}
.mF3ND h3 {
  font-size: 24px;
}
.mF3ND h4 {
  font-size: 18px;
}
.mF3ND h5 {
  font-size: 16px;
}
.mF3ND h6 {
  font-size: 14px;
}

._1Mrhn b, ._1Mrhn strong {
  color: #1D1E26;
}
._1Mrhn ol, ._1Mrhn ul {
  padding: 0;
}
._1Mrhn li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1Mrhn pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1Mrhn img {
  max-width: 100%;
}
._1Mrhn figure {
  margin: 0;
}
._1Mrhn figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1Mrhn p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.il6ks b, .il6ks strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.il6ks i, .il6ks em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.il6ks a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.il6ks a:hover {
  text-decoration: none;
  color: #007799;
}
.il6ks code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2KIEm {
  font-size: 16px;
}

._1QYM0 {
  font-size: 14px;
}

._1SzJQ {
  font-size: 12px;
}

._1e40y {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.Vgox7 {
  padding-top: 2px;
  width: 100%;
  fill: #ACAFBF;
  box-sizing: border-box;
}
.Vgox7 .uui-input-prefix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-left: -5px;
  padding: 6px;
  border-right: 1px solid #CED0DB;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-top: -2px;
}
.Vgox7 .uui-input-suffix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-right: -5px;
  padding: 6px;
  border-left: 1px solid #CED0DB;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: -2px;
}
.Vgox7 .uui-input {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 2px;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}
.Vgox7 .uui-input::placeholder {
  color: #ACAFBF;
}
.Vgox7 .uui-input::-moz-placeholder {
  opacity: 1;
}
.Vgox7 .uui-button-box {
  margin-right: 4px;
  margin-bottom: 2px;
}
.Vgox7 .uui-button-box:last-of-type {
  margin-right: 0;
}
.Vgox7 .uui-placeholder {
  color: #ACAFBF;
}
.Vgox7 .uui-placeholder::placeholder {
  color: #474A59;
}
.Vgox7 .uui-placeholder::-moz-placeholder {
  opacity: 1;
}
.Vgox7 .uui-icon-cancel {
  margin-left: 6px;
}
.Vgox7._3Fh8O.uui-input-box {
  min-height: 24px;
  border-width: 1px;
}
.Vgox7._3Fh8O .uui-input-prefix,
.Vgox7._3Fh8O .uui-input-suffix,
.Vgox7._3Fh8O .uui-input {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.Vgox7._3Fh8O .uui-input {
  padding-top: 0;
  padding-bottom: 0;
}
.Vgox7._3Fh8O :not(.uui-button-box) > .uui-icon {
  height: 18px;
  margin-bottom: 2px;
}
.Vgox7._3bFNN.uui-input-box {
  min-height: 30px;
  border-width: 1px;
}
.Vgox7._3bFNN .uui-input-prefix,
.Vgox7._3bFNN .uui-input-suffix,
.Vgox7._3bFNN .uui-input {
  line-height: 18px;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.Vgox7._3bFNN .uui-input {
  padding-top: 3px;
  padding-bottom: 3px;
}
.Vgox7._3bFNN :not(.uui-button-box) > .uui-icon {
  height: 24px;
  margin-bottom: 2px;
}
.Vgox7._2ju0I.uui-input-box {
  min-height: 36px;
  border-width: 1px;
}
.Vgox7._2ju0I .uui-input-prefix,
.Vgox7._2ju0I .uui-input-suffix,
.Vgox7._2ju0I .uui-input {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.Vgox7._2ju0I .uui-input {
  padding-top: 6px;
  padding-bottom: 6px;
}
.Vgox7._2ju0I :not(.uui-button-box) > .uui-icon {
  height: 30px;
  margin-bottom: 2px;
}
.Vgox7._1OAlm.uui-input-box {
  min-height: 42px;
  border-width: 1px;
}
.Vgox7._1OAlm .uui-input-prefix,
.Vgox7._1OAlm .uui-input-suffix,
.Vgox7._1OAlm .uui-input {
  line-height: 24px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.Vgox7._1OAlm .uui-input {
  padding-top: 6px;
  padding-bottom: 6px;
}
.Vgox7._1OAlm :not(.uui-button-box) > .uui-icon {
  height: 36px;
  margin-bottom: 2px;
}
.Vgox7._2MsCT.uui-input-box {
  min-height: 48px;
  border-width: 1px;
}
.Vgox7._2MsCT .uui-input-prefix,
.Vgox7._2MsCT .uui-input-suffix,
.Vgox7._2MsCT .uui-input {
  line-height: 24px;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.Vgox7._2MsCT .uui-input {
  padding-top: 9px;
  padding-bottom: 9px;
}
.Vgox7._2MsCT :not(.uui-button-box) > .uui-icon {
  height: 42px;
  margin-bottom: 2px;
}
.Vgox7._2iBLh {
  background-color: #FFFFFF;
  border-color: #CED0DB;
  border-style: solid;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.Vgox7._2iBLh:hover {
  border-color: #6C6F80;
}
.Vgox7._2iBLh.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #007799;
}
.Vgox7._2iBLh._3Fh8O {
  min-width: 24px;
}
.Vgox7._2iBLh._3bFNN {
  min-width: 30px;
}
.Vgox7._2iBLh._2ju0I {
  min-width: 36px;
}
.Vgox7._2iBLh._1OAlm {
  min-width: 42px;
}
.Vgox7._2iBLh._2MsCT {
  min-width: 48px;
}
.Vgox7._2iBLh.c8B4J {
  min-width: 60px;
}
.Vgox7._3n5LW {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 3px;
}
.Vgox7._3n5LW:hover {
  border-color: #CED0DB;
}
.Vgox7._3n5LW.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #009ECC;
}
.Vgox7._1E3xv {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 0;
}
.Vgox7._1E3xv:hover .uui-icon.-clickable.uui-enabled, .Vgox7._1E3xv:hover .uui-icon-dropdown.uui-enabled {
  fill: #6C6F80;
}
.Vgox7._1E3xv:hover .uui-icon.-clickable.uui-enabled:hover, .Vgox7._1E3xv:hover .uui-icon-dropdown.uui-enabled:hover {
  fill: #474A59;
}
.Vgox7._1E3xv:hover .uui-icon.-clickable.uui-disabled, .Vgox7._1E3xv:hover .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
.Vgox7._1E3xv .uui-icon.-clickable, .Vgox7._1E3xv .uui-icon-dropdown {
  cursor: pointer;
}
.Vgox7._1E3xv .uui-icon.-clickable.uui-enabled, .Vgox7._1E3xv .uui-icon-dropdown.uui-enabled {
  fill: transparent;
}
.Vgox7._1E3xv .uui-icon.-clickable.uui-disabled, .Vgox7._1E3xv .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
.Vgox7._1E3xv.uui-focus .uui-input-prefix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 2px 0 0);
}
.Vgox7._1E3xv.uui-focus .uui-input-suffix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 0 0 2px);
}
.Vgox7._3n5LW .uui-input-prefix, .Vgox7._1E3xv .uui-input-prefix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none;
  margin-left: 0;
}
.Vgox7._3n5LW .uui-input-suffix, .Vgox7._1E3xv .uui-input-suffix {
  border-left: none;
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Vgox7.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  fill: #E1E3EB;
}
.Vgox7.uui-disabled .uui-input {
  color: #ACAFBF;
}
.Vgox7.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
.Vgox7.uui-disabled:hover, .Vgox7.uui-disabled:active, .Vgox7.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
.Vgox7.uui-disabled:hover .uui-input, .Vgox7.uui-disabled:active .uui-input, .Vgox7.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
.Vgox7.uui-disabled:hover .uui-input::placeholder, .Vgox7.uui-disabled:active .uui-input::placeholder, .Vgox7.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
.Vgox7.uui-disabled::placeholder {
  color: #E1E3EB;
}
.Vgox7.uui-disabled._3n5LW, .Vgox7.uui-disabled._1E3xv {
  background-color: transparent;
  border-color: transparent;
}
.Vgox7.uui-disabled .uui-input {
  cursor: default;
}
.Vgox7.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
.Vgox7.uui-disabled .uui-icon-dropdown, .Vgox7.uui-disabled .uui-icon-cancel {
  visibility: hidden;
}
.Vgox7.uui-readonly._2iBLh {
  background: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
.Vgox7.uui-readonly._1E3xv {
  background: transparent;
  pointer-events: none;
}
.Vgox7.uui-readonly._2iBLh:hover {
  border-color: #E1E3EB;
}
.Vgox7.uui-readonly._3n5LW:hover {
  border-color: transparent;
}
.Vgox7.uui-readonly._1E3xv:hover {
  border-color: transparent;
}
.Vgox7.uui-readonly .uui-icon-dropdown, .Vgox7.uui-readonly .uui-icon-cancel {
  visibility: hidden;
}
.Vgox7.uui-invalid._2iBLh {
  border-color: #FA4B4B;
}
.Vgox7 .uui-icon.-clickable, .Vgox7 .uui-icon-dropdown {
  cursor: pointer;
}
.Vgox7 .uui-icon.-clickable.uui-enabled, .Vgox7 .uui-icon-dropdown.uui-enabled {
  fill: #6C6F80;
}
.Vgox7 .uui-icon.-clickable.uui-enabled:hover, .Vgox7 .uui-icon-dropdown.uui-enabled:hover {
  fill: #474A59;
}
.Vgox7 .uui-icon.-clickable.uui-disabled, .Vgox7 .uui-icon-dropdown.uui-disabled {
  fill: #ACAFBF;
  cursor: default;
}