._2JVQb {
  cursor: pointer;
}
._2JVQb.uui-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}
._2JVQb .uui-input {
  flex: 1 0 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
}
._2JVQb .uui-input._2DMa3 {
  flex: 1 1 auto;
}
._2JVQb .uui-input._3EaN4 {
  cursor: text;
}
._2JVQb .uui-input-prefix {
  white-space: nowrap;
}
._2JVQb .uui-input-suffix {
  white-space: nowrap;
}

._1Ur83 {
  display: flex;
  width: 100%;
  overflow: hidden;
  outline: none;
  align-items: center;
}
._1Ur83.mpznD {
  flex-wrap: wrap;
}

._2XBcU {
  display: flex;
  flex-shrink: 0;
}

._1ppTd {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}