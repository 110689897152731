/* Carbon scale */
/* Night scale */
._2VJZQ .hero-header, ._2VJZQ h1, ._2VJZQ h2, ._2VJZQ h3, ._2VJZQ h4, ._2VJZQ h5, ._2VJZQ h6 {
  margin: 0;
}
._2VJZQ .hero-header, ._2VJZQ h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2VJZQ .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2VJZQ h3, ._2VJZQ h4, ._2VJZQ h5, ._2VJZQ h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2VJZQ h1, ._2VJZQ h2, ._2VJZQ h3, ._2VJZQ h4, ._2VJZQ h5, ._2VJZQ h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2VJZQ h1 {
  font-size: 42px;
}
._2VJZQ h2 {
  font-size: 30px;
}
._2VJZQ h3 {
  font-size: 24px;
}
._2VJZQ h4 {
  font-size: 18px;
}
._2VJZQ h5 {
  font-size: 16px;
}
._2VJZQ h6 {
  font-size: 14px;
}

._2PkRa b, ._2PkRa strong {
  color: #1D1E26;
}
._2PkRa ol, ._2PkRa ul {
  padding: 0;
}
._2PkRa li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2PkRa pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2PkRa img {
  max-width: 100%;
}
._2PkRa figure {
  margin: 0;
}
._2PkRa figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2PkRa p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2sssP b, ._2sssP strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2sssP i, ._2sssP em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2sssP a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2sssP a:hover {
  text-decoration: none;
  color: #007799;
}
._2sssP code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._42CEt {
  font-size: 16px;
}

._2j3cK {
  font-size: 14px;
}

._1V4ai {
  font-size: 12px;
}

._1DJtT {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2q9gP {
  border-radius: 6px;
  overflow: hidden;
}
._2q9gP._2ZB96 {
  background-color: white;
}
._2q9gP.LBOda {
  background-color: #FAFAFC;
}
._2q9gP._1iXpm {
  background-color: transparent;
}
._2q9gP.E1spM {
  margin: 24px;
}
._2q9gP._28AWZ {
  padding: 12px;
}
._2q9gP._1e6pf {
  padding: 24px;
}
._2q9gP._1Gl4W {
  box-shadow: 0 1px 3px 0 rgba(51, 51, 51, 0.2);
}
._2q9gP.-clickable:hover {
  cursor: pointer;
}