/* Carbon scale */
/* Night scale */
._1FuLj .hero-header, ._1FuLj h1, ._1FuLj h2, ._1FuLj h3, ._1FuLj h4, ._1FuLj h5, ._1FuLj h6 {
  margin: 0;
}
._1FuLj .hero-header, ._1FuLj h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1FuLj .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1FuLj h3, ._1FuLj h4, ._1FuLj h5, ._1FuLj h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1FuLj h1, ._1FuLj h2, ._1FuLj h3, ._1FuLj h4, ._1FuLj h5, ._1FuLj h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1FuLj h1 {
  font-size: 42px;
}
._1FuLj h2 {
  font-size: 30px;
}
._1FuLj h3 {
  font-size: 24px;
}
._1FuLj h4 {
  font-size: 18px;
}
._1FuLj h5 {
  font-size: 16px;
}
._1FuLj h6 {
  font-size: 14px;
}

._3YB0r b, ._3YB0r strong {
  color: #1D1E26;
}
._3YB0r ol, ._3YB0r ul {
  padding: 0;
}
._3YB0r li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3YB0r pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3YB0r img {
  max-width: 100%;
}
._3YB0r figure {
  margin: 0;
}
._3YB0r figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3YB0r p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2ugnN b, ._2ugnN strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2ugnN i, ._2ugnN em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2ugnN a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2ugnN a:hover {
  text-decoration: none;
  color: #007799;
}
._2ugnN code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

.Ot3km {
  font-size: 16px;
}

._2xDIY {
  font-size: 14px;
}

._1XJ2Z {
  font-size: 12px;
}

._2cPXL {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2Qg16 {
  height: 100%;
  padding-top: 6px;
}
._2Qg16 ._1H6kv {
  margin-bottom: -6px;
}
._2Qg16 ._1H6kv > ._2CHBF {
  padding-top: 0;
}
._2Qg16 ._1H6kv > ._3mgGs {
  top: -3px;
}
._2Qg16 ._1H6kv > ._3mgGs > .uui-caption {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2Qg16 ._3TIba {
  flex-direction: column;
}
._2Qg16 ._31dcB {
  flex-direction: column;
  top: calc(50% - 35px);
  position: relative;
}
._2Qg16 .mPBex {
  width: 100%;
  box-sizing: border-box;
  padding: 6px 24px;
}
._2Qg16 .mPBex::before {
  content: "";
  height: 1px;
  display: block;
  background: #E1E3EB;
}

._2zxUh {
  padding-bottom: 6px;
}