/* Carbon scale */
/* Night scale */
.OT9YU .hero-header, .OT9YU h1, .OT9YU h2, .OT9YU h3, .OT9YU h4, .OT9YU h5, .OT9YU h6 {
  margin: 0;
}
.OT9YU .hero-header, .OT9YU h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.OT9YU .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.OT9YU h3, .OT9YU h4, .OT9YU h5, .OT9YU h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.OT9YU h1, .OT9YU h2, .OT9YU h3, .OT9YU h4, .OT9YU h5, .OT9YU h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.OT9YU h1 {
  font-size: 42px;
}
.OT9YU h2 {
  font-size: 30px;
}
.OT9YU h3 {
  font-size: 24px;
}
.OT9YU h4 {
  font-size: 18px;
}
.OT9YU h5 {
  font-size: 16px;
}
.OT9YU h6 {
  font-size: 14px;
}

._1z_5V b, ._1z_5V strong {
  color: #1D1E26;
}
._1z_5V ol, ._1z_5V ul {
  padding: 0;
}
._1z_5V li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1z_5V pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1z_5V img {
  max-width: 100%;
}
._1z_5V figure {
  margin: 0;
}
._1z_5V figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1z_5V p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1XgKA b, ._1XgKA strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1XgKA i, ._1XgKA em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1XgKA a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1XgKA a:hover {
  text-decoration: none;
  color: #007799;
}
._1XgKA code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1SbUx {
  font-size: 16px;
}

._3V05A {
  font-size: 14px;
}

._3tp_e {
  font-size: 12px;
}

._12FOL {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._150qE {
  border-top: 1px solid #CED0DB;
}