/* Carbon scale */
/* Night scale */
._2ukQ5 .hero-header, ._2ukQ5 h1, ._2ukQ5 h2, ._2ukQ5 h3, ._2ukQ5 h4, ._2ukQ5 h5, ._2ukQ5 h6 {
  margin: 0;
}
._2ukQ5 .hero-header, ._2ukQ5 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2ukQ5 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2ukQ5 h3, ._2ukQ5 h4, ._2ukQ5 h5, ._2ukQ5 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2ukQ5 h1, ._2ukQ5 h2, ._2ukQ5 h3, ._2ukQ5 h4, ._2ukQ5 h5, ._2ukQ5 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2ukQ5 h1 {
  font-size: 42px;
}
._2ukQ5 h2 {
  font-size: 30px;
}
._2ukQ5 h3 {
  font-size: 24px;
}
._2ukQ5 h4 {
  font-size: 18px;
}
._2ukQ5 h5 {
  font-size: 16px;
}
._2ukQ5 h6 {
  font-size: 14px;
}

._1KlQg b, ._1KlQg strong {
  color: #1D1E26;
}
._1KlQg ol, ._1KlQg ul {
  padding: 0;
}
._1KlQg li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1KlQg pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1KlQg img {
  max-width: 100%;
}
._1KlQg figure {
  margin: 0;
}
._1KlQg figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1KlQg p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2lj5y b, ._2lj5y strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2lj5y i, ._2lj5y em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2lj5y a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2lj5y a:hover {
  text-decoration: none;
  color: #007799;
}
._2lj5y code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3evi5 {
  font-size: 16px;
}

._1LUfr {
  font-size: 14px;
}

._1Zp3B {
  font-size: 12px;
}

._1dQMT {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.Xg8G4 .uui-presets-container {
  padding: 24px;
  font-family: "Sans Regular", Arial, sans-serif;
}
.Xg8G4 .uui-presets-header {
  padding-bottom: 12px;
}
.Xg8G4 .uui-presets-item {
  color: #009ECC;
  padding: 0;
}
.Xg8G4 .uui-presets-item:hover {
  color: #007799;
  cursor: pointer;
}