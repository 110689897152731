[data-placement^=right] .uui-popover-arrow {
  right: 100%;
}
[data-placement^=right] .uui-popover-arrow::after {
  transform: translateX(100%) translateY(20%) rotate(45deg);
}

[data-placement^=left] .uui-popover-arrow {
  left: 100%;
}
[data-placement^=left] .uui-popover-arrow::after {
  transform: translateX(-50%) translateY(20%) rotate(45deg);
}

[data-placement^=top] .uui-popover-arrow {
  top: 100%;
}
[data-placement^=top] .uui-popover-arrow::after {
  transform: translateX(30%) translateY(-50%) rotate(45deg);
}

[data-placement^=bottom] .uui-popover-arrow {
  bottom: 100%;
}
[data-placement^=bottom] .uui-popover-arrow::after {
  transform: translateX(30%) translateY(100%) rotate(45deg);
}