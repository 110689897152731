/* Carbon scale */
/* Night scale */
.prX_v {
  background-color: #E1E3EB;
  font-family: "Sans Regular", Arial, sans-serif;
  color: white;
}
.prX_v .bar {
  background-color: #009ECC;
}
.prX_v .topLabel {
  color: #1D1E26;
}
.prX_v._3eSBe .bar {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: _3HcBu 1s linear infinite;
}

@keyframes _3HcBu {
  0% {
    background-position-x: 1rem;
  }
}
.NZgSa {
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}

._2UDmO {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

._2DFT3 {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
}