/* Carbon scale */
/* Night scale */
._1S1FF .hero-header, ._1S1FF h1, ._1S1FF h2, ._1S1FF h3, ._1S1FF h4, ._1S1FF h5, ._1S1FF h6 {
  margin: 0;
}
._1S1FF .hero-header, ._1S1FF h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1S1FF .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1S1FF h3, ._1S1FF h4, ._1S1FF h5, ._1S1FF h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1S1FF h1, ._1S1FF h2, ._1S1FF h3, ._1S1FF h4, ._1S1FF h5, ._1S1FF h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1S1FF h1 {
  font-size: 42px;
}
._1S1FF h2 {
  font-size: 30px;
}
._1S1FF h3 {
  font-size: 24px;
}
._1S1FF h4 {
  font-size: 18px;
}
._1S1FF h5 {
  font-size: 16px;
}
._1S1FF h6 {
  font-size: 14px;
}

._2SIyI b, ._2SIyI strong {
  color: #1D1E26;
}
._2SIyI ol, ._2SIyI ul {
  padding: 0;
}
._2SIyI li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2SIyI pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2SIyI img {
  max-width: 100%;
}
._2SIyI figure {
  margin: 0;
}
._2SIyI figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2SIyI p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1MYIW b, ._1MYIW strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1MYIW i, ._1MYIW em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1MYIW a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1MYIW a:hover {
  text-decoration: none;
  color: #007799;
}
._1MYIW code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._21zFP {
  font-size: 16px;
}

._3Q0pL {
  font-size: 14px;
}

._26j6H {
  font-size: 12px;
}

.x_S-x {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1XAtk {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
._1XAtk.uui-button-box {
  border-style: solid;
  min-width: inherit;
  justify-content: center;
  border-radius: 3px;
}
._1XAtk .uui-caption {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
  flex: 1 1 auto;
  user-select: none;
}
._1XAtk.-clickable:focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
}

._1sRXR {
  min-width: 18px;
  padding-left: 2px;
  padding-right: 2px;
}
._1sRXR .uui-caption {
  padding-left: 3px;
  padding-right: 3px;
}
._1sRXR.uui-button-box {
  min-height: 18px;
  border-width: 1px;
}

.IDwB3 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.IDwB3 .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
.IDwB3.uui-button-box {
  min-height: 24px;
  border-width: 1px;
}

._1nfAK {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
._1nfAK .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._1nfAK.uui-button-box {
  min-height: 30px;
  border-width: 1px;
}

.e82g4 {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
}
.e82g4 .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
.e82g4.uui-button-box {
  min-height: 36px;
  border-width: 1px;
}

._1lIVa {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
}
._1lIVa .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._1lIVa.uui-button-box {
  min-height: 42px;
  border-width: 1px;
}

._2hp8R {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
}
._2hp8R .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2hp8R.uui-button-box {
  min-height: 48px;
  border-width: 1px;
}

._1pQeP._2hp8R {
  border-radius: 24px;
}
._1pQeP._1lIVa {
  border-radius: 21px;
}
._1pQeP.e82g4 {
  border-radius: 18px;
}
._1pQeP._1nfAK {
  border-radius: 15px;
}
._1pQeP.IDwB3 {
  border-radius: 12px;
}
._1pQeP._1sRXR {
  border-radius: 10px;
}

._2ieK4 {
  background-color: var(--uui-color-700);
  color: var(--uui-contrast-color-700);
  fill: var(--uui-contrast-color-700);
  border-color: var(--uui-color-700);
}
._2ieK4.-clickable:hover, ._2ieK4.-clickable:focus {
  background-color: var(--uui-color-800);
  color: var(--uui-contrast-color-700);
  fill: var(--uui-contrast-color-700);
  border-color: var(--uui-color-800);
}
._2ieK4.-clickable:active {
  background-color: var(--uui-color-900);
  color: var(--uui-contrast-color-700);
  fill: var(--uui-contrast-color-700);
  border-color: var(--uui-color-900);
}
._2ieK4.uui-disabled {
  background-color: #E1E3EB;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
}
._2ieK4.uui-disabled:hover, ._2ieK4.uui-disabled:active, ._2ieK4.uui-disabled:focus {
  background-color: #E1E3EB;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._2ieK4.uui-disabled::placeholder {
  color: #E1E3EB;
}

._3ntJr {
  background-color: #FFFFFF;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._3ntJr.-clickable:hover, ._3ntJr.-clickable:focus {
  background-color: var(--uui-color-500);
  color: var(--uui-color-800);
  fill: var(--uui-color-800);
  border-color: var(--uui-color-800);
}
._3ntJr.-clickable:active {
  background-color: var(--uui-color-600);
  color: var(--uui-color-900);
  fill: var(--uui-color-900);
  border-color: var(--uui-color-900);
}
._3ntJr.uui-disabled {
  background-color: #FFFFFF;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
}
._3ntJr.uui-disabled:hover, ._3ntJr.uui-disabled:active, ._3ntJr.uui-disabled:focus {
  background-color: #FFFFFF;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._3ntJr.uui-disabled::placeholder {
  color: #E1E3EB;
}

._2EX4z {
  background-color: transparent;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._2EX4z.-clickable:hover, ._2EX4z.-clickable:focus {
  background-color: var(--uui-color-500);
  color: var(--uui-color-800);
  fill: var(--uui-color-800);
  border-color: var(--uui-color-800);
}
._2EX4z.-clickable:active {
  background-color: var(--uui-color-600);
  color: var(--uui-color-900);
  fill: var(--uui-color-900);
  border-color: var(--uui-color-900);
}
._2EX4z.uui-disabled {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
}
._2EX4z.uui-disabled:hover, ._2EX4z.uui-disabled:active, ._2EX4z.uui-disabled:focus {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._2EX4z.uui-disabled::placeholder {
  color: #E1E3EB;
}

._5-G5y {
  background-color: transparent;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: transparent;
}
._5-G5y.-clickable:hover, ._5-G5y.-clickable:focus {
  background-color: var(--uui-color-500);
  color: var(--uui-color-800);
  fill: var(--uui-color-800);
  border-color: var(--uui-color-500);
}
._5-G5y.-clickable:active {
  background-color: var(--uui-color-600);
  color: var(--uui-color-900);
  fill: var(--uui-color-900);
  border-color: var(--uui-color-600);
}
._5-G5y.uui-disabled {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: transparent;
  cursor: default;
}
._5-G5y.uui-disabled:hover, ._5-G5y.uui-disabled:active, ._5-G5y.uui-disabled:focus {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: transparent;
  box-shadow: none;
}
._5-G5y.uui-disabled::placeholder {
  color: #E1E3EB;
}