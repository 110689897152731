._15lOd.uui-icon {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-around;
  transform-origin: 50% 50%;
}
._15lOd.uui-icon use, ._15lOd.uui-icon svg {
  pointer-events: none;
}
._15lOd.-clickable:hover {
  cursor: pointer;
}

.JIzep {
  transform: scale(1, -1);
}

._3Cx8u {
  transform: rotate(90deg);
}

.mtzOw {
  transform: rotate(180deg);
}

._1c2ml {
  transform: rotate(-90deg);
}