._2iN7J input::-webkit-outer-spin-button,
._2iN7J input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
._2iN7J.uui-input-box {
  position: relative;
  display: flex;
  outline: none;
}
._2iN7J.uui-input-box .uui-input {
  -moz-appearance: textfield;
  flex: 1 1 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
}
._2iN7J.uui-input-box .uui-input::-ms-clear {
  display: none;
}
._2iN7J.uui-input-box .uui-input:invalid {
  box-shadow: none;
}
._2iN7J.uui-input-box .uui-numeric-input-up-button:hover, ._2iN7J.uui-input-box .uui-numeric-input-down-button:hover {
  cursor: pointer;
}

._16mah {
  text-align: right;
}