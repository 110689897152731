._11MlD {
  display: flex;
  position: relative;
  align-items: center;
  line-height: 0;
}

._3OrFP {
  display: block;
  height: 60px;
}

.jAe-H {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 30px 13px;
  border-color: transparent;
}