/* Carbon scale */
/* Night scale */
._3AC89 .hero-header, ._3AC89 h1, ._3AC89 h2, ._3AC89 h3, ._3AC89 h4, ._3AC89 h5, ._3AC89 h6 {
  margin: 0;
}
._3AC89 .hero-header, ._3AC89 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3AC89 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3AC89 h3, ._3AC89 h4, ._3AC89 h5, ._3AC89 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3AC89 h1, ._3AC89 h2, ._3AC89 h3, ._3AC89 h4, ._3AC89 h5, ._3AC89 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3AC89 h1 {
  font-size: 42px;
}
._3AC89 h2 {
  font-size: 30px;
}
._3AC89 h3 {
  font-size: 24px;
}
._3AC89 h4 {
  font-size: 18px;
}
._3AC89 h5 {
  font-size: 16px;
}
._3AC89 h6 {
  font-size: 14px;
}

.X5D6_ b, .X5D6_ strong {
  color: #1D1E26;
}
.X5D6_ ol, .X5D6_ ul {
  padding: 0;
}
.X5D6_ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.X5D6_ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.X5D6_ img {
  max-width: 100%;
}
.X5D6_ figure {
  margin: 0;
}
.X5D6_ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.X5D6_ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3KY7J b, ._3KY7J strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3KY7J i, ._3KY7J em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3KY7J a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3KY7J a:hover {
  text-decoration: none;
  color: #007799;
}
._3KY7J code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3ljya {
  font-size: 16px;
}

._3wtk7 {
  font-size: 14px;
}

._1qHDL {
  font-size: 12px;
}

.dEtOH {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1mk-A .uui-icon {
  margin: 0 12px;
}
._1mk-A.uui-button-box.-clickable:hover {
  background-color: transparent;
  color: #009ECC;
  fill: #009ECC;
  border-color: transparent;
}

.wpcQb {
  padding: 6px 0;
  border-bottom: 1px solid #E1E3EB;
}

._27crO {
  margin-right: 12px;
}