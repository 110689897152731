.l7mzO {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.l7mzO .uui-modal-blocker {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}