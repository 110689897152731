/* Carbon scale */
/* Night scale */
._2CAkD .hero-header, ._2CAkD h1, ._2CAkD h2, ._2CAkD h3, ._2CAkD h4, ._2CAkD h5, ._2CAkD h6 {
  margin: 0;
}
._2CAkD .hero-header, ._2CAkD h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2CAkD .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2CAkD h3, ._2CAkD h4, ._2CAkD h5, ._2CAkD h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2CAkD h1, ._2CAkD h2, ._2CAkD h3, ._2CAkD h4, ._2CAkD h5, ._2CAkD h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2CAkD h1 {
  font-size: 42px;
}
._2CAkD h2 {
  font-size: 30px;
}
._2CAkD h3 {
  font-size: 24px;
}
._2CAkD h4 {
  font-size: 18px;
}
._2CAkD h5 {
  font-size: 16px;
}
._2CAkD h6 {
  font-size: 14px;
}

.nWf2z b, .nWf2z strong {
  color: #1D1E26;
}
.nWf2z ol, .nWf2z ul {
  padding: 0;
}
.nWf2z li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.nWf2z pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.nWf2z img {
  max-width: 100%;
}
.nWf2z figure {
  margin: 0;
}
.nWf2z figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.nWf2z p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1VGfY b, ._1VGfY strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1VGfY i, ._1VGfY em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1VGfY a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1VGfY a:hover {
  text-decoration: none;
  color: #007799;
}
._1VGfY code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1zVz- {
  font-size: 16px;
}

._16teg {
  font-size: 14px;
}

._2Xcgr {
  font-size: 12px;
}

.NJBjG {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2yBaZ {
  width: 100%;
}
._2yBaZ .uui-label {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
._2yBaZ .uui-label b, ._2yBaZ .uui-label strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2yBaZ .uui-label i, ._2yBaZ .uui-label em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2yBaZ .uui-label a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2yBaZ .uui-label a:hover {
  text-decoration: none;
  color: #007799;
}
._2yBaZ .uui-label code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2yBaZ .uui-label .uui-labeled-input-info-icon {
  flex-shrink: 0;
  margin: 0 4px;
  fill: #009ECC;
}
._2yBaZ .uui-label .uui-labeled-input-asterisk {
  color: #FA4B4B;
}
._2yBaZ .uui-label .uui-labeled-input-optional {
  font-family: "Sans Italic", Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #6C6F80;
}
._2yBaZ .uui-label-left > .uui-label {
  margin-right: 5px;
}
._2yBaZ .uui-invalid-message {
  margin-top: 3px;
  margin-bottom: 2px;
  line-height: 18px;
  font-size: 12px;
  color: #FA4B4B;
  font-family: "Sans Regular", Arial, sans-serif;
}
._2yBaZ._2SmwU .uui-label-top > .uui-label {
  margin-bottom: 6px;
}
._2yBaZ._2SmwU .uui-label {
  font-size: 18px;
  line-height: 24px;
}
._2yBaZ._3konK .uui-label-top > .uui-label {
  margin-bottom: 6px;
}
._2yBaZ._3konK .uui-label {
  font-size: 14px;
  line-height: 24px;
}
._2yBaZ._1Tt70 .uui-label-top > .uui-label {
  padding-bottom: 5px;
  margin-top: 1px;
}
._2yBaZ._1Tt70 .uui-label {
  font-size: 14px;
  line-height: 18px;
}
._2yBaZ._20LJX .uui-label-top > .uui-label {
  padding-bottom: 4px;
}
._2yBaZ._20LJX .uui-label {
  font-size: 12px;
  line-height: 12px;
}
._2yBaZ .uui-label {
  color: var(--uui-color-700);
}