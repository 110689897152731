/* Carbon scale */
/* Night scale */
._1G2qw .hero-header, ._1G2qw h1, ._1G2qw h2, ._1G2qw h3, ._1G2qw h4, ._1G2qw h5, ._1G2qw h6 {
  margin: 0;
}
._1G2qw .hero-header, ._1G2qw h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1G2qw .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1G2qw h3, ._1G2qw h4, ._1G2qw h5, ._1G2qw h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1G2qw h1, ._1G2qw h2, ._1G2qw h3, ._1G2qw h4, ._1G2qw h5, ._1G2qw h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1G2qw h1 {
  font-size: 42px;
}
._1G2qw h2 {
  font-size: 30px;
}
._1G2qw h3 {
  font-size: 24px;
}
._1G2qw h4 {
  font-size: 18px;
}
._1G2qw h5 {
  font-size: 16px;
}
._1G2qw h6 {
  font-size: 14px;
}

._3QT2Q b, ._3QT2Q strong {
  color: #1D1E26;
}
._3QT2Q ol, ._3QT2Q ul {
  padding: 0;
}
._3QT2Q li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3QT2Q pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3QT2Q img {
  max-width: 100%;
}
._3QT2Q figure {
  margin: 0;
}
._3QT2Q figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3QT2Q p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._33S49 b, ._33S49 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._33S49 i, ._33S49 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._33S49 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._33S49 a:hover {
  text-decoration: none;
  color: #007799;
}
._33S49 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3t6Vv {
  font-size: 16px;
}

._1mO8N {
  font-size: 14px;
}

._3HZPS {
  font-size: 12px;
}

._3HRGM {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}