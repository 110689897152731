/* Carbon scale */
/* Night scale */
._2dSIj .hero-header, ._2dSIj h1, ._2dSIj h2, ._2dSIj h3, ._2dSIj h4, ._2dSIj h5, ._2dSIj h6 {
  margin: 0;
}
._2dSIj .hero-header, ._2dSIj h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2dSIj .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2dSIj h3, ._2dSIj h4, ._2dSIj h5, ._2dSIj h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2dSIj h1, ._2dSIj h2, ._2dSIj h3, ._2dSIj h4, ._2dSIj h5, ._2dSIj h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2dSIj h1 {
  font-size: 42px;
}
._2dSIj h2 {
  font-size: 30px;
}
._2dSIj h3 {
  font-size: 24px;
}
._2dSIj h4 {
  font-size: 18px;
}
._2dSIj h5 {
  font-size: 16px;
}
._2dSIj h6 {
  font-size: 14px;
}

._3euJZ b, ._3euJZ strong {
  color: #1D1E26;
}
._3euJZ ol, ._3euJZ ul {
  padding: 0;
}
._3euJZ li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3euJZ pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3euJZ img {
  max-width: 100%;
}
._3euJZ figure {
  margin: 0;
}
._3euJZ figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3euJZ p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.QyH2F b, .QyH2F strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.QyH2F i, .QyH2F em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.QyH2F a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.QyH2F a:hover {
  text-decoration: none;
  color: #007799;
}
.QyH2F code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2I13k {
  font-size: 16px;
}

._33As1 {
  font-size: 14px;
}

.JzJpA {
  font-size: 12px;
}

._23HhW {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._38f3X {
  color: #303240;
  fill: #6C6F80;
  box-sizing: border-box;
}
._38f3X .uui-input-prefix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-left: -5px;
  padding: 6px;
  border-right: 1px solid #CED0DB;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
._38f3X .uui-input-suffix {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-right: -5px;
  padding: 6px;
  border-left: 1px solid #CED0DB;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
._38f3X .uui-input {
  color: #303240;
  font-family: "Sans Regular", Arial, sans-serif;
}
._38f3X .uui-input::placeholder {
  color: #ACAFBF;
}
._38f3X .uui-input:placeholder-shown {
  text-overflow: ellipsis;
}
._38f3X .uui-input::-moz-placeholder {
  opacity: 1;
}
._38f3X._1w5H0 {
  background-color: #FFFFFF;
  border-color: #CED0DB;
  border-style: solid;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
._38f3X._1w5H0:hover {
  border-color: #6C6F80;
}
._38f3X._1w5H0.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #007799;
}
._38f3X._1w5H0 .uui-input {
  padding-left: 6px;
  padding-right: 6px;
}
._38f3X._1w5H0.r0kC_ {
  min-width: 24px;
}
._38f3X._1w5H0._28cA1 {
  min-width: 30px;
}
._38f3X._1w5H0._18wzC {
  min-width: 36px;
}
._38f3X._1w5H0._3gmVI {
  min-width: 42px;
}
._38f3X._1w5H0._1EGp- {
  min-width: 48px;
}
._38f3X._1w5H0.slOZO {
  min-width: 60px;
}
._38f3X._1JELs {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 3px;
}
._38f3X._1JELs:hover {
  border-color: #CED0DB;
}
._38f3X._1JELs.uui-focus {
  box-shadow: 0 0 0 2px rgba(0, 137, 204, 0.4);
  border-color: #009ECC;
}
._38f3X._1JELs .uui-input-prefix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none;
  margin-left: 0;
}
._38f3X._1JELs .uui-input-suffix {
  border-left: none;
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
._38f3X._1JELs .uui-input {
  padding-left: 5px;
  padding-right: 5px;
}
._38f3X._33wyJ {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: 0;
  padding: 0 6px;
}
._38f3X._33wyJ:hover .uui-icon.-clickable.uui-enabled, ._38f3X._33wyJ:hover .uui-icon-dropdown.uui-enabled {
  fill: #6C6F80;
}
._38f3X._33wyJ:hover .uui-icon.-clickable.uui-enabled:hover, ._38f3X._33wyJ:hover .uui-icon-dropdown.uui-enabled:hover {
  fill: #474A59;
}
._38f3X._33wyJ:hover .uui-icon.-clickable.uui-disabled, ._38f3X._33wyJ:hover .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
._38f3X._33wyJ .uui-icon.-clickable, ._38f3X._33wyJ .uui-icon-dropdown {
  cursor: pointer;
}
._38f3X._33wyJ .uui-icon.-clickable.uui-enabled, ._38f3X._33wyJ .uui-icon-dropdown.uui-enabled {
  fill: transparent;
}
._38f3X._33wyJ .uui-icon.-clickable.uui-disabled, ._38f3X._33wyJ .uui-icon-dropdown.uui-disabled {
  fill: transparent;
}
._38f3X._33wyJ .uui-input-prefix {
  border-right: none;
}
._38f3X._33wyJ .uui-input-suffix {
  border-left: none;
}
._38f3X._33wyJ.uui-focus .uui-input-prefix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 2px 0 0);
}
._38f3X._33wyJ.uui-focus .uui-input-suffix {
  box-shadow: inset 0 0 0 2px rgba(48, 182, 221, 0.4);
  clip-path: inset(0 0 0 2px);
}
._38f3X._33wyJ .uui-input {
  padding-left: 5px;
  padding-right: 5px;
}
._38f3X._33wyJ .uui-icon:first-child {
  padding-left: 5px;
}
._38f3X.uui-disabled {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  cursor: default;
  pointer-events: none;
  fill: #ACAFBF;
}
._38f3X.uui-disabled .uui-input {
  color: #ACAFBF;
}
._38f3X.uui-disabled .uui-input::placeholder {
  color: #ACAFBF;
}
._38f3X.uui-disabled:hover, ._38f3X.uui-disabled:active, ._38f3X.uui-disabled:focus {
  background-color: #FAFAFC;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: #E1E3EB;
  box-shadow: none;
}
._38f3X.uui-disabled:hover .uui-input, ._38f3X.uui-disabled:active .uui-input, ._38f3X.uui-disabled:focus .uui-input {
  color: #ACAFBF;
}
._38f3X.uui-disabled:hover .uui-input::placeholder, ._38f3X.uui-disabled:active .uui-input::placeholder, ._38f3X.uui-disabled:focus .uui-input::placeholder {
  color: #ACAFBF;
}
._38f3X.uui-disabled::placeholder {
  color: #E1E3EB;
}
._38f3X.uui-disabled .uui-input::placeholder {
  color: #E1E3EB;
}
._38f3X.uui-disabled._1JELs, ._38f3X.uui-disabled._33wyJ {
  background-color: transparent;
  color: #ACAFBF;
  fill: #ACAFBF;
  border-color: transparent;
}
._38f3X.uui-disabled._1JELs .uui-input, ._38f3X.uui-disabled._33wyJ .uui-input {
  color: #ACAFBF;
}
._38f3X.uui-disabled._1JELs .uui-input::placeholder, ._38f3X.uui-disabled._33wyJ .uui-input::placeholder {
  color: #ACAFBF;
}
._38f3X.uui-readonly._1w5H0 {
  background: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
._38f3X.uui-readonly._33wyJ {
  background: transparent;
  pointer-events: none;
}
._38f3X.uui-readonly._1w5H0:hover {
  border-color: #E1E3EB;
}
._38f3X.uui-readonly._1JELs:hover {
  border-color: transparent;
}
._38f3X.uui-readonly._33wyJ:hover {
  border-color: transparent;
}
._38f3X.uui-invalid._1w5H0 {
  border-color: #FA4B4B;
}
._38f3X.r0kC_.uui-button-box, ._38f3X.r0kC_.uui-input-box {
  min-height: 24px;
  border-width: 1px;
}
._38f3X._28cA1.uui-button-box, ._38f3X._28cA1.uui-input-box {
  min-height: 30px;
  border-width: 1px;
}
._38f3X._18wzC.uui-button-box, ._38f3X._18wzC.uui-input-box {
  min-height: 36px;
  border-width: 1px;
}
._38f3X._3gmVI.uui-button-box, ._38f3X._3gmVI.uui-input-box {
  min-height: 42px;
  border-width: 1px;
}
._38f3X._1EGp-.uui-button-box, ._38f3X._1EGp-.uui-input-box {
  min-height: 48px;
  border-width: 1px;
}
._38f3X.slOZO.uui-button-box, ._38f3X.slOZO.uui-input-box {
  min-height: 60px;
  border-width: 1px;
}
._38f3X .uui-icon.-clickable {
  cursor: pointer;
}
._38f3X .uui-icon.-clickable.uui-enabled {
  fill: #6C6F80;
}
._38f3X .uui-icon.-clickable.uui-enabled:hover {
  fill: #474A59;
}
._38f3X .uui-icon.-clickable.uui-disabled {
  fill: #ACAFBF;
  cursor: default;
}
._38f3X .uui-icon-accept.-clickable.uui-enabled {
  fill: var(--uui-color-700);
}
._38f3X .uui-icon-accept.-clickable.uui-enabled:hover {
  fill: var(--uui-color-800);
}