/* Carbon scale */
/* Night scale */
._1Ag0M .hero-header, ._1Ag0M h1, ._1Ag0M h2, ._1Ag0M h3, ._1Ag0M h4, ._1Ag0M h5, ._1Ag0M h6 {
  margin: 0;
}
._1Ag0M .hero-header, ._1Ag0M h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1Ag0M .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1Ag0M h3, ._1Ag0M h4, ._1Ag0M h5, ._1Ag0M h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1Ag0M h1, ._1Ag0M h2, ._1Ag0M h3, ._1Ag0M h4, ._1Ag0M h5, ._1Ag0M h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1Ag0M h1 {
  font-size: 42px;
}
._1Ag0M h2 {
  font-size: 30px;
}
._1Ag0M h3 {
  font-size: 24px;
}
._1Ag0M h4 {
  font-size: 18px;
}
._1Ag0M h5 {
  font-size: 16px;
}
._1Ag0M h6 {
  font-size: 14px;
}

.R-I9O b, .R-I9O strong {
  color: #1D1E26;
}
.R-I9O ol, .R-I9O ul {
  padding: 0;
}
.R-I9O li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.R-I9O pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.R-I9O img {
  max-width: 100%;
}
.R-I9O figure {
  margin: 0;
}
.R-I9O figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.R-I9O p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1ODbr b, ._1ODbr strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1ODbr i, ._1ODbr em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1ODbr a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1ODbr a:hover {
  text-decoration: none;
  color: #007799;
}
._1ODbr code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3mDZ- {
  font-size: 16px;
}

._2K9lJ {
  font-size: 14px;
}

._1t7pO {
  font-size: 12px;
}

._64Own {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2oH5l {
  cursor: default;
  flex-shrink: 0;
  font-family: "Sans Regular", Arial, sans-serif;
}
._2oH5l .uui-count {
  font-family: "Sans Semibold", Arial, sans-serif;
  margin: auto;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 9px;
  padding-left: 6px;
  padding-right: 6px;
  min-width: 6px;
  text-align: center;
}
._2oH5l .uui-count:not(:first-child) {
  margin-left: 6px;
}
._2oH5l:focus {
  box-shadow: none;
}
._2oH5l:hover:not(.-clickable) {
  cursor: default;
}
._2oH5l._3oQ5H {
  min-width: 12px;
  padding-left: 1px;
  padding-right: 1px;
  padding: 0 2px;
}
._2oH5l._3oQ5H .uui-caption, ._2oH5l._3oQ5H .uui-input {
  padding-left: 2px;
  padding-right: 2px;
}
._2oH5l._3oQ5H .uui-count {
  height: 10px;
  line-height: 10px;
  font-size: 8px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}
._2oH5l._3oQ5H .uui-count:not(:first-child) {
  margin-left: 3px;
}
._2oH5l._3oQ5H .uui-caption, ._2oH5l._3oQ5H .uui-input {
  line-height: 10px;
  font-size: 10px;
  padding: 1px 3px;
}
._2oH5l._3oQ5H.uui-button-box, ._2oH5l._3oQ5H.uui-input-box {
  min-height: 12px;
  border-width: 1px;
}
._2oH5l._3oQ5H .uui-icon svg {
  height: 10px;
  max-width: 10px;
}
._2oH5l._3oQ5H.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._3oQ5H.LTXrF .uui-caption {
  margin-left: 17px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._3oQ5H.LTXrF::before {
  width: 8px;
  height: 8px;
  top: 2px;
  left: 1px;
}
._2oH5l.Ot9Ps {
  min-width: 18px;
  padding-left: 2px;
  padding-right: 2px;
}
._2oH5l.Ot9Ps .uui-caption {
  padding-left: 3px;
  padding-right: 3px;
}
._2oH5l.Ot9Ps.uui-button-box {
  min-height: 18px;
  border-width: 1px;
}
._2oH5l.Ot9Ps .uui-count {
  height: 12px;
  line-height: 12px;
  font-size: 8px;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;
}
._2oH5l.Ot9Ps .uui-count:not(:first-child) {
  margin-left: 3px;
}
._2oH5l.Ot9Ps .uui-caption {
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 12px;
  font-size: 10px;
}
._2oH5l.Ot9Ps.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l.Ot9Ps.LTXrF .uui-caption {
  margin-left: 17px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l.Ot9Ps.LTXrF::before {
  width: 8px;
  height: 8px;
  top: 4px;
  left: 1px;
}
._2oH5l._2KuR8 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
._2oH5l._2KuR8 .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2oH5l._2KuR8.uui-button-box {
  min-height: 24px;
  border-width: 1px;
}
._2oH5l._2KuR8 .uui-caption {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 12px;
  font-size: 12px;
}
._2oH5l._2KuR8.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._2KuR8.LTXrF .uui-caption {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._2KuR8.LTXrF::before {
  width: 12px;
  height: 12px;
  top: 5px;
  left: 2px;
}
._2oH5l._1RpzQ {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
._2oH5l._1RpzQ .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2oH5l._1RpzQ.uui-button-box {
  min-height: 30px;
  border-width: 1px;
}
._2oH5l._1RpzQ .uui-caption {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 18px;
  font-size: 14px;
}
._2oH5l._1RpzQ.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._1RpzQ.LTXrF .uui-caption {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._1RpzQ.LTXrF::before {
  width: 12px;
  height: 12px;
  top: 8px;
  left: 2px;
}
._2oH5l._2C9HW {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
}
._2oH5l._2C9HW .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2oH5l._2C9HW.uui-button-box {
  min-height: 36px;
  border-width: 1px;
}
._2oH5l._2C9HW .uui-caption {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
  font-size: 14px;
}
._2oH5l._2C9HW.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._2C9HW.LTXrF .uui-caption {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._2C9HW.LTXrF::before {
  width: 12px;
  height: 12px;
  top: 11px;
  left: 2px;
}
._2oH5l._12AJo {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
}
._2oH5l._12AJo .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2oH5l._12AJo.uui-button-box {
  min-height: 42px;
  border-width: 1px;
}
._2oH5l._12AJo .uui-count {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}
._2oH5l._12AJo .uui-caption {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 24px;
  font-size: 16px;
}
._2oH5l._12AJo.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._12AJo.LTXrF .uui-caption {
  margin-left: 29px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._12AJo.LTXrF::before {
  width: 16px;
  height: 16px;
  top: 12px;
  left: 3px;
}
._2oH5l._3zI5o {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
}
._2oH5l._3zI5o .uui-caption {
  padding-left: 6px;
  padding-right: 6px;
}
._2oH5l._3zI5o.uui-button-box {
  min-height: 48px;
  border-width: 1px;
}
._2oH5l._3zI5o .uui-count {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}
._2oH5l._3zI5o .uui-caption {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 24px;
  font-size: 16px;
}
._2oH5l._3zI5o.LTXrF {
  padding-left: 0;
  padding-right: 0;
}
._2oH5l._3zI5o.LTXrF .uui-caption {
  margin-left: 29px;
  padding-right: 0;
  padding-left: 0;
}
._2oH5l._3zI5o.LTXrF::before {
  width: 16px;
  height: 16px;
  top: 15px;
  left: 3px;
}
._2oH5l.MTCaR {
  background-color: var(--uui-color-700);
  color: var(--uui-contrast-color-700);
  fill: var(--uui-contrast-color-700);
  border-color: var(--uui-color-700);
}
._2oH5l.MTCaR .uui-count {
  background-color: #FFFFFF;
  color: #6C6F80;
}
._2oH5l._8EuUV {
  background-color: #FFFFFF;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._2oH5l._24Kf3 {
  background-color: var(--uui-color-500);
  color: var(--uui-color-900);
  fill: var(--uui-color-900);
  border-color: var(--uui-color-600);
}
._2oH5l._24Kf3 .uui-count {
  background-color: #FFFFFF;
  color: #6C6F80;
}
._2oH5l.LTXrF {
  background-color: transparent;
  color: #303240;
  fill: #303240;
  border-color: transparent;
}
._2oH5l.LTXrF::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--uui-color-700);
}
._2oH5l.-P6AR {
  background-color: transparent;
  color: var(--uui-color-700);
  fill: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._2oH5l._10PMA._3oQ5H {
  border-radius: 7px;
}
._2oH5l._10PMA.Ot9Ps {
  border-radius: 9px;
}
._2oH5l._10PMA._2KuR8 {
  border-radius: 12px;
}
._2oH5l._10PMA._1RpzQ {
  border-radius: 15px;
}
._2oH5l._10PMA._2C9HW {
  border-radius: 18px;
}
._2oH5l._10PMA._12AJo {
  border-radius: 21px;
}
._2oH5l._10PMA._3zI5o {
  border-radius: 24px;
}