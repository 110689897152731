/* Carbon scale */
/* Night scale */
.uui-thumb-vertical, .uui-thumb-horizontal {
  background: #CED0DB;
  border-radius: 1.5px;
}
.uui-thumb-vertical:hover, .uui-thumb-horizontal:hover {
  border-radius: 3px;
}
.uui-thumb-vertical:active, .uui-thumb-horizontal:active {
  border-radius: 3px;
  background: #6C6F80;
}

.uui-thumb-vertical {
  width: 3px;
  margin-left: 3px;
}
.uui-thumb-vertical:hover {
  width: 6px;
  margin-left: 0;
}
.uui-thumb-vertical:active {
  width: 6px;
  margin-left: 0;
}

.uui-thumb-horizontal {
  height: 3px;
  margin-top: 3px;
}
.uui-thumb-horizontal:hover {
  height: 6px;
  margin-top: 0;
}
.uui-thumb-horizontal:active {
  height: 6px;
  margin-top: 0;
}

.tEm-m.uui-shadow-top::before {
  background: linear-gradient(to bottom, rgba(44, 47, 60, 0.5) 0%, rgba(44, 47, 60, 0) 100%);
}
.tEm-m.uui-shadow-bottom::after {
  background: linear-gradient(to top, rgba(44, 47, 60, 0.5) 0%, rgba(44, 47, 60, 0) 100%);
}

._1FmRC.uui-shadow-top::before {
  background: linear-gradient(to bottom, rgba(44, 47, 60, 0.15) 0%, rgba(44, 47, 60, 0) 100%);
}
._1FmRC.uui-shadow-bottom::after {
  background: linear-gradient(to top, rgba(44, 47, 60, 0.15) 0%, rgba(44, 47, 60, 0) 100%);
}