/* Carbon scale */
/* Night scale */
._39UVF .hero-header, ._39UVF h1, ._39UVF h2, ._39UVF h3, ._39UVF h4, ._39UVF h5, ._39UVF h6 {
  margin: 0;
}
._39UVF .hero-header, ._39UVF h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._39UVF .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._39UVF h3, ._39UVF h4, ._39UVF h5, ._39UVF h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._39UVF h1, ._39UVF h2, ._39UVF h3, ._39UVF h4, ._39UVF h5, ._39UVF h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._39UVF h1 {
  font-size: 42px;
}
._39UVF h2 {
  font-size: 30px;
}
._39UVF h3 {
  font-size: 24px;
}
._39UVF h4 {
  font-size: 18px;
}
._39UVF h5 {
  font-size: 16px;
}
._39UVF h6 {
  font-size: 14px;
}

._2gJIg b, ._2gJIg strong {
  color: #1D1E26;
}
._2gJIg ol, ._2gJIg ul {
  padding: 0;
}
._2gJIg li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2gJIg pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2gJIg img {
  max-width: 100%;
}
._2gJIg figure {
  margin: 0;
}
._2gJIg figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2gJIg p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1gXUy b, ._1gXUy strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1gXUy i, ._1gXUy em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1gXUy a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1gXUy a:hover {
  text-decoration: none;
  color: #007799;
}
._1gXUy code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._30e2U {
  font-size: 16px;
}

._3XtXJ {
  font-size: 14px;
}

._2JdN8 {
  font-size: 12px;
}

._3CoFa {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._39_je {
  padding: 17px 0 0 0;
}

._1_ekZ {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 12px;
  color: #ACAFBF;
  background: #1D1E26;
  padding: 0 14px 0 18px;
}

._3rqvW {
  position: absolute;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #474A59;
  z-index: -1;
  margin-top: 11px;
}