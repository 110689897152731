.rF_8l.uui-yearselection-container .uui-yearselection-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.rF_8l.uui-yearselection-container .uui-yearselection-year-container {
  display: flex;
  flex-direction: column;
}
.rF_8l.uui-yearselection-container .uui-yearselection-year-container > * {
  display: flex;
}
.rF_8l.uui-yearselection-container .uui-yearselection-year {
  display: flex;
  justify-content: center;
  align-items: center;
}