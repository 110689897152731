/* Carbon scale */
/* Night scale */
._2xtS2 .hero-header, ._2xtS2 h1, ._2xtS2 h2, ._2xtS2 h3, ._2xtS2 h4, ._2xtS2 h5, ._2xtS2 h6 {
  margin: 0;
}
._2xtS2 .hero-header, ._2xtS2 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2xtS2 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2xtS2 h3, ._2xtS2 h4, ._2xtS2 h5, ._2xtS2 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2xtS2 h1, ._2xtS2 h2, ._2xtS2 h3, ._2xtS2 h4, ._2xtS2 h5, ._2xtS2 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2xtS2 h1 {
  font-size: 42px;
}
._2xtS2 h2 {
  font-size: 30px;
}
._2xtS2 h3 {
  font-size: 24px;
}
._2xtS2 h4 {
  font-size: 18px;
}
._2xtS2 h5 {
  font-size: 16px;
}
._2xtS2 h6 {
  font-size: 14px;
}

._19JvI b, ._19JvI strong {
  color: #1D1E26;
}
._19JvI ol, ._19JvI ul {
  padding: 0;
}
._19JvI li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._19JvI pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._19JvI img {
  max-width: 100%;
}
._19JvI figure {
  margin: 0;
}
._19JvI figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._19JvI p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3tEjR b, ._3tEjR strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3tEjR i, ._3tEjR em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3tEjR a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3tEjR a:hover {
  text-decoration: none;
  color: #007799;
}
._3tEjR code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3R4Bx {
  font-size: 16px;
}

._7VohR {
  font-size: 14px;
}

._1s4YS {
  font-size: 12px;
}

._10_vH {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1vK-Y {
  color: var(--uui-color-700);
}
._1vK-Y._2QPCq {
  font-family: "Oswald Regular", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}
._1vK-Y._1ca1z {
  font-family: "Sans Regular", Arial, sans-serif;
  font-weight: normal;
}
._1vK-Y._18tPG {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
._1vK-Y._2ma1Y {
  font-family: "Sans Light", Arial, sans-serif;
  font-weight: normal;
}
._1vK-Y.c7NDp {
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-weight: normal;
}
._1vK-Y b, ._1vK-Y strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1vK-Y i, ._1vK-Y em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1vK-Y a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1vK-Y a:hover {
  text-decoration: none;
  color: #007799;
}
._1vK-Y code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}