/* Carbon scale */
/* Night scale */
._1fzaY .hero-header, ._1fzaY h1, ._1fzaY h2, ._1fzaY h3, ._1fzaY h4, ._1fzaY h5, ._1fzaY h6 {
  margin: 0;
}
._1fzaY .hero-header, ._1fzaY h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1fzaY .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1fzaY h3, ._1fzaY h4, ._1fzaY h5, ._1fzaY h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1fzaY h1, ._1fzaY h2, ._1fzaY h3, ._1fzaY h4, ._1fzaY h5, ._1fzaY h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1fzaY h1 {
  font-size: 42px;
}
._1fzaY h2 {
  font-size: 30px;
}
._1fzaY h3 {
  font-size: 24px;
}
._1fzaY h4 {
  font-size: 18px;
}
._1fzaY h5 {
  font-size: 16px;
}
._1fzaY h6 {
  font-size: 14px;
}

.BV1QG b, .BV1QG strong {
  color: #1D1E26;
}
.BV1QG ol, .BV1QG ul {
  padding: 0;
}
.BV1QG li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.BV1QG pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.BV1QG img {
  max-width: 100%;
}
.BV1QG figure {
  margin: 0;
}
.BV1QG figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.BV1QG p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2efHW b, ._2efHW strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2efHW i, ._2efHW em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2efHW a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2efHW a:hover {
  text-decoration: none;
  color: #007799;
}
._2efHW code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2GhbM {
  font-size: 16px;
}

.Cuzql {
  font-size: 14px;
}

._2zZVk {
  font-size: 12px;
}

._3DGuz {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3usI6 {
  color: #474A59;
  box-shadow: none;
}
._3usI6.-clickable:hover {
  color: var(--uui-color-700);
}
._3usI6.-clickable:focus {
  box-shadow: none;
}

._7lSN6 {
  fill: #6C6F80;
}
._7lSN6.-clickable:focus {
  box-shadow: none;
}
._7lSN6.-clickable:hover, ._7lSN6.-clickable:active {
  fill: #6C6F80;
}

._1vLGx {
  padding-left: 0;
  padding-right: 0;
}
._1vLGx .uui-caption {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
}
._1vLGx:first-of-type {
  margin-right: 6px;
}
._1vLGx:last-of-type {
  margin-left: 6px;
}

._3kRiM {
  padding-left: 2px;
  padding-right: 2px;
}
._3kRiM .uui-caption {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
}
._3kRiM:first-of-type {
  margin-right: 6px;
}
._3kRiM:last-of-type {
  margin-left: 6px;
}