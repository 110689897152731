/* Carbon scale */
/* Night scale */
._54Zrr .hero-header, ._54Zrr h1, ._54Zrr h2, ._54Zrr h3, ._54Zrr h4, ._54Zrr h5, ._54Zrr h6 {
  margin: 0;
}
._54Zrr .hero-header, ._54Zrr h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._54Zrr .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._54Zrr h3, ._54Zrr h4, ._54Zrr h5, ._54Zrr h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._54Zrr h1, ._54Zrr h2, ._54Zrr h3, ._54Zrr h4, ._54Zrr h5, ._54Zrr h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._54Zrr h1 {
  font-size: 42px;
}
._54Zrr h2 {
  font-size: 30px;
}
._54Zrr h3 {
  font-size: 24px;
}
._54Zrr h4 {
  font-size: 18px;
}
._54Zrr h5 {
  font-size: 16px;
}
._54Zrr h6 {
  font-size: 14px;
}

.NwXH3 b, .NwXH3 strong {
  color: #1D1E26;
}
.NwXH3 ol, .NwXH3 ul {
  padding: 0;
}
.NwXH3 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.NwXH3 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.NwXH3 img {
  max-width: 100%;
}
.NwXH3 figure {
  margin: 0;
}
.NwXH3 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.NwXH3 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3T-B9 b, ._3T-B9 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3T-B9 i, ._3T-B9 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3T-B9 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3T-B9 a:hover {
  text-decoration: none;
  color: #007799;
}
._3T-B9 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2wPNu {
  font-size: 16px;
}

._2JFBB {
  font-size: 14px;
}

._23iDm {
  font-size: 12px;
}

.PXSs5 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2pfTo {
  position: relative;
  padding: 0 24px 0 0;
  width: 100%;
  box-sizing: border-box;
  touch-action: auto;
}
._2pfTo.XywYk:not(:hover) ._2pGIo {
  visibility: hidden;
}
._2pfTo:hover {
  box-shadow: 0 0 1px #ACAFBF;
}
._2pfTo:hover, ._2pfTo:hover ._15Pju, ._2pfTo:hover ._2pGIo {
  background-color: #F5F6FA;
}
._2pfTo ._1TWo4 {
  height: 12px;
  background-position: right;
  background-repeat: repeat-y;
  padding: 6px 0 6px 24px;
  background-position-y: -2px;
  opacity: 1;
  touch-action: none;
}
._2pfTo ._1TWo4._3Uqrh {
  opacity: 0.3;
  touch-action: auto;
}
._2pfTo.uui-drag-ghost {
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(51, 51, 51, 0.2);
}