/* Carbon scale */
/* Night scale */
._1Ot90 .hero-header, ._1Ot90 h1, ._1Ot90 h2, ._1Ot90 h3, ._1Ot90 h4, ._1Ot90 h5, ._1Ot90 h6 {
  margin: 0;
}
._1Ot90 .hero-header, ._1Ot90 h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1Ot90 .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1Ot90 h3, ._1Ot90 h4, ._1Ot90 h5, ._1Ot90 h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1Ot90 h1, ._1Ot90 h2, ._1Ot90 h3, ._1Ot90 h4, ._1Ot90 h5, ._1Ot90 h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1Ot90 h1 {
  font-size: 42px;
}
._1Ot90 h2 {
  font-size: 30px;
}
._1Ot90 h3 {
  font-size: 24px;
}
._1Ot90 h4 {
  font-size: 18px;
}
._1Ot90 h5 {
  font-size: 16px;
}
._1Ot90 h6 {
  font-size: 14px;
}

._3MOfY b, ._3MOfY strong {
  color: #1D1E26;
}
._3MOfY ol, ._3MOfY ul {
  padding: 0;
}
._3MOfY li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3MOfY pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3MOfY img {
  max-width: 100%;
}
._3MOfY figure {
  margin: 0;
}
._3MOfY figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3MOfY p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.lpzHf b, .lpzHf strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.lpzHf i, .lpzHf em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.lpzHf a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.lpzHf a:hover {
  text-decoration: none;
  color: #007799;
}
.lpzHf code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3jsc_ {
  font-size: 16px;
}

._1Mgf4 {
  font-size: 14px;
}

.OiGdz {
  font-size: 12px;
}

._2nFyg {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1ZJUA {
  --uui-dt-cell-background: transparent;
  --uui-dt-cell-border-width: 1px;
  --uui-dt-cell-border-color: transparent;
  --uui-dt-cell-editor-border-width: 1px;
  --uui-dt-cell-editor-hover-border-color: #6C6F80;
  --uui-dt-cell-editor-focus-border-color: #009ECC;
  --uui-dt-cell-editor-invalid-border-color: #FA4B4B;
}
._1ZJUA._28MZv {
  --uui-dt-cell-border-color: #E1E3EB;
}
._1ZJUA.uui-focus {
  --uui-dt-cell-editor-border-width:2px;
}
._1ZJUA._1XS6k._1Muai .uui-checkbox, ._1ZJUA._1XS6k._1Muai ._2h5wA, ._1ZJUA._1XS6k.Szwzt .uui-checkbox, ._1ZJUA._1XS6k.Szwzt ._2h5wA {
  margin-top: 6px;
}
._1ZJUA._1XS6k.x_KCs .uui-checkbox, ._1ZJUA._1XS6k.x_KCs ._2h5wA {
  margin-top: 9px;
}
._1ZJUA._1XS6k._2lM5W .uui-checkbox, ._1ZJUA._1XS6k._2lM5W ._2h5wA {
  margin-top: 12px;
}
._1ZJUA._1XS6k._38HFK .uui-checkbox, ._1ZJUA._1XS6k._38HFK ._2h5wA {
  margin-top: 15px;
}
._1ZJUA._1XS6k.EX3-J .uui-checkbox, ._1ZJUA._1XS6k.EX3-J ._2h5wA {
  margin-top: 21px;
}
._1ZJUA._3PQLK {
  align-self: center;
}
._1ZJUA._3u13L {
  padding: 0 12px;
}
._1ZJUA.lAyUU {
  padding: 0 24px;
}
._1ZJUA._3oUh- {
  padding-left: 12px;
}
._1ZJUA._15NaO {
  padding-left: 24px;
}
._1ZJUA._2O7zD {
  padding-right: 24px;
}
._1ZJUA.uui-invalid {
  --uui-dt-cell-editor-background: #FDE1E1;
}
._1ZJUA._3sVOF {
  --uui-dt-cell-editor-background: rgba(0, 158, 204, 0.1);
}
._1ZJUA._2-uiC {
  --uui-dt-cell-editor-background: rgba(103, 163, 0, 0.1);
}
._1ZJUA._1OA0E {
  --uui-dt-cell-editor-background: rgba(252, 170, 0, 0.1);
}
._1ZJUA._3NzJe {
  --uui-dt-cell-editor-background: rgba(250, 75, 75, 0.1);
}
._1ZJUA._1v8VE {
  --uui-dt-cell-editor-background: #FAFAFC;
}

._2Kfy4 {
  position: absolute;
  left: 2px;
  width: 6px;
  top: 3px;
  bottom: 2px;
  align-self: stretch;
  visibility: hidden;
}
.-draggable.uui-table-row:not(.uui-drop-accepted):hover ._2Kfy4 {
  visibility: visible;
}

._2DkEk {
  padding-right: 12px;
}

._33J8I {
  display: flex;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 12px;
  padding-right: 12px;
}

._2h5wA {
  margin: auto 0;
}
._2h5wA.uui-icon {
  position: relative;
  fill: #6C6F80;
}

._30n31.uui-icon {
  left: -2px;
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
}

._1G7hi.uui-icon {
  left: -4px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
}