._3qESv.uui-calendar-container .uui-calendar-content {
  display: flex;
  flex-direction: column;
}
._3qESv.uui-calendar-container .uui-calendar-weekdays-container {
  display: flex;
  justify-content: space-around;
}
._3qESv.uui-calendar-container .uui-calendar-days {
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
}
._3qESv.uui-calendar-container .uui-calendar-days > * {
  display: flex;
}