/* Carbon scale */
/* Night scale */
._3DMTY .hero-header, ._3DMTY h1, ._3DMTY h2, ._3DMTY h3, ._3DMTY h4, ._3DMTY h5, ._3DMTY h6 {
  margin: 0;
}
._3DMTY .hero-header, ._3DMTY h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3DMTY .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3DMTY h3, ._3DMTY h4, ._3DMTY h5, ._3DMTY h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3DMTY h1, ._3DMTY h2, ._3DMTY h3, ._3DMTY h4, ._3DMTY h5, ._3DMTY h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3DMTY h1 {
  font-size: 42px;
}
._3DMTY h2 {
  font-size: 30px;
}
._3DMTY h3 {
  font-size: 24px;
}
._3DMTY h4 {
  font-size: 18px;
}
._3DMTY h5 {
  font-size: 16px;
}
._3DMTY h6 {
  font-size: 14px;
}

._31aM5 b, ._31aM5 strong {
  color: #1D1E26;
}
._31aM5 ol, ._31aM5 ul {
  padding: 0;
}
._31aM5 li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._31aM5 pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._31aM5 img {
  max-width: 100%;
}
._31aM5 figure {
  margin: 0;
}
._31aM5 figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._31aM5 p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._3c1j9 b, ._3c1j9 strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._3c1j9 i, ._3c1j9 em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._3c1j9 a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._3c1j9 a:hover {
  text-decoration: none;
  color: #007799;
}
._3c1j9 code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2zJuj {
  font-size: 16px;
}

.jFZY3 {
  font-size: 14px;
}

.Fcabt {
  font-size: 12px;
}

._2aV2Q {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3vjZS {
  padding: 0 24px;
  background: none;
  border: 0;
  outline: none;
  font-family: "Sans Regular", Arial, sans-serif;
  line-height: 48px;
  flex-shrink: 0;
}
._3vjZS.uui-enabled {
  color: #FFFFFF;
  fill: #FFFFFF;
}
._3vjZS.uui-enabled.-clickable:hover {
  color: #009ECC;
  fill: #009ECC;
}
._3vjZS.uui-active {
  color: #009ECC;
}
._3vjZS .uui-icon {
  padding: 0 18px 0 24px;
}
._3vjZS._2VB68 {
  font-size: 18px;
}
._3vjZS._2yF6w {
  font-size: 14px;
  padding-left: 18px;
  margin-left: 54px;
  border-left: 1px #474A59 solid;
}
._3vjZS.Yo7cS, ._3vjZS.BrPSv {
  padding-left: 0;
}
._3vjZS.Yo7cS .uui-icon svg {
  height: 16px;
  max-width: 16px;
}
._3vjZS.Yo7cS .uui-icon {
  padding: 0 12px 0 20px;
}