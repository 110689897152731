._3q8QY {
  position: sticky;
  top: 0;
  isolation: isolate;
  z-index: 5;
}

._1duzi {
  margin-top: 1px;
  isolation: isolate;
}

._2gaXq .-scrolled-left .uui-scroll-shadow-left {
  opacity: 1;
}
._2gaXq .-scrolled-right .uui-scroll-shadow-right {
  opacity: 1;
}
._2gaXq .uui-scroll-shadow-top {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: -5px;
}
._2gaXq .uui-scroll-shadow-top-visible {
  display: block;
  height: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  transition: display 200ms;
}

.xgdMM {
  position: sticky;
  left: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.YuSBs {
  align-items: center;
}
._3zFtU {
  padding: 0;
  margin-top: 30px;
}