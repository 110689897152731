._1FEtj.uui-horizontal-direction > * {
  margin-left: 18px;
}
._1FEtj.uui-horizontal-direction :first-child {
  margin-left: 0;
}
._1FEtj.uui-vertical-direction > * {
  margin-bottom: 10px;
}
._1FEtj.uui-vertical-direction :last-child {
  margin-bottom: 0;
}