._3b16C.uui-horizontal-direction > * {
  margin-left: 12px;
}
._3b16C.uui-horizontal-direction :first-child {
  margin-left: 0;
}
._3b16C.uui-vertical-direction > * {
  margin-bottom: 12px;
}
._3b16C.uui-vertical-direction :last-child {
  margin-bottom: 0;
}