@font-face {
  font-family: "Oswald Regular";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald Bold";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald Light";
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.eot");
  src: url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.eot?#iefix") format("embedded-opentype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.woff") format("woff"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.ttf") format("truetype"), url("https://static.cdn.epam.com/projects/epm-uui/fonts/font-oswald/oswald-light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Regular";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Light";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Light.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Light.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Light.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Bold";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Bold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Semibold";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Semibold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Semibold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans Italic";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-It.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-It.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-It.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/RobotoCondensed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/RobotoMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Redacted";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.eot");
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.svg#filename") format("svg");
  font-display: block;
}
/* Carbon scale */
/* Night scale */
.w9rim .hero-header, .w9rim h1, .w9rim h2, .w9rim h3, .w9rim h4, .w9rim h5, .w9rim h6 {
  margin: 0;
}
.w9rim .hero-header, .w9rim h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.w9rim .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.w9rim h3, .w9rim h4, .w9rim h5, .w9rim h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.w9rim h1, .w9rim h2, .w9rim h3, .w9rim h4, .w9rim h5, .w9rim h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.w9rim h1 {
  font-size: 42px;
}
.w9rim h2 {
  font-size: 30px;
}
.w9rim h3 {
  font-size: 24px;
}
.w9rim h4 {
  font-size: 18px;
}
.w9rim h5 {
  font-size: 16px;
}
.w9rim h6 {
  font-size: 14px;
}

.RaJ1W ol, .RaJ1W ul {
  padding: 0;
}
.RaJ1W li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.RaJ1W pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.RaJ1W img {
  max-width: 100%;
}
.RaJ1W figure {
  margin: 0;
}
.RaJ1W figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.RaJ1W p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._10lAH b, ._10lAH strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._10lAH i, ._10lAH em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._10lAH a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._10lAH a:hover {
  text-decoration: none;
  color: #007799;
}
._10lAH code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3pN82 {
  font-size: 16px;
}

._2b1vl {
  font-size: 14px;
}

.dtRwJ {
  font-size: 12px;
}

._27uK7 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3NjuU {
  --uui-color-500: #E1F4FA;
  --uui-color-600: #C4EAF5;
  --uui-color-700: #009ECC;
  --uui-color-800: #008ABD;
  --uui-color-900: #0079AD;
  --uui-contrast-color-700: white;
}

._24NxG {
  --uui-color-500: #EBF3D8;
  --uui-color-600: #D6E6B2;
  --uui-color-700: #67A300;
  --uui-color-800: #558A00;
  --uui-color-900: #428024;
  --uui-contrast-color-700: white;
}

._37t8b {
  --uui-color-500: #FFEDC9;
  --uui-color-600: #FFDD96;
  --uui-color-700: #FCAA00;
  --uui-color-800: #F67E00;
  --uui-color-900: #E64C00;
  --uui-contrast-color-700: white;
}

._1jkmg {
  --uui-color-500: #FDE1E1;
  --uui-color-600: #FCC8C8;
  --uui-color-700: #FA4B4B;
  --uui-color-800: #CC2929;
  --uui-color-900: #B32424;
  --uui-contrast-color-700: white;
}

._1g4kt {
  --uui-color-500: #6C6E7A;
  --uui-color-600: #4F515C;
  --uui-color-700: #3D404D;
  --uui-color-800: #272933;
  --uui-color-900: #17181F;
  --uui-contrast-color-700: white;
}

.Eu4St {
  --uui-color-500: #ACAFBF;
  --uui-color-600: #6C6F80;
  --uui-color-700: #474A59;
  --uui-color-800: #303240;
  --uui-color-900: #1D1E26;
  --uui-contrast-color-700: white;
}

._2GWZl {
  --uui-color-500: #32B2FB;
  --uui-color-600: #2196F3;
  --uui-color-700: #0E74E2;
  --uui-color-800: #0453C7;
  --uui-color-900: #003399;
  --uui-contrast-color-700: white;
}

._3RCsL {
  --uui-color-500: #6362C9;
  --uui-color-600: #3F51B5;
  --uui-color-700: #2E3A9D;
  --uui-color-800: #21288D;
  --uui-color-900: #191980;
  --uui-contrast-color-700: white;
}

._2HxM7 {
  --uui-color-500: #F5527B;
  --uui-color-600: #E91E63;
  --uui-color-700: #CD0053;
  --uui-color-800: #B00052;
  --uui-color-900: #94004C;
  --uui-contrast-color-700: white;
}

._3GSev {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #FFFFFF;
  --uui-color-800: #e6e6e6;
  --uui-color-900: #cccccc;
  --uui-contrast-color-700: #474A59;
}

._1S7FH {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #FAFAFC;
  --uui-color-800: #dadae9;
  --uui-color-900: #babad6;
  --uui-contrast-color-700: #474A59;
}

._3TPnt {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #F5F6FA;
  --uui-color-800: #d3d7e9;
  --uui-color-900: #b1b9d8;
  --uui-contrast-color-700: #474A59;
}

._2Ljq5 {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #EBEDF5;
  --uui-color-800: #c9cee4;
  --uui-color-900: #a7b0d3;
  --uui-contrast-color-700: #474A59;
}

.aLi3R {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #E1E3EB;
  --uui-color-800: #c2c6d7;
  --uui-color-900: #a4aac2;
  --uui-contrast-color-700: #474A59;
}

.bPi_u {
  --uui-color-500: white;
  --uui-color-600: #ebecf1;
  --uui-color-700: #CED0DB;
  --uui-color-800: #b1b4c5;
  --uui-color-900: #9398b0;
  --uui-contrast-color-700: #474A59;
}

._2lIPR {
  --uui-color-500: #e6e7eb;
  --uui-color-600: #c9cbd5;
  --uui-color-700: #ACAFBF;
  --uui-color-800: #8f93a9;
  --uui-color-900: #727793;
  --uui-contrast-color-700: white;
}

._3kGw7 {
  --uui-color-500: #a2a4b0;
  --uui-color-600: #868999;
  --uui-color-700: #6C6F80;
  --uui-color-800: #555764;
  --uui-color-900: #3d3f49;
  --uui-contrast-color-700: white;
}

._1LH_q {
  --uui-color-500: #757a91;
  --uui-color-600: #5e6275;
  --uui-color-700: #474A59;
  --uui-color-800: #30323d;
  --uui-color-900: #1a1b20;
  --uui-contrast-color-700: white;
}

._1daLp {
  --uui-color-500: #5c607a;
  --uui-color-600: #46495d;
  --uui-color-700: #303240;
  --uui-color-800: #1a1b23;
  --uui-color-900: #040406;
  --uui-contrast-color-700: white;
}

.tQzNE {
  --uui-color-500: #494c60;
  --uui-color-600: #333543;
  --uui-color-700: #1D1E26;
  --uui-color-800: #070709;
  --uui-color-900: black;
  --uui-contrast-color-700: white;
}

/*
    Row z-indexes layout.

    Left fixed: 5
    Center
    Right fixed: 5

    Cell:
        Overlay
        * borders can overlay cell on top, impossible to overlay bottom cells because position: sticky creates isolation context:
            Hover: 1
            Focus: 2
            Invalid: 3

    DropMarker: 10
*/
.HFkfR {
  position: relative;
  display: flex;
  align-items: stretch;
  background: var(--uui-dt-row-background);
}

._1e-qa {
  display: flex;
  border-bottom: var(--uui-dt-row-border-width) solid var(--uui-dt-row-border-color);
  background: var(--uui-dt-row-background);
  background-clip: content-box;
  box-sizing: border-box;
}

._3xr1N {
  position: relative;
  align-items: stretch;
}

._2pCJL, .jmPm_ {
  position: sticky;
  z-index: 5;
}

._2pCJL {
  left: 0;
  border-right: 1px solid transparent;
}

.jmPm_ {
  right: 0;
}

.uui-scroll-shadow-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 5px;
  pointer-events: none;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
}

.uui-scroll-shadow-right {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 5px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
}