.GLLne.uui-slider-container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
}
.GLLne.uui-slider-container .uui-slider-handle {
  position: absolute;
  left: 0;
}
.GLLne.uui-slider-container .uui-slider-handle:focus {
  outline-offset: 1px;
  outline-color: var(--uui-color-700);
}
.GLLne.uui-slider-container .uui-slider {
  pointer-events: none;
}
.GLLne.uui-slider-container .uui-slider-filled {
  position: absolute;
  pointer-events: none;
}
.GLLne.uui-slider-container .uui-slider-scale {
  position: absolute;
}
.GLLne.uui-slider-container .uui-slider-scale-number {
  position: absolute;
}
.GLLne.uui-slider-container .uui-slider-scale-dot {
  position: absolute;
}