/* Carbon scale */
/* Night scale */
._1NCGj .hero-header, ._1NCGj h1, ._1NCGj h2, ._1NCGj h3, ._1NCGj h4, ._1NCGj h5, ._1NCGj h6 {
  margin: 0;
}
._1NCGj .hero-header, ._1NCGj h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1NCGj .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1NCGj h3, ._1NCGj h4, ._1NCGj h5, ._1NCGj h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1NCGj h1, ._1NCGj h2, ._1NCGj h3, ._1NCGj h4, ._1NCGj h5, ._1NCGj h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1NCGj h1 {
  font-size: 42px;
}
._1NCGj h2 {
  font-size: 30px;
}
._1NCGj h3 {
  font-size: 24px;
}
._1NCGj h4 {
  font-size: 18px;
}
._1NCGj h5 {
  font-size: 16px;
}
._1NCGj h6 {
  font-size: 14px;
}

._2GSdN b, ._2GSdN strong {
  color: #1D1E26;
}
._2GSdN ol, ._2GSdN ul {
  padding: 0;
}
._2GSdN li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2GSdN pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2GSdN img {
  max-width: 100%;
}
._2GSdN figure {
  margin: 0;
}
._2GSdN figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2GSdN p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._2S5FW b, ._2S5FW strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2S5FW i, ._2S5FW em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2S5FW a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2S5FW a:hover {
  text-decoration: none;
  color: #007799;
}
._2S5FW code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3tEG6 {
  font-size: 16px;
}

._2QqHN {
  font-size: 14px;
}

._2kW7W {
  font-size: 12px;
}

.w-kmI {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.qnlUt {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

._1dU3s {
  position: absolute;
  pointer-events: none;
}

.AklCB {
  top: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #009ECC;
}

._28HAy {
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #009ECC;
}

._345E8 {
  top: 0;
  bottom: 0;
  left: -1px;
  width: 2px;
  background-color: #009ECC;
}

._1i5iS {
  top: 0;
  bottom: 0;
  right: -1px;
  width: 2px;
  background-color: #009ECC;
}

._2BK7h {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #009ECC;
  background-color: rgba(255, 255, 255, 0.5);
}