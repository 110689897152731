/* Carbon scale */
/* Night scale */
._1Xa-z .hero-header, ._1Xa-z h1, ._1Xa-z h2, ._1Xa-z h3, ._1Xa-z h4, ._1Xa-z h5, ._1Xa-z h6 {
  margin: 0;
}
._1Xa-z .hero-header, ._1Xa-z h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._1Xa-z .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._1Xa-z h3, ._1Xa-z h4, ._1Xa-z h5, ._1Xa-z h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._1Xa-z h1, ._1Xa-z h2, ._1Xa-z h3, ._1Xa-z h4, ._1Xa-z h5, ._1Xa-z h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._1Xa-z h1 {
  font-size: 42px;
}
._1Xa-z h2 {
  font-size: 30px;
}
._1Xa-z h3 {
  font-size: 24px;
}
._1Xa-z h4 {
  font-size: 18px;
}
._1Xa-z h5 {
  font-size: 16px;
}
._1Xa-z h6 {
  font-size: 14px;
}

._3qaXC b, ._3qaXC strong {
  color: #1D1E26;
}
._3qaXC ol, ._3qaXC ul {
  padding: 0;
}
._3qaXC li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3qaXC pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3qaXC img {
  max-width: 100%;
}
._3qaXC figure {
  margin: 0;
}
._3qaXC figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3qaXC p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.AtlAp b, .AtlAp strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.AtlAp i, .AtlAp em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.AtlAp a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.AtlAp a:hover {
  text-decoration: none;
  color: #007799;
}
.AtlAp code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2_MW5 {
  font-size: 16px;
}

._21UUx {
  font-size: 14px;
}

._3JRJd {
  font-size: 12px;
}

.fmTrC {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1wrzs {
  height: 60px;
  background-color: #303240;
  fill: #ACAFBF;
  padding: 0 24px;
  flex-shrink: 0;
  width: auto;
}
._1wrzs .uui-input {
  color: #E1E3EB;
  font-family: "Sans Regular", Arial, sans-serif;
}
._1wrzs .uui-input::placeholder {
  color: #ACAFBF;
}
._1wrzs .uui-caption,
._1wrzs .uui-input {
  padding: 18px 16px;
  line-height: 24px;
  font-size: 14px;
}
._1wrzs .uui-icon svg {
  height: 16px;
  max-width: 16px;
}
._1wrzs .uui-icon-cancel svg {
  height: 24px;
  max-width: 24px;
}
._1wrzs .uui-icon-cancel:hover {
  cursor: pointer;
}