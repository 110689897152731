/* Carbon scale */
/* Night scale */
._2lDYE .hero-header, ._2lDYE h1, ._2lDYE h2, ._2lDYE h3, ._2lDYE h4, ._2lDYE h5, ._2lDYE h6 {
  margin: 0;
}
._2lDYE .hero-header, ._2lDYE h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2lDYE .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2lDYE h3, ._2lDYE h4, ._2lDYE h5, ._2lDYE h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2lDYE h1, ._2lDYE h2, ._2lDYE h3, ._2lDYE h4, ._2lDYE h5, ._2lDYE h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2lDYE h1 {
  font-size: 42px;
}
._2lDYE h2 {
  font-size: 30px;
}
._2lDYE h3 {
  font-size: 24px;
}
._2lDYE h4 {
  font-size: 18px;
}
._2lDYE h5 {
  font-size: 16px;
}
._2lDYE h6 {
  font-size: 14px;
}

._238Zi b, ._238Zi strong {
  color: #1D1E26;
}
._238Zi ol, ._238Zi ul {
  padding: 0;
}
._238Zi li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._238Zi pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._238Zi img {
  max-width: 100%;
}
._238Zi figure {
  margin: 0;
}
._238Zi figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._238Zi p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1WEam b, ._1WEam strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1WEam i, ._1WEam em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1WEam a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1WEam a:hover {
  text-decoration: none;
  color: #007799;
}
._1WEam code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1NY9S {
  font-size: 16px;
}

._2ETq3 {
  font-size: 14px;
}

._3oYJc {
  font-size: 12px;
}

._2TvO9 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

.hU1KZ {
  fill: var(--uui-color-700);
}
.hU1KZ .uui-rating-star {
  padding: 0 1px;
}
.hU1KZ .uui-rating-filled-star-container svg {
  stroke-width: 0.1px;
}
.hU1KZ .uui-rating-filled-star-container svg {
  stroke: var(--uui-color-700);
}
.hU1KZ.olnVt svg {
  width: 18px;
  height: 18px;
}
.hU1KZ._2zBW4 svg {
  width: 24px;
  height: 24px;
}
.hU1KZ.nHd46 svg {
  width: 30px;
  height: 30px;
}
.hU1KZ.uui-disabled .uui-rating-star {
  fill: #E1E3EB;
  cursor: default;
}
.hU1KZ.uui-invalid .uui-rating-star {
  fill: #FA4B4B;
}

._3SsTD {
  pointer-events: none;
}