/* Carbon scale */
/* Night scale */
._3W7Zs {
  --uui-color-500: #E1F4FA;
  --uui-color-600: #C4EAF5;
  --uui-color-700: #009ECC;
  --uui-color-800: #008ABD;
  --uui-color-900: #0079AD;
  --uui-contrast-color-700: white;
}

._10quE {
  --uui-color-500: #EBF3D8;
  --uui-color-600: #D6E6B2;
  --uui-color-700: #67A300;
  --uui-color-800: #558A00;
  --uui-color-900: #428024;
  --uui-contrast-color-700: white;
}

._1pnhq {
  --uui-color-500: #FFEDC9;
  --uui-color-600: #FFDD96;
  --uui-color-700: #FCAA00;
  --uui-color-800: #F67E00;
  --uui-color-900: #E64C00;
  --uui-contrast-color-700: #303240;
}

._37dz8 {
  --uui-color-500: #FDE1E1;
  --uui-color-600: #FCC8C8;
  --uui-color-700: #FA4B4B;
  --uui-color-800: #CC2929;
  --uui-color-900: #B32424;
  --uui-contrast-color-700: white;
}

._3ZrGW {
  --uui-color-500: #6C6E7A;
  --uui-color-600: #4F515C;
  --uui-color-700: #3D404D;
  --uui-color-800: #272933;
  --uui-color-900: #17181F;
  --uui-contrast-color-700: white;
}

.N9wnv {
  --uui-color-500: #ACAFBF;
  --uui-color-600: #6C6F80;
  --uui-color-700: #474A59;
  --uui-color-800: #303240;
  --uui-color-900: #1D1E26;
  --uui-contrast-color-700: white;
}

._2_fkR {
  --uui-color-500: #32B2FB;
  --uui-color-600: #2196F3;
  --uui-color-700: #0E74E2;
  --uui-color-800: #0453C7;
  --uui-color-900: #003399;
  --uui-contrast-color-700: white;
}

._1Jl8I {
  --uui-color-500: #6362C9;
  --uui-color-600: #3F51B5;
  --uui-color-700: #2E3A9D;
  --uui-color-800: #21288D;
  --uui-color-900: #191980;
  --uui-contrast-color-700: white;
}

.Y0FCb {
  --uui-color-500: #F5527B;
  --uui-color-600: #E91E63;
  --uui-color-700: #CD0053;
  --uui-color-800: #B00052;
  --uui-color-900: #94004C;
  --uui-contrast-color-700: white;
}

._9_RMI {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #FFFFFF;
  --uui-color-800: #e6e6e6;
  --uui-color-900: #cccccc;
  --uui-contrast-color-700: #303240;
}

._1VSYR {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #FAFAFC;
  --uui-color-800: #dadae9;
  --uui-color-900: #babad6;
  --uui-contrast-color-700: #303240;
}

.wT8gN {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #F5F6FA;
  --uui-color-800: #d3d7e9;
  --uui-color-900: #b1b9d8;
  --uui-contrast-color-700: #303240;
}

._3n_Aa {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #EBEDF5;
  --uui-color-800: #c9cee4;
  --uui-color-900: #a7b0d3;
  --uui-contrast-color-700: #303240;
}

._37iDY {
  --uui-color-500: white;
  --uui-color-600: white;
  --uui-color-700: #E1E3EB;
  --uui-color-800: #c2c6d7;
  --uui-color-900: #a4aac2;
  --uui-contrast-color-700: #303240;
}

._3roOR {
  --uui-color-500: white;
  --uui-color-600: #ebecf1;
  --uui-color-700: #CED0DB;
  --uui-color-800: #b1b4c5;
  --uui-color-900: #9398b0;
  --uui-contrast-color-700: #303240;
}

._2ZaVX {
  --uui-color-500: #e6e7eb;
  --uui-color-600: #c9cbd5;
  --uui-color-700: #ACAFBF;
  --uui-color-800: #8f93a9;
  --uui-color-900: #727793;
  --uui-contrast-color-700: white;
}

._3sx99 {
  --uui-color-500: #a2a4b0;
  --uui-color-600: #868999;
  --uui-color-700: #6C6F80;
  --uui-color-800: #555764;
  --uui-color-900: #3d3f49;
  --uui-contrast-color-700: white;
}

._14Uji {
  --uui-color-500: #757a91;
  --uui-color-600: #5e6275;
  --uui-color-700: #474A59;
  --uui-color-800: #30323d;
  --uui-color-900: #1a1b20;
  --uui-contrast-color-700: white;
}

._3gxIU {
  --uui-color-500: #5c607a;
  --uui-color-600: #46495d;
  --uui-color-700: #303240;
  --uui-color-800: #1a1b23;
  --uui-color-900: #040406;
  --uui-contrast-color-700: white;
}

._3CS_c {
  --uui-color-500: #494c60;
  --uui-color-600: #333543;
  --uui-color-700: #1D1E26;
  --uui-color-800: #070709;
  --uui-color-900: black;
  --uui-contrast-color-700: white;
}