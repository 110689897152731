/* Carbon scale */
/* Night scale */
._3ayvC .hero-header, ._3ayvC h1, ._3ayvC h2, ._3ayvC h3, ._3ayvC h4, ._3ayvC h5, ._3ayvC h6 {
  margin: 0;
}
._3ayvC .hero-header, ._3ayvC h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._3ayvC .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._3ayvC h3, ._3ayvC h4, ._3ayvC h5, ._3ayvC h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._3ayvC h1, ._3ayvC h2, ._3ayvC h3, ._3ayvC h4, ._3ayvC h5, ._3ayvC h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._3ayvC h1 {
  font-size: 42px;
}
._3ayvC h2 {
  font-size: 30px;
}
._3ayvC h3 {
  font-size: 24px;
}
._3ayvC h4 {
  font-size: 18px;
}
._3ayvC h5 {
  font-size: 16px;
}
._3ayvC h6 {
  font-size: 14px;
}

._3B-WV b, ._3B-WV strong {
  color: #1D1E26;
}
._3B-WV ol, ._3B-WV ul {
  padding: 0;
}
._3B-WV li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3B-WV pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3B-WV img {
  max-width: 100%;
}
._3B-WV figure {
  margin: 0;
}
._3B-WV figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3B-WV p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._308Dn b, ._308Dn strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._308Dn i, ._308Dn em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._308Dn a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._308Dn a:hover {
  text-decoration: none;
  color: #007799;
}
._308Dn code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._2mBuF {
  font-size: 16px;
}

.TPSB5 {
  font-size: 14px;
}

._17caO {
  font-size: 12px;
}

._259V1 {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1gng-.uui-timepicker-container {
  display: flex;
  padding: 8px;
}
._1gng-.uui-timepicker-container .uui-timepicker-input.uui-input-box {
  border: 1px solid #6C6F80;
  width: 36px;
  height: 36px;
}
._1gng-.uui-timepicker-container .uui-timepicker-input > input {
  text-align: center;
  color: #474A59;
  font-family: "Sans Regular", Arial, sans-serif;
}
._1gng-.uui-timepicker-container .uui-timepicker-item {
  position: relative;
  padding: 5px;
}
._1gng-.uui-timepicker-container .uui-timepicker-item:first-child::after {
  color: #6C6F80;
  font-size: 20px;
  content: ":";
  position: absolute;
  top: 33px;
  left: 45px;
}
._1gng-.uui-timepicker-container ._2ZXcj {
  align-items: center;
  fill: #6C6F80;
}
._1gng-.uui-timepicker-container ._2ZXcj:hover {
  cursor: pointer;
}
._1gng-.uui-timepicker-container .uui-timepicker-icon-up {
  align-items: center;
  fill: #6C6F80;
  transform: rotate(180deg);
}
._1gng-.uui-timepicker-container .uui-timepicker-icon-down {
  align-items: center;
  fill: #6C6F80;
}
._1gng- .uui-dropdown-body {
  align-items: center;
}

._1iwCP {
  width: 100%;
}