/* Carbon scale */
/* Night scale */
._2gRgn .hero-header, ._2gRgn h1, ._2gRgn h2, ._2gRgn h3, ._2gRgn h4, ._2gRgn h5, ._2gRgn h6 {
  margin: 0;
}
._2gRgn .hero-header, ._2gRgn h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._2gRgn .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._2gRgn h3, ._2gRgn h4, ._2gRgn h5, ._2gRgn h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._2gRgn h1, ._2gRgn h2, ._2gRgn h3, ._2gRgn h4, ._2gRgn h5, ._2gRgn h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._2gRgn h1 {
  font-size: 42px;
}
._2gRgn h2 {
  font-size: 30px;
}
._2gRgn h3 {
  font-size: 24px;
}
._2gRgn h4 {
  font-size: 18px;
}
._2gRgn h5 {
  font-size: 16px;
}
._2gRgn h6 {
  font-size: 14px;
}

._3x3wB b, ._3x3wB strong {
  color: #1D1E26;
}
._3x3wB ol, ._3x3wB ul {
  padding: 0;
}
._3x3wB li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3x3wB pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3x3wB img {
  max-width: 100%;
}
._3x3wB figure {
  margin: 0;
}
._3x3wB figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3x3wB p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._25MeX b, ._25MeX strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._25MeX i, ._25MeX em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._25MeX a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._25MeX a:hover {
  text-decoration: none;
  color: #007799;
}
._25MeX code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._5NOx9 {
  font-size: 16px;
}

._3Ks8J {
  font-size: 14px;
}

._2Y-9w {
  font-size: 12px;
}

._38-BG {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2RpsI._327cg .uui-switch-body {
  height: 12px;
  width: 24px;
}
._2RpsI._327cg .uui-switch-toggler {
  height: 10px;
  width: 10px;
}
._2RpsI._327cg .uui-input-label {
  font-size: 12px;
  letter-spacing: 0.2px;
}
._2RpsI._327cg .uui-checked .uui-switch-toggler {
  transform: translateX(12px);
}
._2RpsI._3qg6K .uui-switch-body {
  height: 18px;
  width: 36px;
}
._2RpsI._3qg6K .uui-switch-toggler {
  height: 16px;
  width: 16px;
}
._2RpsI._3qg6K .uui-input-label {
  font-size: 14px;
  letter-spacing: 0.1px;
}
._2RpsI._3qg6K .uui-checked .uui-switch-toggler {
  transform: translateX(18px);
}
._2RpsI._1HD21 .uui-switch-body {
  height: 24px;
  width: 48px;
}
._2RpsI._1HD21 .uui-switch-toggler {
  height: 22px;
  width: 22px;
}
._2RpsI._1HD21 .uui-input-label {
  font-size: 14px;
  letter-spacing: 0.1px;
}
._2RpsI._1HD21 .uui-checked .uui-switch-toggler {
  transform: translateX(24px);
}
._2RpsI .uui-switch-body {
  background-color: #CED0DB;
  border: 1px solid #CED0DB;
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
}
._2RpsI .uui-switch-body:hover, ._2RpsI .uui-switch-body:focus-within {
  background-color: #ACAFBF;
  border-color: #ACAFBF;
}
._2RpsI .uui-switch-body:active {
  background-color: #6C6F80;
  border-color: #6C6F80;
}
._2RpsI .uui-switch-toggler {
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: 0.1s transform cubic-bezier(0.4, 0, 0.2, 1);
}
._2RpsI .uui-input-label {
  font-family: "Sans Regular", Arial, sans-serif;
  margin-left: 11px;
}
._2RpsI .uui-input-label b, ._2RpsI .uui-input-label strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._2RpsI .uui-input-label i, ._2RpsI .uui-input-label em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._2RpsI .uui-input-label a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._2RpsI .uui-input-label a:hover {
  text-decoration: none;
  color: #007799;
}
._2RpsI .uui-input-label code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2RpsI .uui-checked.uui-switch-body {
  background-color: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._2RpsI .uui-checked.uui-switch-body:active {
  background-color: var(--uui-color-900);
  border-color: var(--uui-color-900);
}
._2RpsI .uui-checked.uui-switch-body:hover, ._2RpsI .uui-checked.uui-switch-body:focus-within {
  background-color: var(--uui-color-800);
  border-color: var(--uui-color-800);
}
._2RpsI.uui-disabled .uui-switch-body {
  background-color: #FAFAFC;
  border-color: #E1E3EB;
}
._2RpsI.uui-disabled .uui-checked.uui-switch-body {
  background-color: var(--uui-color-600);
  border-color: var(--uui-color-600);
}
._2RpsI.uui-disabled .uui-checked .uui-switch-toggler {
  background-color: #FAFAFC;
  box-shadow: none;
}
._2RpsI.uui-disabled .uui-switch-toggler {
  background-color: #FFFFFF;
  box-shadow: 0 0 0 1px #E1E3EB;
}
._2RpsI.uui-disabled,
._2RpsI.uui-disabled .uui-switch-toggler,
._2RpsI.uui-disabled .uui-switch-body,
._2RpsI.uui-disabled .uui-input-label {
  cursor: default;
}
._2RpsI._3a1f5 .uui-input-label {
  color: #474A59;
}
._2RpsI._3a1f5.uui-disabled .uui-input-label {
  color: #ACAFBF;
}
._2RpsI._3hJeQ .uui-input-label {
  color: #FAFAFC;
}