/* Carbon scale */
/* Night scale */
._3Pbab .uui-thumb-vertical, ._3Pbab .uui-thumb-horizontal {
  background: #CED0DB;
  border-radius: 1.5px;
}
._3Pbab .uui-thumb-vertical:hover, ._3Pbab .uui-thumb-horizontal:hover {
  border-radius: 3px;
}
._3Pbab .uui-thumb-vertical:active, ._3Pbab .uui-thumb-horizontal:active {
  border-radius: 3px;
  background: #6C6F80;
}
._3Pbab .uui-thumb-vertical {
  width: 3px;
  margin-left: 3px;
}
._3Pbab .uui-thumb-vertical:hover {
  width: 6px;
  margin-left: 0;
}
._3Pbab .uui-thumb-vertical:active {
  width: 6px;
  margin-left: 0;
}
._3Pbab .uui-thumb-horizontal {
  height: 3px;
  margin-top: 3px;
}
._3Pbab .uui-thumb-horizontal:hover {
  height: 6px;
  margin-top: 0;
}
._3Pbab .uui-thumb-horizontal:active {
  height: 6px;
  margin-top: 0;
}