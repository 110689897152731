.JlPqi .uui-accordion-toggle-container {
  display: flex;
  align-items: center;
}
.JlPqi .uui-accordion-toggler:focus {
  outline: none;
}
.JlPqi .uui-accordion-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

._3V3v1 {
  margin-left: auto;
}