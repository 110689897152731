/* Carbon scale */
/* Night scale */
.nbqBg .hero-header, .nbqBg h1, .nbqBg h2, .nbqBg h3, .nbqBg h4, .nbqBg h5, .nbqBg h6 {
  margin: 0;
}
.nbqBg .hero-header, .nbqBg h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.nbqBg .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.nbqBg h3, .nbqBg h4, .nbqBg h5, .nbqBg h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.nbqBg h1, .nbqBg h2, .nbqBg h3, .nbqBg h4, .nbqBg h5, .nbqBg h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.nbqBg h1 {
  font-size: 42px;
}
.nbqBg h2 {
  font-size: 30px;
}
.nbqBg h3 {
  font-size: 24px;
}
.nbqBg h4 {
  font-size: 18px;
}
.nbqBg h5 {
  font-size: 16px;
}
.nbqBg h6 {
  font-size: 14px;
}

._1EeAU b, ._1EeAU strong {
  color: #1D1E26;
}
._1EeAU ol, ._1EeAU ul {
  padding: 0;
}
._1EeAU li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._1EeAU pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1EeAU img {
  max-width: 100%;
}
._1EeAU figure {
  margin: 0;
}
._1EeAU figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._1EeAU p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1Q8KQ b, ._1Q8KQ strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1Q8KQ i, ._1Q8KQ em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1Q8KQ a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1Q8KQ a:hover {
  text-decoration: none;
  color: #007799;
}
._1Q8KQ code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._3Xzry {
  font-size: 16px;
}

._2ti4_ {
  font-size: 14px;
}

._2owlM {
  font-size: 12px;
}

._3XXaL {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._2ibsf {
  fill: var(--uui-color-700);
}