/* Carbon scale */
/* Night scale */
.iEdLB .hero-header, .iEdLB h1, .iEdLB h2, .iEdLB h3, .iEdLB h4, .iEdLB h5, .iEdLB h6 {
  margin: 0;
}
.iEdLB .hero-header, .iEdLB h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
.iEdLB .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
.iEdLB h3, .iEdLB h4, .iEdLB h5, .iEdLB h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.iEdLB h1, .iEdLB h2, .iEdLB h3, .iEdLB h4, .iEdLB h5, .iEdLB h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.iEdLB h1 {
  font-size: 42px;
}
.iEdLB h2 {
  font-size: 30px;
}
.iEdLB h3 {
  font-size: 24px;
}
.iEdLB h4 {
  font-size: 18px;
}
.iEdLB h5 {
  font-size: 16px;
}
.iEdLB h6 {
  font-size: 14px;
}

._3GJ3G b, ._3GJ3G strong {
  color: #1D1E26;
}
._3GJ3G ol, ._3GJ3G ul {
  padding: 0;
}
._3GJ3G li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._3GJ3G pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._3GJ3G img {
  max-width: 100%;
}
._3GJ3G figure {
  margin: 0;
}
._3GJ3G figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._3GJ3G p {
  margin: 0.5em 0;
  line-height: 1.5;
}

.Ez6qq b, .Ez6qq strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
.Ez6qq i, .Ez6qq em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.Ez6qq a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
.Ez6qq a:hover {
  text-decoration: none;
  color: #007799;
}
.Ez6qq code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._1MsAD {
  font-size: 16px;
}

._1K1be {
  font-size: 14px;
}

._3J0rn {
  font-size: 12px;
}

._3MLeF {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._19d5w {
  position: relative;
  display: flex;
}
._19d5w.uui-burger-menu-open {
  background-color: #1D1E26;
  z-index: 1001;
}
._19d5w.uui-burger-menu-open ~ #global_menu_toggle {
  background-color: #1D1E26;
  z-index: 1001;
}
._19d5w.uui-burger-menu-open ~ #global_menu_toggle :hover {
  background-color: #070709;
}
._19d5w .uui-burger-button {
  width: 60px;
  cursor: pointer;
  fill: #FFFFFF;
}
._19d5w .uui-burger-button:hover {
  opacity: 0.9;
}
._19d5w .uui-burger-button:active {
  opacity: 0.8;
}
._19d5w .uui-burger-button .uui-icon svg {
  width: 60px;
  height: 60px;
}
._19d5w .uui-burger-logo-container {
  position: absolute;
  left: 60px;
  top: 0;
}
._19d5w .uui-burger-logo {
  display: block;
  height: 60px;
}

._2tENN.uui-burger-overlay {
  opacity: 0;
}
._2tENN.uui-burger-overlay.uui-burger-overlay-visible {
  opacity: 1;
  position: fixed;
  z-index: 1000;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(29, 30, 38, 0.8);
  transition: opacity 0.2s ease-in-out;
}
._2tENN .uui-burger-items {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 300px;
  background: #1D1E26;
  transform: translateX(-100px);
  overflow-y: overlay;
  overflow: auto;
}
._2tENN .uui-burger-items.uui-burger-items-visible {
  transform: translateX(0);
}