/* Carbon scale */
/* Night scale */
._32g7a .hero-header, ._32g7a h1, ._32g7a h2, ._32g7a h3, ._32g7a h4, ._32g7a h5, ._32g7a h6 {
  margin: 0;
}
._32g7a .hero-header, ._32g7a h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._32g7a .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._32g7a h3, ._32g7a h4, ._32g7a h5, ._32g7a h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._32g7a h1, ._32g7a h2, ._32g7a h3, ._32g7a h4, ._32g7a h5, ._32g7a h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._32g7a h1 {
  font-size: 42px;
}
._32g7a h2 {
  font-size: 30px;
}
._32g7a h3 {
  font-size: 24px;
}
._32g7a h4 {
  font-size: 18px;
}
._32g7a h5 {
  font-size: 16px;
}
._32g7a h6 {
  font-size: 14px;
}

.hwjdz b, .hwjdz strong {
  color: #1D1E26;
}
.hwjdz ol, .hwjdz ul {
  padding: 0;
}
.hwjdz li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.hwjdz pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.hwjdz img {
  max-width: 100%;
}
.hwjdz figure {
  margin: 0;
}
.hwjdz figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.hwjdz p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1RSWJ b, ._1RSWJ strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1RSWJ i, ._1RSWJ em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1RSWJ a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1RSWJ a:hover {
  text-decoration: none;
  color: #007799;
}
._1RSWJ code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._23_ek {
  font-size: 16px;
}

._1OTd3 {
  font-size: 14px;
}

._169QA {
  font-size: 12px;
}

.OR-Lm {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._1D-_g:hover .uui-radioinput, ._1D-_g:active .uui-radioinput, ._1D-_g:focus-within .uui-radioinput {
  border-color: var(--uui-color-700);
}
._1D-_g:hover .uui-checked.uui-radioinput, ._1D-_g:active .uui-checked.uui-radioinput, ._1D-_g:focus-within .uui-checked.uui-radioinput {
  border-color: var(--uui-color-800);
}
._1D-_g:hover .uui-checked .uui-icon, ._1D-_g:active .uui-checked .uui-icon, ._1D-_g:focus-within .uui-checked .uui-icon {
  fill: var(--uui-color-800);
}
._1D-_g .uui-radioinput {
  fill: #009ECC;
  align-self: baseline;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 1px solid #CED0DB;
}
._1D-_g .uui-radioinput.uui-checked {
  border: 1px solid var(--uui-color-700);
}
._1D-_g .uui-icon {
  fill: var(--uui-color-700);
}
._1D-_g .uui-input-label {
  flex-grow: 1;
  font-family: "Sans Regular", Arial, sans-serif;
  margin-left: 12px;
  display: flex;
  align-items: center;
}
._1D-_g .uui-input-label b, ._1D-_g .uui-input-label strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1D-_g .uui-input-label i, ._1D-_g .uui-input-label em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1D-_g .uui-input-label a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1D-_g .uui-input-label a:hover {
  text-decoration: none;
  color: #007799;
}
._1D-_g .uui-input-label code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._1D-_g._1KW_7 .uui-radioinput {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin: 0px 0;
}
._1D-_g._1KW_7 .uui-radioinput .uui-icon {
  margin: 0px 0;
}
._1D-_g._1KW_7 .uui-radioinput .uui-icon svg {
  height: 18px;
  width: 18px;
}
._1D-_g._1KW_7 .uui-input-label {
  font-size: 14px;
  line-height: 18px;
}
._1D-_g._2gcFQ .uui-radioinput {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 1px 0;
}
._1D-_g._2gcFQ .uui-radioinput .uui-icon {
  margin: 1px 0;
}
._1D-_g._2gcFQ .uui-radioinput .uui-icon svg {
  height: 12px;
  width: 12px;
}
._1D-_g._2gcFQ .uui-input-label {
  font-size: 12px;
  line-height: 14px;
}
._1D-_g._2gcFQ .uui-input-label {
  margin-left: 6px;
}
._1D-_g._2T8zG .uui-input-label {
  color: #474A59;
}
._1D-_g._2T8zG.uui-disabled, ._1D-_g._2T8zG.uui-readonly {
  cursor: default;
}
._1D-_g._2T8zG.uui-disabled .uui-radioinput, ._1D-_g._2T8zG.uui-readonly .uui-radioinput {
  cursor: default;
  background-color: #FAFAFC;
  border-color: #E1E3EB;
  box-shadow: none;
}
._1D-_g._2T8zG.uui-disabled .uui-icon, ._1D-_g._2T8zG.uui-readonly .uui-icon {
  fill: var(--uui-color-600);
}
._1D-_g._2T8zG.uui-disabled:hover .uui-radioinput, ._1D-_g._2T8zG.uui-disabled:active .uui-radioinput, ._1D-_g._2T8zG.uui-disabled:focus-within .uui-radioinput, ._1D-_g._2T8zG.uui-readonly:hover .uui-radioinput, ._1D-_g._2T8zG.uui-readonly:active .uui-radioinput, ._1D-_g._2T8zG.uui-readonly:focus-within .uui-radioinput {
  border-color: #E1E3EB;
}
._1D-_g._2T8zG.uui-disabled.uui-checked .uui-radioinput, ._1D-_g._2T8zG.uui-readonly.uui-checked .uui-radioinput {
  border-color: var(--uui-color-600);
}
._1D-_g._2T8zG.uui-disabled.uui-checked .uui-icon, ._1D-_g._2T8zG.uui-readonly.uui-checked .uui-icon {
  fill: var(--uui-color-600);
}
._1D-_g._2T8zG.uui-readonly .uui-input-label {
  color: #474A59;
}
._1D-_g._2T8zG.uui-disabled .uui-input-label {
  color: #ACAFBF;
}
._1D-_g._3SOaa .uui-input-label {
  color: #FAFAFC;
}
._1D-_g._3SOaa.uui-disabled, ._1D-_g._3SOaa.uui-readonly {
  cursor: default;
}
._1D-_g._3SOaa.uui-disabled:hover .uui-radioinput, ._1D-_g._3SOaa.uui-disabled:active .uui-radioinput, ._1D-_g._3SOaa.uui-disabled:focus-within .uui-radioinput, ._1D-_g._3SOaa.uui-readonly:hover .uui-radioinput, ._1D-_g._3SOaa.uui-readonly:active .uui-radioinput, ._1D-_g._3SOaa.uui-readonly:focus-within .uui-radioinput {
  cursor: default;
  border-color: #6C6F80;
}
._1D-_g._3SOaa.uui-disabled .uui-radioinput, ._1D-_g._3SOaa.uui-readonly .uui-radioinput {
  background-color: #6C6F80;
  border-color: #6C6F80;
  box-shadow: none;
}
._1D-_g._3SOaa.uui-disabled .uui-icon, ._1D-_g._3SOaa.uui-readonly .uui-icon {
  fill: #ACAFBF;
}
._1D-_g._3SOaa.uui-disabled.uui-checked .uui-radioinput, ._1D-_g._3SOaa.uui-readonly.uui-checked .uui-radioinput {
  background-color: #6C6F80;
  border-color: #6C6F80;
}
._1D-_g._3SOaa.uui-disabled.uui-checked .uui-icon, ._1D-_g._3SOaa.uui-readonly.uui-checked .uui-icon {
  fill: #ACAFBF;
}
._1D-_g._3SOaa.uui-readonly .uui-input-label {
  color: #FAFAFC;
}
._1D-_g._3SOaa.uui-disabled .uui-input-label {
  color: #ACAFBF;
}
._1D-_g.uui-invalid .uui-radioinput {
  border-color: #FA4B4B;
}
._1D-_g.uui-invalid .uui-icon {
  fill: #FA4B4B;
}
._1D-_g.uui-invalid.uui-checked .uui-radioinput {
  border-color: #FA4B4B;
}
._1D-_g.uui-invalid.uui-checked .uui-icon {
  fill: #FA4B4B;
}
._1D-_g.uui-invalid:hover .uui-radioinput, ._1D-_g.uui-invalid:active .uui-radioinput, ._1D-_g.uui-invalid:focus-within .uui-radioinput {
  border-color: #f91919;
}
._1D-_g.uui-invalid:hover .uui-icon, ._1D-_g.uui-invalid:active .uui-icon, ._1D-_g.uui-invalid:focus-within .uui-icon {
  fill: #f91919;
}
._1D-_g.uui-invalid .uui-input-label {
  color: #FA4B4B;
}