._1LtzF {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
._1LtzF:focus-within {
  outline: none;
}
._1LtzF input[type=checkbox] {
  margin: 0;
  border: none;
  width: 0;
  height: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
._1LtzF input[type=checkbox]:focus {
  outline: none;
}
._1LtzF .uui-switch-toggler {
  cursor: pointer;
  display: flex;
  position: absolute;
  flex-shrink: 0;
  position: relative;
}
._1LtzF .uui-switch-body {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}