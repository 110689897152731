.LYcrE.uui-shadow-top::before, .LYcrE.uui-shadow-bottom::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 6px;
  opacity: 0;
  transition: opacity 200ms;
}
.LYcrE.uui-shadow-top::before {
  top: 0;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.LYcrE.uui-shadow-bottom::after {
  bottom: 0;
  background: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.LYcrE.uui-shadow-top-visible::before {
  opacity: 1;
}
.LYcrE.uui-shadow-bottom-visible::after {
  opacity: 1;
}
.LYcrE .uui-track-horizontal,
.LYcrE .uui-track-vertical {
  position: absolute;
  border-radius: 3px;
  z-index: 6;
}
.LYcrE .uui-track-horizontal {
  height: 6px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}
.LYcrE .uui-track-vertical {
  width: 6px;
  right: 2px;
  top: 2px;
  bottom: 2px;
}