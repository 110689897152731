.vevF4 {
  display: flex;
  align-items: center;
}
.vevF4.IabRQ {
  min-height: 18px;
}
.vevF4._1mo01 {
  min-height: 24px;
}
.vevF4._3oz0b {
  min-height: 30px;
}
.vevF4._3rsp6 {
  min-height: 36px;
}
.vevF4._30fLK {
  min-height: 48px;
}