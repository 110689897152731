/* Carbon scale */
/* Night scale */
._18kJU .hero-header, ._18kJU h1, ._18kJU h2, ._18kJU h3, ._18kJU h4, ._18kJU h5, ._18kJU h6 {
  margin: 0;
}
._18kJU .hero-header, ._18kJU h2 {
  font-family: "Sans Bold", Arial, sans-serif;
}
._18kJU .hero-header {
  margin: 30px 0;
  font-size: 72px;
  line-height: 72px;
}
._18kJU h3, ._18kJU h4, ._18kJU h5, ._18kJU h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
._18kJU h1, ._18kJU h2, ._18kJU h3, ._18kJU h4, ._18kJU h5, ._18kJU h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
._18kJU h1 {
  font-size: 42px;
}
._18kJU h2 {
  font-size: 30px;
}
._18kJU h3 {
  font-size: 24px;
}
._18kJU h4 {
  font-size: 18px;
}
._18kJU h5 {
  font-size: 16px;
}
._18kJU h6 {
  font-size: 14px;
}

._2T0ay b, ._2T0ay strong {
  color: #1D1E26;
}
._2T0ay ol, ._2T0ay ul {
  padding: 0;
}
._2T0ay li {
  margin-left: 1.25em;
  line-height: 1.5;
}
._2T0ay pre {
  padding: 1.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
._2T0ay img {
  max-width: 100%;
}
._2T0ay figure {
  margin: 0;
}
._2T0ay figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
._2T0ay p {
  margin: 0.5em 0;
  line-height: 1.5;
}

._1TU8p b, ._1TU8p strong {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #1D1E26;
}
._1TU8p i, ._1TU8p em {
  font-family: "Sans Italic", Arial, sans-serif;
}
._1TU8p a {
  display: inline-flex;
  align-items: center;
  color: #009ECC;
}
._1TU8p a:hover {
  text-decoration: none;
  color: #007799;
}
._1TU8p code {
  padding: 0.125em 0.25em;
  color: #FAFAFC;
  background-color: #474A59;
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}

._26iGw {
  font-size: 16px;
}

._1heck {
  font-size: 14px;
}

._3C6AC {
  font-size: 12px;
}

.Rxu8S {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
}

._3gmyw .uui-tooltip-body {
  background-color: #474A59;
  padding: 0 10px;
  color: white;
  line-height: 24px;
  font-size: 14px;
  font-family: "Sans Regular", Arial, sans-serif;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
._3gmyw .uui-tooltip-arrow {
  border: 5px solid transparent;
}
._3gmyw .uui-slider-scale-number {
  font-size: 11px;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
  margin-top: 10px;
}
._3gmyw .uui-slider-scale-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: -1px;
}
._3gmyw.uui-slider-container {
  height: 12px;
}
._3gmyw.uui-slider-container .uui-slider {
  margin: 0 6px 0 6px;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #ACAFBF;
  min-width: 100px;
}
._3gmyw.uui-slider-container .uui-slider-scale {
  height: 6px;
}
._3gmyw.uui-slider-container .uui-slider-handle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--uui-color-700);
  cursor: pointer;
}
._3gmyw.uui-slider-container .uui-slider-filled {
  margin: 0 6px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--uui-color-700);
}
._3gmyw.uui-slider-container .uui-slider-scale-dot {
  background-color: #EBEDF5;
  border: 1px #ACAFBF solid;
}
._3gmyw.uui-slider-container:hover .uui-slider {
  background-color: var(--uui-color-600);
}
._3gmyw.uui-slider-container:hover .uui-slider-scale-dot:not(.uui-slider-scale-filled-dot) {
  border-color: var(--uui-color-600);
  background-color: var(--uui-color-600);
}
._3gmyw.uui-slider-container .uui-slider-scale-filled-dot {
  background-color: var(--uui-color-700);
  border-color: var(--uui-color-700);
}
._3gmyw.uui-slider-container.uui-active .uui-slider {
  background-color: var(--uui-color-600);
}
._3gmyw.uui-slider-container.uui-active .uui-slider-scale-dot:not(.uui-slider-scale-filled-dot) {
  border-color: var(--uui-color-600);
  background-color: var(--uui-color-600);
}
._3gmyw.uui-slider-container.uui-disabled {
  pointer-events: none;
}
._3gmyw.uui-slider-container.uui-disabled .uui-slider-filled {
  background-color: #CED0DB;
}
._3gmyw.uui-slider-container.uui-disabled .uui-slider-handle {
  background-color: #CED0DB;
}
._3gmyw.uui-slider-container.uui-disabled .uui-slider {
  background-color: #CED0DB;
}
._3gmyw.uui-slider-container.uui-disabled .uui-slider-scale-filled-dot {
  background-color: #CED0DB;
  border-color: #CED0DB;
}